import { ThemeColor, ThemeService } from '../../../../../../shared/services/theme-service';
import styles from '../../page.module.css';
import { useState } from 'react';

interface Props {
	themeService: ThemeService;
}
export default function ThemeColorList({ themeService }: Props) {
	const [themeColors, setThemeColors] = useState(themeService.getColors());

	const changeColor = (themeColor: ThemeColor) => {
		const updatedColors = themeColors.map(color => ({
			...color,
			isSelected: themeColor.id === color.id,
		}));

		themeService.setColors(updatedColors);
		setThemeColors(updatedColors);

		document.documentElement.setAttribute('style', `--theme-color: ${themeColor.color}`);
	};

	return (
		<>
			{themeColors.map(themeColor => {
				return (
					<div
						data-testid={themeColor.color}
						style={{ backgroundColor: themeColor.color }}
						className={`${styles['color-picker']} ${themeColor.isSelected ? styles.active : ''}`}
						onClick={() => {
							changeColor(themeColor);
						}}
						key={themeColor.color}
					/>
				);
			})}
		</>
	);
}
