import Animated from '../../../../components/essence/animated/animated';
import EssenceButton from '../../../../components/essence/button/button';
import Clickable from '../../../../components/essence/core/clickable';
import { useState } from 'react';
import ShareTodoList from '../share-todo-list/page';

interface Props {
	onAddTodo(todo: string): void;
	onShare(): void;
}
export default function AddTodoForm({ onAddTodo, onShare }: Props) {
	const [newTodo, setNewTodo] = useState('');

	const handleFormAddTodo = (e: React.FormEvent) => {
		e.preventDefault();
		onAddTodo(newTodo);
		setNewTodo('');
	};

	return (
		<form onSubmit={handleFormAddTodo} className="">
			<div className="md:flex block gap-5 items-center">
				<input
					essence-input="true"
					type="text"
					placeholder="type your todo"
					name="todo-item"
					value={newTodo}
					data-testid="input-todo"
					onChange={event => {
						setNewTodo(event.target.value);
					}}
				/>

				<div className="md:hidden flex mb-6 gap-5 items-center">
					<Animated>
						<Clickable key="add-expenses">
							<ShareTodoList onShare={onShare} />
						</Clickable>
					</Animated>
					<EssenceButton name="Add TODO" disabled={newTodo.trim() === ''} data-testid="btn-add-todo" />
				</div>
				<div className="md:flex hidden gap-10 items-center">
					<EssenceButton name="Add TODO" disabled={newTodo.trim() === ''} data-testid="btn-add-todo" />
					<Clickable key="add-expenses">
						<ShareTodoList onShare={onShare} />
					</Clickable>
				</div>
			</div>
		</form>
	);
}
