export interface Props {
	onShare(): void;
}
export default function ShareTodoList({ onShare }: Props) {
	return (
		<div className="flex headline-4 lowercase" onClick={onShare} data-testid="share-btn">
			<div className="fa fa-share me-3"></div>
			<span>share list</span>
		</div>
	);
}
