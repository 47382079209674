import { useQueryClient } from '@tanstack/react-query';
import api from '../../services/api';
import { accountKeys } from './keys';
import { getItems } from '../query-response-manager';
import { Account } from '../../models/account';

export const useAccountQueries = () => {
	const queryClient = useQueryClient();
	const accountQuery = {
		queryKey: accountKeys.all(),
		queryFn: () =>
			api.get<Account>(`/api/account`).then(res => {
				const serverItems = res.data;
				const clientItems = queryClient.getQueryData<Account>(accountKeys.all());
				const items = getItems<Account>([serverItems], clientItems ? [clientItems] : [], 'userId');
				return items[0];
			}),
		refetchOnReconnect: false,
	};

	return {
		accountQuery,
	};
};
