import { QueryClient } from '@tanstack/react-query';
import { addTodo, deleteTodo, toggleTodo, reorderTodo, AddTodoRequest, EditTodoRequest, editTodo } from './api';
import { toDoKeys } from './keys';
import { Todo } from '../../models/todo';

export const setDefaultTodosMutation = (queryClient: QueryClient) => {
	// we need a default mutation function so that paused mutations can resume after a page reload
	queryClient.setMutationDefaults(toDoKeys.add(), {
		mutationFn: async (todo: AddTodoRequest) => {
			// to avoid clashes with our optimistic update when an offline mutation continues
			await queryClient.cancelQueries({
				queryKey: toDoKeys.add(),
			});

			return await addTodo(todo);
		},
	});

	// we need a default mutation function so that paused mutations can resume after a page reload
	queryClient.setMutationDefaults(toDoKeys.toggle(), {
		mutationFn: async (todo: Todo) => {
			// to avoid clashes with our optimistic update when an offline mutation continues
			await queryClient.cancelQueries({
				queryKey: toDoKeys.toggle(),
			});
			const todoToUpdate = _getCachedTodo(todo);
			await toggleTodo(todoToUpdate.toDoId);
		},
	});

	// we need a default mutation function so that paused mutations can resume after a page reload
	queryClient.setMutationDefaults(toDoKeys.delete(), {
		mutationFn: async (todo: Todo) => {
			// to avoid clashes with our optimistic update when an offline mutation continues
			await queryClient.cancelQueries({
				queryKey: toDoKeys.delete(),
			});
			const todoToUpdate = _getCachedTodo(todo);
			await deleteTodo(todoToUpdate.toDoId);
		},
	});

	// we need a default mutation function so that paused mutations can resume after a page reload
	queryClient.setMutationDefaults(toDoKeys.reorder(), {
		mutationFn: async (todos: Todo[]) => {
			// to avoid clashes with our optimistic update when an offline mutation continues
			await queryClient.cancelQueries({
				queryKey: toDoKeys.reorder(),
			});

			const todosToUpdate = todos.map(todo => _getCachedTodo(todo));
			await reorderTodo(todosToUpdate);
		},
	});

	// we need a default mutation function so that paused mutations can resume after a page reload
	queryClient.setMutationDefaults(toDoKeys.edit(), {
		mutationFn: async (request: EditTodoRequest) => {
			// to avoid clashes with our optimistic update when an offline mutation continues
			await queryClient.cancelQueries({
				queryKey: toDoKeys.edit(),
			});
			await editTodo(request);
		},
	});

	const _getCachedTodo = (todo: Todo) => {
		const todos = queryClient.getQueryData<Todo[]>(toDoKeys.list(todo.circleId));
		const updatedTodos = todos?.filter(x => x.clientId === todo.toDoId);
		if (updatedTodos && updatedTodos.length > 0) {
			return updatedTodos[0];
		}
		return todo;
	};
};
