import { useParams } from 'react-router-dom';
import { useCircleQueries } from '../../../../../shared/tanstack/circle/queries';
import { useQuery } from '@tanstack/react-query';
import { InvalidCircleIdError } from '../../../../../shared/models/errors/invalid-circle-id-error';
import StepList from './step-list';
export default function AddExpenseSteps() {
	const { circleId } = useParams();
	const { circleQuery } = useCircleQueries();
	if (!circleId) {
		throw new InvalidCircleIdError();
	}
	const { data: circle } = useQuery(circleQuery(circleId));

	return (
		<div className="hidden md:block col-span-6">
			<div className="hidden md:flex items-center justify-center flex-col h-screen">
				<h1 className="mt-10 text-5xl">{circle?.name}</h1>
				<h4 className="text-2xl mt-16 mb-10">Add expense in 3 easy steps</h4>
				<StepList />
			</div>
		</div>
	);
}
