import React, { useState } from 'react';
import EssenceAnimatedButton from '../../../../components/essence/button/animated-button';
import Header from '../../../../shared/components/header/page';
import Content from '../../../../shared/components/content/page';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
// import EssenceDatePicker from '../../../../components/essence/date-picker/date-picker';
import { useEffect } from 'react';
import { InvalidCircleIdError } from '../../../../shared/models/errors/invalid-circle-id-error';
import { Itinerary } from '../../../../shared/models/itinerary';

const AddItineraryDetail = () => {
	const navigate = useNavigate();
	const { circleId } = useParams();
	if (!circleId) {
		throw new InvalidCircleIdError();
	}
	const [itineraryDetail, setItineraryDetail] = useState({
		circleId,
		title: '',
		description: '',
		origin: '',
		destination: '',
	});

	useEffect(() => {
		const rawItineraryData = localStorage.getItem('itinerary-detail');
		let itineraryData: Itinerary | undefined;
		if (rawItineraryData) {
			itineraryData = JSON.parse(rawItineraryData) as Itinerary;
			setItineraryDetail(itineraryData);
		}
	}, []);

	const goToOrgDest = (e: React.MouseEvent) => {
		e.preventDefault();
		localStorage.setItem(`itinerary-detail`, JSON.stringify(itineraryDetail));
		navigate(`/secure/circles/${circleId}/add-itinerary/org-dest`, {
			state: { itineraryDetail },
		});
	};

	const form = {
		get isDisabled() {
			return !itineraryDetail.title ? true : undefined;
		},
	};

	return (
		<>
			<Header title="Details" subtitle="Enter the details of the itinerary." />
			<Content>
				<div>
					<input
						essence-input="true"
						type="text"
						placeholder="Title"
						value={itineraryDetail.title}
						onChange={event => {
							setItineraryDetail({
								...itineraryDetail,
								title: event.target.value,
							});
						}}
						name="itinerary-item"
					/>
					{/* <EssenceDatePicker date={itineraryDetail.startDateTime}></EssenceDatePicker> */}
					{/* <EssenceDatePicker
						date={itineraryDetail.endDateTime}
						// onChange={event =>
						// 	setItineraryDetail({
						// 		...itineraryDetail,
						// 		endDateTime: event.target.value.toISOString(),
						// 	})
						// }
					></EssenceDatePicker> */}
					<input
						essence-input="true"
						type="text"
						placeholder="description"
						value={itineraryDetail.description}
						onChange={event => {
							setItineraryDetail({
								...itineraryDetail,
								description: event.target.value,
							});
						}}
						name="itinerary-item"
					/>
					<EssenceAnimatedButton disabled={form.isDisabled} onClick={goToOrgDest} name="next" />
				</div>
			</Content>
		</>
	);
};

export default AddItineraryDetail;
