import { useEffect, useState } from 'react';
import styles from './page.module.css';
import Animated from '../../../components/essence/animated/animated';
import Clickable from '../../../components/essence/core/clickable';
import CircleList from '../../circles/my-circles/circles-list/page';
import coachMarkManager from '../../../secure/services/coach-mark';
import swipeLeftAnimation from './../../../../scroll-left.json';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import useLoaderDisplay from '../../../shared/hooks/loader-display';
import accountService from '../../../shared/services/account-service';
import { useCircleQueries } from '../../../shared/tanstack/circle/queries';

export default function MyCircles() {
	const navigate = useNavigate();
	const [displayAnimation, setDisplayAnimation] = useState(coachMarkManager.shouldShowCoachMark('landing'));

	const onSwipe = () => {
		coachMarkManager.hideCoachMark('landing');
		setDisplayAnimation(false);
	};
	const accessToken = accountService.getAccessToken();

	const { circlesQuery } = useCircleQueries();
	const { data, isLoading } = useQuery(circlesQuery(accessToken === undefined));
	useLoaderDisplay({ key: 'getCircles', isLoading });
	const handleAddCircle = () => {
		navigate('/secure/onboarding/circle-info');
	};

	useEffect(
		() => () => {
			coachMarkManager.removeGestureListener(onSwipe);
		},
		[],
	);

	useEffect(() => {
		if (displayAnimation) {
			coachMarkManager.addGestureListener({
				listener: onSwipe,
				animation: {
					elementSelector: '#left-swipe',
					lottie: swipeLeftAnimation,
				},
			});
		}
	}, [displayAnimation]);

	return (
		<div className={styles.container}>
			<div>
				<div className="mt-10" />
				<Animated>
					<Clickable>
						<div className="headline-4 lowercase" onClick={handleAddCircle}>
							+ add circle
						</div>
					</Clickable>
				</Animated>
				<div className="mt-10" />
				<CircleList circles={data ?? []} />
			</div>
			{displayAnimation && <div id="left-swipe" className={styles['coach-mark']}></div>}
		</div>
	);
}
