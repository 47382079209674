import ListExpenses from '../list-expenses/page';
import { useExpenseQueries } from '../../shared/tanstack/expenses/queries';
import { useQuery } from '@tanstack/react-query';
import useLoaderDisplay from '../../shared/hooks/loader-display';
import { useCircleQueries } from '../../shared/tanstack/circle/queries';
import Clickable from '../../components/essence/core/clickable';
import ExpenseSummary from '../expense-summary/page';
import AddNewExpense from './components/add-new-expense/page';
import { useEffect, useState } from 'react';
import accountService from '../../shared/services/account-service';
import { jwtDecode } from 'jwt-decode';
import { Account } from '../../shared/models/account';

interface Props {
	circleId: string;
}
export default function Expenses(props: Props) {
	const { expensesQuery, expensesSummaryQuery } = useExpenseQueries();
	const { participantsQuery } = useCircleQueries();
	const [loggedInUserId, setLoggedInUserId] = useState('');

	const { data: expenses, isLoading } = useQuery(expensesQuery(props.circleId));
	useLoaderDisplay({ key: 'getExpenses', isLoading });
	const { data: expensesSummary } = useQuery(expensesSummaryQuery(props.circleId));
	const { data: participants } = useQuery(participantsQuery(props.circleId));

	useEffect(() => {
		const accessToken = accountService.getAccessToken();
		if (accessToken) {
			setLoggedInUserId(jwtDecode<Account>(accessToken).userId);
		}
	}, []);

	return (
		<>
			<Clickable key="add-expenses">
				<AddNewExpense circleId={props.circleId} />
			</Clickable>
			<div className="mt-3" />
			{/* <EssenceAnimatedButton name="Add Expense" onClick={goToAddExpenses} /> */}
			{expensesSummary && expensesSummary.length > 0 && (
				<ExpenseSummary
					expensesSummary={expensesSummary}
					participants={participants ?? []}
					circleId={props.circleId}
				/>
			)}

			{expenses && <ListExpenses expenses={expenses} circleId={props.circleId} loggedInUserId={loggedInUserId} />}
		</>
	);
}
