import { PropsWithChildren } from 'react';
import Animated from '../animated/animated';
import styles from './page.module.css';

interface Props extends PropsWithChildren {
	title: string;
	onTitleClick?: React.MouseEventHandler<HTMLDivElement>;
}

export default function Page({ title, onTitleClick, children }: Props) {
	return (
		<div className={styles.component}>
			<div className={styles.title} onClick={onTitleClick}>
				<Animated>{title}</Animated>
			</div>
			<div className={styles.template}>{children}</div>
		</div>
	);
}
