import { useState, useEffect } from 'react';
import Page from '../../../../components/essence/page/page';
import AnimatedParent from '../../../../components/essence/animated/animated-parent';
import getMemberType from '../../member-type';
import { useNavigate, useParams } from 'react-router-dom';
import EssenceCheckbox from '../../../../components/essence/checkbox/checkbox';
import { useCircleQueries } from '../../../../shared/tanstack/circle/queries';
import { useCircleMutations } from '../../../../shared/tanstack/circle/mutations';
import { useQuery, useMutation } from '@tanstack/react-query';
import EssenceAlert from '../../../../components/essence/alert/alert';
import accountService from '../../../../shared/services/account-service';
import { jwtDecode } from 'jwt-decode';
import Clickable from '../../../../components/essence/core/clickable';
import { InvalidCircleIdError } from '../../../../shared/models/errors/invalid-circle-id-error';
import { Account } from '../../../../shared/models/account';
import Animated from '../../../../components/essence/animated/animated';
import { Contact } from '../../../../shared/services/contacts-manager';
import { ContactRequest } from '../../../../shared/tanstack/circle/api';
import { Capacitor } from '@capacitor/core';
import styles from './page.module.css';

interface WarningAlert {
	message?: string;
	okButton?: string;
	show?: boolean;
	something?: boolean;
	context?: {
		type: string;
		participant: Contact;
	};
}

const CircleParticipants = () => {
	const navigate = useNavigate();
	const [localParticipants, setLocalParticipants] = useState<Contact[]>([]);
	const [filteredParticipants, setFilteredParticipants] = useState<Contact[]>([]);
	const [disabledParticipants, setDisabledParticipants] = useState<Contact[]>([]);
	const [searchTerm] = useState('');
	const { circleId } = useParams();
	if (!circleId) {
		throw new InvalidCircleIdError();
	}
	const { circleQuery, participantsQuery } = useCircleQueries();
	const { data: circle } = useQuery(circleQuery(circleId));
	let member: string | undefined;
	const circleType = circle?.type;
	if (circleType) {
		member = getMemberType(circleType)?.pluralTitle;
	}
	const { data: participants } = useQuery(participantsQuery(circleId));
	const { deleteParticipantsMutation } = useCircleMutations();
	const { deleteCircleMutation } = useCircleMutations();
	const { mutate: exitCircle } = useMutation(deleteCircleMutation);

	const { mutate: removeParticipant } = useMutation(deleteParticipantsMutation);
	const [alert, setAlert] = useState({ show: false, message: '' });
	const [warningAlert, setWarningAlert] = useState<WarningAlert>({
		show: false,
		something: false,
		message: '',
		okButton: '',
	});

	useEffect(() => {
		// const fetchedParticipants = participants?.map(participant => contactsManager.getParticipant(participant)) ?? [];
		const updatedParticipants = participants?.map(x => ({
			...x,
			name: x.name ?? '-',
			phoneNumber: x.phoneNumber ?? '',
			countryCode: x.countryCode ?? 0,
		}));

		const disabledParticipants = updatedParticipants?.filter(x => x.lastFourNumber) ?? [];
		const localParticipants = updatedParticipants?.filter(x => x.phoneNumber.length) ?? [];

		setDisabledParticipants(
			disabledParticipants.sort((x, y) => {
				return (x.lastFourNumber ?? '').localeCompare(y.lastFourNumber ?? '');
			}),
		);
		setLocalParticipants(localParticipants.sort((x, y) => x.name.localeCompare(y.name || '') || 0));
		setFilteredParticipants(localParticipants);
	}, [participants]);

	// const handleSearchChange = (event) => {
	//   setSearchTerm(event.target.value);
	// };

	const handleToggleCompletion = (participant: Contact) => {
		const accessToken = accountService.getAccessToken();
		let loggedInUserId: string;
		if (accessToken) {
			loggedInUserId = jwtDecode<Account>(accessToken).userId;
			if (participant.userId === loggedInUserId) {
				setWarningAlert({
					show: true,
					message: 'Are you sure you want to exit the circle?',
					okButton: 'exit',
					context: {
						participant,
						type: 'HIDE_PARTICIPANT',
					},
				});
			} else {
				if (participant.name && circle?.name) {
					setWarningAlert({
						show: true,
						message: `Are you sure you want to remove ${participant.name} from ${circle.name}?`,
						okButton: 'remove',
						context: {
							participant,
							type: 'REMOVE_PARTICIPANT',
						},
					});
				}
			}
		}
	};

	const hideAlert = () => {
		setAlert({
			show: false,
			message: '',
		});
		setWarningAlert({ show: false });
	};

	useEffect(() => {
		if (searchTerm.length === 0) {
			if (localParticipants.length > 0) {
				setFilteredParticipants(localParticipants);
			}
		} else {
			const filtered = localParticipants.filter(
				participant =>
					participant.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
					participant.phoneNumber.includes(searchTerm),
			);
			setFilteredParticipants(filtered);
		}
	}, [searchTerm, localParticipants]);

	const getName = (participant: Contact) => {
		return participant.name && participant.name.length > 0 ? participant.name : '-';
	};

	const getPhoneNumber = (participant: Contact) => {
		let phoneNumber: string;
		if (participant.phoneNumber) {
			phoneNumber = participant.phoneNumber;
		} else if (participant.lastFourNumber) {
			phoneNumber = `xxx.xxx.${participant.lastFourNumber}`;
		} else {
			return;
		}

		if (participant.countryCode) {
			return `+${participant.countryCode.toString()} ${phoneNumber}`;
		}
	};

	const warningAlertAction = () => {
		if (warningAlert.context) {
			if (warningAlert.context.type === 'REMOVE_PARTICIPANT') {
				_removeParticipant();
			} else if (warningAlert.context.type === 'EXIT_CIRCLE') {
				_exitCircle();
			}
		}
	};
	const _removeParticipant = () => {
		const participant = warningAlert.context?.participant;
		if (participant?.userId && participant.phoneNumber && participant.countryCode) {
			const contacts: ContactRequest[] = [
				{
					userId: participant.userId,
					phoneNumber: participant.phoneNumber,
					countryCode: participant.countryCode,
				},
			];

			removeParticipant({
				circleId,
				contacts,
			});
		}

		const newParticipants = filteredParticipants.filter(x => x.userId !== participant?.userId);
		setFilteredParticipants(newParticipants);
		hideAlert();
	};
	const _exitCircle = () => {
		exitCircle({ circleId });
		setWarningAlert({ show: false });
		navigate(-2);
	};
	const goToAddParticipants = () => {
		navigate(`/secure/circles/${circleId}/participants/add`);
	};

	return (
		<AnimatedParent>
			{alert.show && <EssenceAlert onOk={hideAlert} okButton="okay" message={alert.message}></EssenceAlert>}

			{warningAlert.show && warningAlert.okButton && warningAlert.message && (
				<EssenceAlert
					onOk={warningAlertAction}
					okButton={warningAlert.okButton}
					onDismiss={hideAlert}
					dismissButton={'cancel'}
					message={warningAlert.message}></EssenceAlert>
			)}
			{member && (
				<Page title={member}>
					<div>
						{/* <Animated>
            <input
              essence-input="true"
              type="text"
              placeholder={"search " + member}
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Animated> */}
						{Capacitor.isNativePlatform() ? (
							<Animated>
								<Clickable key="add-participants">
									<div className="headline-4 lowercase my-6" onClick={goToAddParticipants}>
										+ add {member}
									</div>
								</Clickable>
							</Animated>
						) : (
							<></>
						)}

						{filteredParticipants.map(participant => {
							return (
								<EssenceCheckbox
									key={participant.userId}
									title={getName(participant)}
									subTitle={getPhoneNumber(participant)}
									checked={true}
									onChange={() => {
										handleToggleCompletion(participant);
									}}
								/>
							);
						})}

						{disabledParticipants.length > 0 && (
							<div className="mt-10 body-1 opacity-50">
								Below {member} can only be removed by the person who added them.
							</div>
						)}
						{disabledParticipants.map(participant => {
							return (
								<EssenceCheckbox
									key={participant.userId}
									title={getName(participant)}
									subTitle={getPhoneNumber(participant)}
									disabled={true}
									checked={true}
									onChange={() => {
										handleToggleCompletion(participant);
									}}
								/>
							);
						})}

						{Capacitor.isNativePlatform() ? (
							<></>
						) : (
							<>
								<div className="body-1 mt-10">To add {member} download the app on your mobile</div>
								<div className="flex gap-5 mt-3">
									<a
										href="https://circlesco.app.link/apple-download"
										target="_blank"
										rel="noreferrer">
										<img
											alt="apple logo"
											src="/images/badge_download_appstore.png"
											className={styles['download-logo']}></img>
									</a>
									<a
										href="https://circlesco.app.link/android-download"
										target="_blank"
										rel="noreferrer">
										<img
											alt="android logo"
											src="/images/badge_download_playstore.png"
											className={styles['download-logo']}></img>
									</a>
								</div>
							</>
						)}

						{/* <div className={`${styles["add-participants"]} mt-10`}>
              <EssenceButton
                name="save participants"
                onClick={saveParticipants}
              />
            </div> */}
					</div>
				</Page>
			)}
		</AnimatedParent>
	);
};

export default CircleParticipants;
