export const circlesKeys = {
	all: () => ['circles'],
	detail: (circleId: string) => [...circlesKeys.all(), circleId],
	participants: (circleId: string | undefined) => [...circlesKeys.all(), circleId, 'participants'],
	updateParticipants: () => [...circlesKeys.all(), 'participants', 'update'],
	removeParticipants: () => [...circlesKeys.all(), 'participants', 'remove'],
	list: () => [...circlesKeys.all(), 'list'],
	add: () => [...circlesKeys.all(), 'add'],
	delete: () => [...circlesKeys.all(), 'delete'],
};
