import { useState } from 'react';
import EssenceAnimatedButton from '../../../../components/essence/button/animated-button';
import Header from '../../../../shared/components/header/page';
import Content from '../../../../shared/components/content/page';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import EssenceDatePicker from '../../../../components/essence/date-picker/date-picker';
import { useExpenseMutations } from '../../../../shared/tanstack/expenses/mutations';
import { useMutation } from '@tanstack/react-query';
import { LocationState } from './payee';
import { InvalidCircleIdError } from '../../../../shared/models/errors/invalid-circle-id-error';

const AddSettlementDetail = () => {
	const navigate = useNavigate();
	const { circleId } = useParams();
	if (!circleId) {
		throw new InvalidCircleIdError();
	}
	const location = useLocation();
	const { settlementDetail: stateSettlementDetail } = location.state as LocationState;
	const [settlementDetail, setSettlementDetail] = useState(stateSettlementDetail);

	const { addExpenseMutation, updateExpenseMutation } = useExpenseMutations(circleId);

	const { mutate: addExpense } = useMutation(addExpenseMutation);
	const { mutate: updateExpense } = useMutation(updateExpenseMutation);

	const onAmountChange = ($event: React.ChangeEvent<HTMLInputElement>) => {
		const regex = new RegExp(/^[0-9]*\.?[0-9]{0,2}?$/);
		const amount = $event.target.value;

		if (regex.test(amount) && settlementDetail) {
			setSettlementDetail({
				...settlementDetail,
				amount: +amount,
			});
		}
	};

	const onBlur = () => {
		if (settlementDetail) {
			if (settlementDetail.amount) {
				setSettlementDetail({
					...settlementDetail,
					amount: parseFloat(settlementDetail.amount.toString()),
				});
			}
		}
	};

	const settleAmount = (e: React.SyntheticEvent) => {
		e.preventDefault();
		//TODO: check for settlement model it is currently using expense model for settlement which generates the below errrors.
		if (settlementDetail) {
			const creditors = settlementDetail.creditors ?? [];
			const debtors = settlementDetail.debtors ?? [];

			const settlment = {
				...settlementDetail,
				creditors: creditors,
				debtors: debtors,
			};
			if (settlementDetail.expenseId) {
				updateExpense(settlment);
				navigate(-3);
			} else {
				addExpense(settlment);
				navigate(-2);
			}
		}
	};

	if (settlementDetail) {
		return (
			<>
				<Header title="Details" subtitle={`Enter the details of the settlement.`} />
				<Content>
					<div>
						<div className="headline-5">Amount</div>
						<input
							onBlur={onBlur}
							essence-input="true"
							placeholder="Amount"
							value={settlementDetail.amount}
							onChange={onAmountChange}
							name="settlement-amount"
							type="tel"
						/>
					</div>
					<div className="mt-3 mb-6">
						<div className="headline-5">Date</div>
						<EssenceDatePicker
							date={settlementDetail.dateTime}
							onAccept={() => {
								//TODO: See how to set settlement detail
								// setSettlementDetail({
								// 	...settlementDetail,
								// 	dateTime: event.target.value.toISOString(),
								// });
							}}></EssenceDatePicker>
					</div>
					<EssenceAnimatedButton onClick={settleAmount} name="settle up" />
				</Content>
			</>
		);
	}
};

export default AddSettlementDetail;
