import { useEffect, useState } from 'react';
import Animated from '../../components/essence/animated/animated';
import useLoaderDisplay from '../../shared/hooks/loader-display';
import { useTodoMutations } from '../../shared/tanstack/todos/mutations';
import { useTodoQueries } from '../../shared/tanstack/todos/queries';
import { useQuery, useMutation } from '@tanstack/react-query';
import { Todo as TodoItem } from '../../shared/models/todo';
import AddTodoForm from './components/add-todo-form/page';
import { Capacitor } from '@capacitor/core';
import accountService from '../../shared/services/account-service';
import { jwtDecode } from 'jwt-decode';
import { Account } from '../../shared/models/account';
import { BranchDeepLinks } from 'capacitor-branch-deep-links';
import { Share } from '@capacitor/share';
import ListTodo from './components/list-todo/page';

interface Props {
	circleId: string;
	circleName: string;
}
export default function Todo({ circleId, circleName }: Props) {
	const { toggleToDoMutation, addToDoMutation, deleteToDoMutation } = useTodoMutations(circleId);
	const { mutate: toggleToDo } = useMutation(toggleToDoMutation);
	const { mutate: addToDo } = useMutation(addToDoMutation);
	const { mutate: deleteToDo } = useMutation(deleteToDoMutation);

	const { toDoQuery } = useTodoQueries();
	const { data: todos, isLoading } = useQuery(toDoQuery(circleId));
	useLoaderDisplay({ key: 'getToDos', isLoading });

	const [activeTodos, setActiveTodos] = useState<TodoItem[]>(todos?.filter(todo => todo.status === 'PENDING') ?? []);
	const [completedTodos, setCompletedTodos] = useState<TodoItem[]>(
		todos?.filter(todo => todo.status === 'COMPLETED') ?? [],
	);
	const { reorderToDoMutation, editToDoMutation } = useTodoMutations(circleId);
	const { mutate: reorderToDo } = useMutation(reorderToDoMutation);
	const { mutate: editToDo } = useMutation(editToDoMutation);

	useEffect(() => {
		if (todos) {
			setActiveTodos(todos.filter(todo => todo.status === 'PENDING'));
			setCompletedTodos(todos.filter(todo => todo.status === 'COMPLETED'));
		}
	}, [todos]);

	const shareToDos = async () => {
		let shareText = `Here is a list of todos for ${circleName}. Try out Circles to manage your todos, expense and much more.\n\n`;
		activeTodos.forEach(todo => {
			shareText += `[   ]  ${todo.name}\n`;
		});

		completedTodos.forEach(todo => {
			shareText += `[✓]  ${todo.name}\n`;
		});

		let url = 'https://circlesco.app.link/share';
		if (Capacitor.getPlatform() !== 'web') {
			// optional fields
			const accessToken = accountService.getAccessToken() ?? '';
			const loggedInUserId = jwtDecode<Account>(accessToken).userId;
			const analytics = {
				channel: 'native',
				feature: 'referral',
				campaign: 'referral for pr',
				stage: 'new user',
			};
			const properties = {
				referrerId: loggedInUserId,
			};
			// share sheet
			const res = await BranchDeepLinks.generateShortUrl({
				analytics,
				properties,
			});
			url = res.url;
		}

		await Share.share({ url: url, text: shareText });
	};

	const addToDoRequest = (newTodo: string) => {
		addToDo({
			circleId,
			name: newTodo,
		});
	};

	const handleToggleCompletion = (toDoId: string) => {
		toggleToDo({ toDoId, circleId });
	};

	const handleRemoveTodo = (toDoId: string) => {
		deleteToDo({ toDoId, circleId });
	};

	const handleReorderTodos = (todos: TodoItem[]) => {
		if (todos.length > 0) {
			const reorderedTodos = todos.map((todo, i) => {
				return { order: i, ...todo };
			});

			reorderToDo(reorderedTodos);
		}
	};

	const handleEditTodo = (todo: TodoItem, text: string) => {
		editToDo({ toDoId: todo.toDoId, name: text });
	};
	return (
		<>
			<Animated>
				<AddTodoForm
					onAddTodo={addToDoRequest}
					onShare={() => {
						shareToDos().catch(() => {
							// Do nothing
						});
					}}
				/>
			</Animated>
			<ListTodo
				showIndex={true}
				todos={activeTodos}
				setTodos={setActiveTodos}
				onToggle={handleToggleCompletion}
				onRemove={handleRemoveTodo}
				onReorder={handleReorderTodos}
				onEdit={handleEditTodo}
			/>

			<ListTodo
				showIndex={true}
				todos={completedTodos}
				setTodos={setCompletedTodos}
				onToggle={handleToggleCompletion}
				onRemove={handleRemoveTodo}
				onReorder={handleReorderTodos}
				onEdit={handleEditTodo}
			/>
		</>
	);
}
