import { Account } from '../../models/account';
import api from '../../services/api';

interface SendOtpRequest {
	phoneNumber: string;
	countryCode: number;
}
export const sendOtp = async (body: SendOtpRequest) => await api.post(`/api/account/sendLoginOtp`, body);

export interface VerifyOtpRequest {
	phoneNumber: string;
	countryCode: number;
	otp: string;
	fcmToken?: string;
	referrerId?: string;
}

export const verifyOtp = async (body: VerifyOtpRequest) => {
	const queryParams = body.referrerId ? `referredBy=${body.referrerId}` : '';
	return await api.post<Account>(`/api/account/verifyOtp?${queryParams}`, body).then(res => res.data);
};

export interface UpdateProfileRequest {
	name: string;
	profileImage?: string;
}
export const updateProfile = async (body: UpdateProfileRequest) => {
	await api.put(`/api/account/profile`, body);
};

export const logout = async () => {
	await api.post(`/api/logout`, {});
};

export const deleteAccount = async () => {
	await api.delete(`/api/account`);
};
