import EssenceAnimatedSwitch from '../../../../../../components/essence/switch/animated-switch';
import { useState } from 'react';
import { ThemeService } from '../../../../../../shared/services/theme-service';

interface Props {
	themeService: ThemeService;
}
export default function ToggleTheme({ themeService }: Props) {
	const [isDarkMode, setDarkMode] = useState(themeService.isDarkMode());

	const onToggleDarkMode = () => {
		document.documentElement.classList.toggle('theme-light');
		if (!isDarkMode) {
			themeService.setDarkMode();
		} else {
			themeService.setLightMode();
		}
		setDarkMode(!isDarkMode);
	};
	return (
		<EssenceAnimatedSwitch
			dataTestId="toggle-theme"
			text={''}
			checked={isDarkMode}
			onChange={() => {
				onToggleDarkMode();
			}}
		/>
	);
}
