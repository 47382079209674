import Clickable from '../core/clickable';
import styles from './button.module.css';

interface Props extends React.ComponentProps<'button'> {
	disabled?: boolean;
	className?: string;
	name: string;
}

export default function EssenceButton({ disabled, className, name, ...props }: Props) {
	return (
		<Clickable disabled={disabled}>
			<button
				disabled={disabled}
				className={`${className ? className : ''} ${styles['essence-button']}`}
				{...props}>
				{name}
			</button>
		</Clickable>
	);
}
