import { useNavigate } from 'react-router-dom';
import Animated from '../../../components/essence/animated/animated';
import Clickable from '../../../components/essence/core/clickable';
import { BranchDeepLinks } from 'capacitor-branch-deep-links';
import { Capacitor } from '@capacitor/core';
import accountService from '../../../shared/services/account-service';
import { jwtDecode } from 'jwt-decode';
import { Account } from '../../../shared/models/account';
import { Share } from '@capacitor/share';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAccountMutations } from '../../../shared/tanstack/account/mutations';
import coachMarkManager from '../../../secure/services/coach-mark';

export default function Settings() {
	const navigate = useNavigate();
	const goToProfile = () => {
		navigate('/secure/settings/profile', {
			state: { context: 'UPDATE_PROFILE' },
		});
	};
	const queryClient = useQueryClient();
	const { logoutMutation } = useAccountMutations();
	const { mutate: logout } = useMutation(logoutMutation);

	const shareApp = async () => {
		const shareText =
			'Discover a world of possibilities – join us on Circles! Click the link to embark on your journey';
		let url = 'https://circlesco.app.link/share';
		if (Capacitor.getPlatform() !== 'web') {
			// optional fields
			const accessToken = accountService.getAccessToken() ?? '';
			const loggedInUserId = jwtDecode<Account>(accessToken).userId;
			const analytics = {
				channel: 'native',
				feature: 'referral',
				campaign: 'referral for pr',
				stage: 'new user',
			};
			const properties = {
				referrerId: loggedInUserId,
			};
			// share sheet
			const res = await BranchDeepLinks.generateShortUrl({
				analytics,
				properties,
			});
			url = res.url;
		}
		await Share.share({ url: url, text: shareText });
	};
	const goToTheme = () => {
		navigate('/secure/settings/theme');
	};
	const goToPrivacy = () => {
		navigate('/privacy', {
			state: { context: 'UPDATE_PROFILE' },
		});
	};
	const goToTerms = () => {
		navigate('/terms', {
			state: { context: 'UPDATE_PROFILE' },
		});
	};
	const onLogout = () => {
		accountService.removeToken();
		queryClient.removeQueries();
		coachMarkManager.reset();
		logout();
		navigate('/auth/login', { replace: true });
	};

	return (
		<div className="flex flex-col">
			<Animated>
				<Clickable key="profile">
					<div className="headline-4 lowercase" onClick={goToProfile}>
						profile
					</div>
				</Clickable>
			</Animated>
			<div className="mt-4" />
			<Animated>
				<Clickable key="share">
					<div className="headline-4 lowercase" onClick={() => void shareApp()}>
						share the app
					</div>
				</Clickable>
			</Animated>
			<div className="mt-4" />
			<Animated>
				<Clickable key="theme">
					<div className="headline-4 lowercase" onClick={goToTheme}>
						Theme
					</div>
				</Clickable>
			</Animated>
			<div className="mt-4" />
			<Animated>
				<Clickable key="privacy_policy">
					<div className="headline-4 lowercase" onClick={goToPrivacy}>
						Privacy Policy
					</div>
				</Clickable>
			</Animated>
			<div className="mt-4" />
			<Animated>
				<Clickable key="tos">
					<div className="headline-4 lowercase" onClick={goToTerms}>
						Terms & Condition
					</div>
				</Clickable>
			</Animated>
			<div className="mt-4" />
			<Animated>
				<Clickable key="logout">
					<div className="headline-4 lowercase" onClick={onLogout} style={{ color: 'red' }}>
						logout
					</div>
				</Clickable>
			</Animated>
		</div>
	);
}
