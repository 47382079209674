import { useQueryClient } from '@tanstack/react-query';
import { Contact } from './api';
import { contactsKeys } from './keys';

export const useContactsQueries = () => {
	const queryClient = useQueryClient();
	const contactsQuery = () => ({
		refetchOnReconnect: false,
		queryKey: contactsKeys.all(),
		queryFn: () => {
			const contacts = queryClient.getQueryData<Contact[]>(contactsKeys.all()) ?? [];
			return Promise.resolve(contacts);
		},
	});

	return {
		contactsQuery,
	};
};
