import { useEffect, useState } from 'react';
import styles from './country-code-picker.module.css';
import EssencePickerList from '../core/picker-list/picker-list';
import EssencePickerOverlay from '../core/picker-overlay/picker-overlay';
import { PickerOption } from '../core/model/picker-option';

interface Props {
	onClose?(): void;
	onChange(option: PickerOption): void;
}
export default function CountryCodePicker({ onClose, onChange }: Props) {
	const countryCodes: PickerOption[] = [
		{ title: '+91', subtitle: 'India', index: 0 },
		// { title: "+1", subtitle: "USA" },
		{ title: '+44', subtitle: 'UK', index: 1 },
		{ title: '+49', subtitle: 'Germany', index: 2 },
		// { title: "+965", subtitle: "Kuwait" },
	];

	const [internalCountryCode, setInternalCountryCode] = useState(countryCodes[0]);
	const [hidePicker, setHidePicker] = useState(true);

	const [countryCode, setCountryCode] = useState(countryCodes[0]);

	useEffect(() => {
		onChange(countryCode);
	}, [countryCode, onChange]);

	const onCountryCodeChange = (option: PickerOption) => {
		setInternalCountryCode(option);
	};

	const accept = () => {
		setHidePicker(true);
		setCountryCode(internalCountryCode);
		onChange(internalCountryCode);
	};

	const reject = () => {
		setHidePicker(true);
		if (onClose) {
			onClose();
		}
	};

	const openPicker = (event: React.MouseEvent<HTMLDivElement>) => {
		event.preventDefault();
		setHidePicker(false);
	};

	return (
		<div>
			<div className={styles['country-code-picker-input']} onClick={openPicker}>
				<input essence-input="true" value={countryCode.title} readOnly inputMode="none" className="w-full" />
			</div>
			{!hidePicker && (
				<EssencePickerOverlay onAccept={accept} onReject={reject}>
					<div className={styles['picker-container']}>
						<EssencePickerList
							id="date-picker-days-id"
							value={countryCode}
							onChange={onCountryCodeChange}
							options={countryCodes}></EssencePickerList>
					</div>
				</EssencePickerOverlay>
			)}
		</div>
	);
}
