import './App.css';
import { BrowserRouter } from 'react-router-dom';
import AnimatedRoutes from './components/AnimatedRoutes';
import Loader from './components/essence/loader/loader';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { setDefaultMutations } from './shared/tanstack/default-mutations';
import { createIDBPersister } from './shared/tanstack/indexeddb-persister';
import { onlineManager } from '@tanstack/react-query';
import { Network } from '@capacitor/network';
import { BranchDeepLinks } from 'capacitor-branch-deep-links';

interface BranchArgs {
	referrerId?: string;
}
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			networkMode: 'online',
			gcTime: Infinity,
			retry: 0,
		},
	},
});
const persister = createIDBPersister();

setDefaultMutations(queryClient);

// This is a hack for the following situation:
// If offline and when the app starts React Query is not correctly setting
// the status as offline. Hence we're using capacitor's status to
// correctly set the status of online manager.
Network.getStatus()
	.then(async status => {
		if (status.connected) {
			await queryClient.resumePausedMutations();
		} else {
			onlineManager.setOnline(false);
		}
		return true;
	})
	.catch(() => {
		// Do nothing
	});

import * as Sentry from '@sentry/react';
import { Capacitor } from '@capacitor/core';

Sentry.init({
	dsn: 'https://c99557d42eb66ac64f30f0c4af7d5122@o4507596380110848.ingest.us.sentry.io/4507596383715328',
	integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (Capacitor.getPlatform() !== 'web') {
	BranchDeepLinks.addListener('init', event => {
		// Retrieve deeplink keys from 'referringParams' and evaluate the values to determine where to route the user
		// Check '+clicked_branch_link' before deciding whether to use your Branch routing logic
		if (event.referringParams['+clicked_branch_link']) {
			const referrerId = (event.referringParams.args as BranchArgs).referrerId;
			if (referrerId) {
				localStorage.setItem('referrerId', referrerId);
			}
		}
	}).catch(() => {
		// Do Nothing
	});

	BranchDeepLinks.addListener('initError', error => {
		console.error(error);
	}).catch(() => {
		// Do nothing
	});
}

function App() {
	return (
		<PersistQueryClientProvider client={queryClient} persistOptions={{ persister, maxAge: Infinity }}>
			<BrowserRouter>
				<div className="loader" id="loader">
					<Loader />
				</div>
				<AnimatedRoutes />
			</BrowserRouter>
		</PersistQueryClientProvider>
	);
}

export default App;
