export const transformScale = (element: Node, scale: number) => {
	if (element instanceof HTMLElement) {
		if (!element.style.transform.includes('scale(')) {
			element.style.transform = element.style.transform + ` scale(${scale.toString()} `;
		} else {
			element.style.transform = element.style.transform.replace(
				/scale\([0-9|.]*\)/,
				` scale(${scale.toString()}) `,
			);
		}
	}
};

export const transformRotation = (element: Node, x: number, y: number) => {
	const rotateX = x.toString();
	const rotateY = y.toString();
	if (element instanceof HTMLElement) {
		if (!element.style.transform.includes('rotateX(')) {
			element.style.transform += ` rotateX(${rotateX}deg) rotateY(${rotateY}deg) `;
		} else {
			element.style.transform = element.style.transform.replace(
				/rotateX\(.*deg\) rotateY\(.*deg\)/,
				`rotateX(${rotateX}deg) rotateY(${rotateY}deg) `,
			);
		}
	}
};
