import { Participant } from '../../shared/models/participant';
import { ExpenseSummary } from '../../shared/models/expense-summary';

interface Props {
	expenseSummary: ExpenseSummary;
	participants: Participant[];
	loggedInUserId?: string;
}

export default function ExpenseSummaryList({ expenseSummary, participants, loggedInUserId }: Props) {
	const _findParticipant = (summary: ExpenseSummary): Participant | undefined => {
		const userId = summary.creditorId !== loggedInUserId ? summary.creditorId : summary.debtorId;
		const filteredParticipant = participants.find(x => x.userId == userId);
		return filteredParticipant;
	};

	const prefix = (summary: ExpenseSummary) => {
		return summary.creditorId !== loggedInUserId ? 'Pay' : 'Collect from';
	};

	return (
		<>
			<div key={expenseSummary.amount}>
				<span
					style={{
						color: expenseSummary.creditorId !== loggedInUserId ? 'rgb(220 120 120)' : '#73b873',
					}}>
					{prefix(expenseSummary)}
				</span>
				{'  '}
				{_findParticipant(expenseSummary)?.name}{' '}
				<span className="headline-4">
					{expenseSummary.currency}
					{expenseSummary.amount}
				</span>
			</div>
		</>
	);
}
