import styles from './page.module.css';

export default function PrivacyPolicy() {
	return (
		<>
			<main className="bg-3 headline-5 m-16">
				<section>
					<h1 className="text-center page-title">Privacy Policy</h1>
					<div className="container">
						<h4 className={styles['page-sub-title']}>Effective Date: May 7th, 2024</h4>
						<h4 className={styles['page-sub-title']}>Last Updated: May 7th, 2024</h4>
						<p className={styles['page-content']}>
							This is the Privacy Policy for Circles by Royale Cheese Innovations LLP and its affiliates
							and subsidiaries. In this policy, we call ourselves “Circles”, “we,” “us,” or “our.” We call
							you, the person using our apps or visiting our website, “you.”
						</p>
						<p className={styles['page-content']}>
							Circles offers a service that allows you to earn rewards from your everyday purchases,
							identify financial savings opportunities, and get paid for sharing your data with Circles
							and our Participating Businesses for market research or personalised ads.
						</p>
						<p className={styles['page-content']}>
							If you have opted out of Data Dividends, we will not share your Personal Information with
							<a href="#participating-business">Participating Businesses</a> for market research or
							personalised advertisements.
						</p>
					</div>
				</section>
				<section>
					<div className="container">
						<p className={styles['page-content']}>
							This Privacy Policy document outlines the types of information that is collected and
							recorded by Circles, how we use it and why.
						</p>
						<p className={styles['page-content']}>
							This Privacy Policy applies to all the Users whose Personal Information has been processed
							by Us in the course of our business, mobile applications, forums, blogs, and other online or
							offline offerings
						</p>
						<p className={styles['page-content']}>
							By visiting the Platform, You (“You” or “Your”), accept and agree to be bound by the terms
							and conditions of this privacy policy (“Privacy Policy”).
						</p>
						<p className={styles['page-content']}>
							By using the Service, you hereby consent to allow us to process information in accordance
							with this Privacy Policy. Please also refer to our Terms of Service, available here
							https://circles.royalecheese.com/terms, which are incorporated as if fully recited herein.
							Terms defined in the Terms of Service that are not defined herein shall have the same
							definition as in the Terms of Service. This policy is subject to change. If our information
							retention or usage practices change, we will let you know by posting the Privacy Policy
							changes on the Service and/or otherwise making you aware of the changes. Your continued use
							of the Service following our notice of changes to this Privacy Policy (or other method of
							legal acceptance) means you accept such changes. Please refer to the “Effective Date” above
							to see when this Policy was last updated.
						</p>

						<h4 className={styles['page-sub-title']}>Personal Information We Collect</h4>
						<h4 className={styles['page-sub-title']}>What is Personal Information?</h4>
						<p className={styles['page-content']}>
							When we use the term “Personal Information” in this Privacy Policy, we mean information that
							identifies, relates to, describes, is reasonably capable of being associated with, or could
							reasonably be linked, directly or indirectly, to an individual. It does not include
							aggregated or de-identified information that is maintained in a form that is not reasonably
							capable of being associated with or linked to an individual.
						</p>
						<p className={styles['page-content']}>
							We collect Personal Information in connection with your visits to and use of the Service.
							This collection includes information that you provide in connection with the Service,
							information from third parties, and information that is collected automatically such as
							through the use of cookies and other tracking technologies.
						</p>

						<h4 className={styles['page-sub-title']}>What Personal Information is collected from you?</h4>
						<p className={styles['page-content']}>
							We collect Personal Information from you that you may provide to us through the Service or
							otherwise. The categories of information we collect may include
						</p>
						<p className={styles['page-content']}>
							<span>Contact information</span>,For example, your first and last name, email and mailing
							addresses, and phone number.
						</p>
						<p className={styles['page-content']}>
							<span>Account and profile information</span>, For example, Personal Information you provide
							to us after you create a user account. The Personal Information we collect could include
							your contact information, Demographic data, date of birth, gender, interests , credit score,
							income, Transaction Data, Digital Purchase Data,Online/physical Receipt Data, Banking data,
							Browsing Data, Survey Responses, Cashback Offer Data and Health Information. We also collect
							additional information if you provide it.
						</p>
						<p className={styles['page-content']}>
							<span>Communications data</span>, based on our exchanges with you, including when you
							contact us through the Service, social media, or otherwise;
						</p>
						<p className={styles['page-content']}>
							<span>Transaction Data</span>,This includes the data related to your linked bank account(s)
							or credit or debit card(s) (a “Payment Credential”): account numbers and card transaction
							data collected and stored by our third-party providers (including credit and/or debit card
							transaction data collected by our third-party providers, that covers the prior 48 months and
							all subsequent transactions ), bank fees and refunds, direct deposits, rent and/or mortgage
							payments, and bill payments (collectively, the “Transaction Data”).
						</p>
						<p className={styles['page-content']}>
							<span>Digital Purchase Data</span>,We also collect purchase information, such as
							item/SKU-level data, through email accounts or digital consumer accounts that you link to
							your Circles account (collectively, the “Linked Account(s)”). Our Rewards Program allows you
							to link email accounts or digital consumer accounts to your Circles account, as directed by
							you, through the use of your login credentials to such account(s) so that Circles, as your
							agent, can retrieve purchase information from digital receipts from historical (up to 50
							years prior) and future purchases in your digital consumer account or email. In processing
							your digital receipt data, Circles may retrieve Personal Information such as name, payment
							method, billing address, mailing address, and other information contained in the digital
							receipt email (collectively, the “Digital Account Data”).
						</p>
						<p className={styles['page-content']}>
							The account credentials that you enter for these accounts are not stored with Circles, but
							are encrypted and stored locally on your device.
						</p>
						<p className={styles['page-content']}>
							While linked, Circles will automatically collect digital receipt data from your linked email
							accounts.
						</p>
						<p className={styles['page-content']}>
							<span>Survey Responses</span>,For example, information you provide when answering survey
							questions in the App, which may include information about your activities/interests,
							opinions about products/services, household information, and other demographic information.
						</p>
						<p className={styles['page-content']}>
							<span>Marketing data</span>, such as when you sign up to receive information regarding our
							products and details about your engagement with them;
						</p>
						<p className={styles['page-content']}>
							<span>Job application data</span>, such as information you submit in connection with an
							application for a job (including information in a CV, resume or otherwise collected through
							the job application process);
						</p>
						<p className={styles['page-content']}>
							<span>Third-party sources</span>. We may combine personal information we receive from you
							with personal information we obtain from other sources, such as:
						</p>
						<p className={styles['page-content']}>
							<span>Public sources</span>, such as government agencies, public records, social media
							platforms, and other publicly available sources;
						</p>
						<p className={styles['page-content']}>
							<span>Private sources</span>, such as social media platforms, including Instagram, LinkedIn,
							and Twitter.
						</p>
						<p className={styles['page-content']}>
							<span>Automatic data collection</span>. We, our service providers, and our business partners
							may automatically log information about you, your computer, and your interaction over time
							with the Service, our communications and other online services, such as: Device data, such
							as your computer operating system type and version, manufacturer and model, browser type,
							screen resolution, RAM and disk size, CPU usage, device type (e.g., phone, tablet), IP
							address, unique identifiers (including identifiers used for advertising purposes), language
							settings, mobile device carrier, radio/network information (e.g., Wi-Fi, LTE, 3G), and
							general location information such as city, state or geographic area; Online activity data,
							such as pages or screens you viewed, how long you spent on a page or screen, the website you
							visited before browsing to the Service, navigation paths between pages or screens,
							information about your activity on a page or screen, access times and duration of access,
							and whether you have opened our emails or clicked links within them.
						</p>
						<p className={styles['page-content']}>
							<span>Cookies and similar technologies</span>. Some of the automatic collection described
							above is facilitated by the following technologies:
						</p>
						<p className={styles['page-content']}>
							Cookies, which are small text files that websites store on user devices and that allow web
							servers to record users’ web browsing activities and remember their submissions,
							preferences, and login status as they navigate a site. Cookies used on our sites include
							both “session cookies” that are deleted when a session ends, “persistent cookies” that
							remain longer, “first party” cookies that we place and “third party” cookies that our
							third-party business partners and service providers place.
						</p>
						<p className={styles['page-content']}>
							<span> Data Connections</span>, We may receive your personal and digital data from external
							accounts, such as Flipkart, Amazon or Google once connected
						</p>
						<p className={styles['page-content']}>
							<span>For Gmail users:</span>
							<br />
							<br />
							We comply with the
							<a
								rel="noreferrer"
								target="_blank"
								href="https://developers.google.com/terms/api-services-user-data-policy">
								Google API Services User Data Policy
							</a>
							, including the Limited Use requirements, and use information collected through your use of
							My Purchases from your Gmail account only to provide you points for your purchases on behalf
							of our participating partners and to improve user-facing features on the App or Website. We
							do not share your information that we receive through Google Gmail APIs with third parties
							other than those service providers as necessary to support functions we need to provide you
							points for your purchases, for legal or security reasons, or as part of an acquisition or
							liquidation.
						</p>
						<p className={styles['page-content']}>
							<span>User content</span>, For example, information about how you use the Service and
							interact with us, including information associated with any content you submit to us, and
							information you provide when you use any interactive features of the Service.
						</p>
						<p className={styles['page-content']}>
							<span>Other information, </span>
							<br />
							<br />
							We may collect additional types of information which we will use in accordance with this
							Privacy Policy or as otherwise disclosed at the time of collection.
						</p>

						<h4 className={styles['page-sub-title']}>
							What Personal Information is collected from third-parties?
						</h4>
						<p className={styles['page-content']}>
							We also obtain Personal Information from third parties, which we often combine with Personal
							Information we collect either automatically or directly from you. We may receive the same
							categories of Personal Information as described above from the following third parties:
						</p>
						<p className={styles['page-content']}>
							<span>Information we obtain from social media platforms</span>
							<br />
							<br />
							We may maintain pages on social media platforms, such as Facebook, LinkedIn, Twitter,
							Google, YouTube, Instagram, and other third-party platforms. When you visit or interact with
							our pages on those platforms, the platform provider’s privacy policy will apply to your
							interactions and their collection, use and processing of your Personal Information. You or
							the platforms may provide us with information through the platform, and we will treat such
							information in accordance with this Privacy Policy.
						</p>
						<p className={styles['page-content']}>
							<span>Information we obtain from health trackers and apps</span>
							<br />
							<br />
							At your request, we may also collect Health Information gathered or generated by your use of
							any third-party health application, system, program and/or device that you connect to the
							Service (the “Connected Health Systems”).
						</p>
						<p className={styles['page-content']}>
							For example, if you choose to connect Apple’s Health app on your iPhone or Apple Watch, we
							may receive information such as Activity History (i.e., steps taken, workouts, sleep etc.,),
							Health Vitals (i.e., body temperature, heart rate), Self-Reported Characteristics (i.e.,
							height, weight, body fat percentage, gender), Clinical Records (i.e., protein samples, lab
							results) and information about the source of such data. The data we receive from these
							third-party sites is dependent upon that third party’s policies and your privacy settings on
							that third-party application, system, program and/or device. You should always review, and
							if necessary, adjust your privacy settings on third-party websites and services before
							linking or connecting them to our Service.
						</p>
						<p className={styles['page-content']}>
							<span>Information we obtain from service providers</span>
							<br />
							<br />
							Our service providers that perform services solely on our behalf, such as payment
							processors, video and audio providers, or survey and certain advertising/marketing
							providers, collect Personal Information and may share some or all of this information with
							us.
						</p>
						<p className={styles['page-content']}>
							<span>Referrals</span>
							<br />
							<br />
							Users of the Service may refer friends or other contacts to us. If you are an existing user,
							you may only submit a referral if you have permission to provide the referral’s contact
							information to us so that we may contact them.
						</p>
						<p className={styles['page-content']}>
							<span>Information we obtain from other third parties</span>
							<br />
							<br />
							We may receive Personal Information about you from third-party sources. For example, a
							business partner may share your contact information with us if you have expressed interest
							in learning specifically about our products or services, or the types of products or
							services we offer. We may obtain your Personal Information from other third parties, such as
							marketing partners, publicly available sources and data providers. Personal Information
							obtained from these third parties may include payroll information (e.g., hours worked, wages
							earned, and tax withholdings etc., ). We also may obtain your information from brand
							partnerships or through transactions such as mergers and acquisitions.
						</p>
						<p className={styles['page-content']}>
							<span>Deidentified information</span>
							<br />
							<br />
							Through the provision of our Service, we may also process deidentified information that
							cannot reasonably be used to infer information about, or otherwise be linked to, a
							particular consumer or household. Circles commits to maintain and use the information in
							deidentified form and will not attempt to reidentify the information, except in instances
							where necessary for determining whether the deidentification process used by Circles
							satisfies the requirements under applicable law.
						</p>

						<h4 className={styles['page-sub-title']}>How we use and protect your Personal Information</h4>
						<p className={styles['page-content']}>
							Circles uses your Personal Information to deliver the Service to you, the goal of which is
							to help you earn money on everyday purchases and to identify relevant savings opportunities.
							This includes using your Personal Information to do the following:
						</p>
						<div className={styles['page-content']}>
							<span>Fulfill your requests and provide the Service to you</span>
							<br />
							<br />
							This includes
							<ul className="page-bullets">
								<li>Making the Service available to you and Participating Businesses;</li>
								<li>
									Coordinating access to your account, and establishing and maintaining your user
									profile on the Service;
								</li>
								<li>
									Enabling the security features of the Service, such as by sending you security codes
									via email or SMS, and remembering devices from which you have previously logged in;
								</li>
								<li>
									Responding to requests, suggestions, questions, and comments, and providing other
									types of user support;
								</li>
								<li>
									Sending you service/transaction-related messages, such as changes to your account;
								</li>
								<li>
									Communicating about, and administering your participation in, events, conferences,
									programs, contests, surveys, polls, panels, questionnaires, and other offers or
									promotions; and Providing support and maintenance for the Service.
								</li>
								<li>
									Serve You Targeted Advertisements. We may use your PII to serve you advertisements
									with the Service that are targeted to your interests.
								</li>
							</ul>
						</div>

						<p className={styles['page-content']}>
							<span>Personalize your experience on our Service</span> <br />
							<br />
							This includes providing you with content or other products or services you might be
							interested in and de-emphasize content you’ve already read or viewed.
						</p>
						<p className={styles['page-content']}>
							<span>Market our service</span>
							<br />
							<br />
							We will send you communications about new features, updates, products, and special offers.
							We may also use your information to serve you ads about our products or other products or
							services we (or our advertising partners, such as other third-party brands) think you might
							find interesting. We may also use individual and aggregate information about you to inform
							our marketing and advertising campaigns.
						</p>
						<p className={styles['page-content']}>
							<span>Communicate with you</span> <br />
							<br />
							For example, we may communicate with you about your account activities, such as by providing
							you transaction confirmations or alerting you when a subscription is up for renewal. If you
							register with us, we may enroll you in our email newsletters or other periodic electronic
							communications and may also send you user surveys and promotional communications. We may
							communicate with you by email, postal mail, telephone, text message, or other means. For
							more information on how to adjust your communications preferences, see “The choices you have
							regarding our collection, use and sharing practices” section of this Privacy Policy.
						</p>
						<p className={styles['page-content']}>
							<span>Monitor, improve, and develop our products and services</span>
							We may use your information to understand our visitors and customers and to tailor or
							optimize our Service. For example, we may analyze statistics and trends to make our Service
							better and to develop the Site or new services or features.
						</p>
						<p className={styles['page-content']}>
							<span>Prevent fraud</span>
							<br />
							<br />
							We may use your Personal Information and disclose it to law enforcement, government
							authorities, and private parties as we believe necessary or appropriate to protect,
							investigate and deter against fraudulent, harmful, unauthorized, unethical or illegal
							activity.
						</p>
						<p className={styles['page-content']}>
							<span>
								Protect the security and integrity of our business, comply with legal requirements and
								obligations, or as otherwise permitted by law
							</span>
							<br />
							<br />
							We may use information to protect Pogo, our users, and our Service. We may also use
							information in order to comply with laws, regulations, court orders, or other legal
							obligations or to assist in an investigation, protect and defend our rights and property, or
							the rights or safety of third parties, enforce our Terms of Use, this Privacy Policy, or
							agreements with third parties, detect and prevent fraud or for crime-prevention purposes, or
							for any other reason permitted by law. We may take part in or be involved with a corporate
							business transaction, such as a merger, acquisition, joint venture, or financing or sale of
							company assets and may use information in connection with or as an asset in such a corporate
							business transaction. Personal Information may also be used in the event of insolvency,
							bankruptcy, or receivership.
						</p>
						<p className={styles['page-content']}>
							<span>Create anonymous, aggregated or de-identified data</span>
							<br />
							<br />
							We may use this anonymous, aggregated or de-identified data and share it with third parties
							for our lawful business purposes, including to analyze and improve the Service and promote
							our business.
						</p>
						<p className={styles['page-content']}>
							<span>
								For any other purposes with your consent, at your direction, or where notice is provided
							</span>
							<br />
							<br />
							We may use your Personal Information for any other purposes with your consent, at your
							direction, or where notice is otherwise provided.
						</p>
						<div className={styles['page-content']}>
							<span>Purchase Data</span>
							<br />
							<br />
							In addition to the use cases described above, Circles and
							<a href="#participating-business">Participating Businesses</a> may use your Transaction
							Data, Digital Purchase Data, Other Receipts Data, Browsing Data, Location data and Survey
							Responses (collectively, the “Purchase Data”) in these ways to deliver the Service to you
							<ul className="page-bullets">
								<li>To credit you with automatic cash back when you make qualifying purchases;</li>
								<li>
									For market research and providing insights into consumer purchasing behaviors and
									conducting surveys based on past purchases;
								</li>
								<li>To provide targeted offers that may be of interest to you;</li>
								<li>To confirm a specific transaction occurred or rewards should be provided;</li>
								<li>
									To create a record of your Purchase Data to use data in connection with the Service;
								</li>
								<li>
									To respond to a request from government authority or a payment organization involved
									in a transaction with you or a Participating Business;
								</li>
								<li>
									To analyze bank fees charged on your Payment Credential to: (i) generate an email
									you can send to your bank to request the bank to provide a refund of such fees, if
									applicable; and (ii) recommend a low interest loan from a partner to pay off high
									interest credit card debt;
								</li>
								<li>
									To help identify ways you can save money on insurance, loans, bills, utilities,
									prescriptions, mortgage payments and other third-party services.
								</li>
								<li>To provide you rewards relating to item/SKU-level purchases.</li>
							</ul>
						</div>
						<div className={styles['page-content']}>
							<span>Health Information</span> <br />
							<br />
							With your permission, your Health Information may be used by or shared with Circles and
							<a href="#participating-business">Participating Businesses</a> in these ways to deliver the
							Service to you
							<ul className="page-bullets">
								<li>
									For market research and providing insights into consumer health behaviors/conditions
									and conducting surveys based on health attributes;
								</li>
								<li>To build and enhance consumer profiles;</li>
								<li>To provide targeted offers that may be of interest to you;</li>
								<li>
									To surface clinical study or other research study opportunities for which you may be
									eligible to participate in.
								</li>
							</ul>
						</div>

						<p className={styles['page-content']}>
							<span>Security Practices</span>
							<br />
							<br />
							We employ a number of organizational, technical and physical safeguards designed to protect
							the Personal Information we collect.
						</p>

						<p className={styles['page-content']}>
							We do not store your payment card or bank account information, and we do not have control
							over or responsibility for your payment card or bank account information. When you provide
							your payment card and/or bank account information through the Site or App, it is stored
							and/or processed by our third-party service providers.
						</p>

						<p className={styles['page-content']}>
							Please note that security risk is inherent in all internet and information technologies, and
							we cannot guarantee the security of your Personal Information.
						</p>

						<h4 className={styles['page-sub-title']}>How we share your personal information</h4>
						<p className={styles['page-content']}>
							Circles may disclose your Personal Information when you share content with others on the
							Service, when you consent or instruct us to share your information with third parties, or
							when we have a legitimate business or legal need to share your information.
						</p>
						<p className={styles['page-content']}>For example, your information may be shared with</p>

						<div className={styles['page-content']}>
							<span>Affiliates</span>
							<br />
							<br />
							We may share your Personal Information with our corporate parent, subsidiaries, and
							affiliates, for purposes consistent with this Privacy Policy.
							<ul className="page-bullets">
								<li>Making the Service available to you and Participating Businesses;</li>
								<li>
									Coordinating access to your account, and establishing and maintaining your user
									profile on the Service;
								</li>
								<li>
									Enabling the security features of the Service, such as by sending you security codes
									via email or SMS, and remembering devices from which you have previously logged in;
								</li>
								<li>
									Responding to requests, suggestions, questions, and comments, and providing other
									types of user support;
								</li>
								<li>
									Sending you service/transaction-related messages, such as changes to your account;
								</li>
								<li>
									Communicating about, and administering your participation in, events, conferences,
									programs, contests, surveys, polls, panels, questionnaires, and other offers or
									promotions; and
								</li>
								<li>Providing support and maintenance for the Service.</li>
							</ul>
						</div>

						<p className={styles['page-content']} id="participating-business">
							<span>Participating Businesses through the Rewards Program</span>
							<br />
							<br />
							If you have opted in to Data Rewards, we may share your Personal Information, including
							Racial / Ethnic Origin, Precise Geolocation and Health Information, with participating
							businesses, including our affiliates and subsidiaries, our service providers or advisors,
							marketing/advertising and strategic partners, analytics providers; social networks; our
							clients, partnered brands, retailers and agencies (collectively, &quot;Participating
							Businesses&quot;). These Participating Business partners will use this data in accordance
							with their own privacy policies. If you have opted out of Data Rewards, we will not share
							this information with Participating Businesses, in accordance with our market research or
							personalized advertisements initiatives.
						</p>
						<p className={styles['page-content']}>
							<span>Service providers</span>
							<br />
							<br />
							We may share your Personal Information with third party companies and individuals that
							provide services on our behalf or help us operate the Service (such as customer support,
							hosting, analytics, email delivery, advertising, marketing, and database management
							services).
						</p>
						<p className={styles['page-content']}>
							Xoxoday. We use xoxoday.com to process your redemption request, which collects your contact
							information and cash out method preference to issue payment. You may find out more about how
							xoxoday provides services by accessing xoxoday’s privacy policy (see
							<a target="_blank" rel="noreferrer" href="https://www.xoxoday.com/privacy-policy">
								https://www.xoxoday.com/privacy-policy
							</a>
							).
						</p>
						<p className={styles['page-content']}>
							<span>
								Information we process to understand how our products are used and make improvements
							</span>
							<br />
							<br />
							Firebase, Google analytics, Sentry &amp; more.
						</p>
						<p className={styles['page-content']}>
							<span>Pharmaceutical and healthcare partners</span>
							<br />
							<br />
							At your direction, we may share your Personal Information, including your Health and
							Location Data, with our pharmaceutical and healthcare partners.
						</p>

						<p className={styles['page-content']}>
							<span>Lending and insurance partners</span>
							<br />
							<br />
							We may share your pre-qualification information (i.e., birthdate, credit score, gender, and
							income) with our loan and insurance partners.
						</p>
						<p className={styles['page-content']}>
							<span>Other users of the Service and the public</span>
							<br />
							<br />
							We may enable you to disclose Personal Information to other users of the Service or the
							public at your direction. For instance, you may be able to maintain a user profile with
							information about yourself that you can make available to other users or the public. You may
							also be able to submit content to the Service (such as comments, reviews, surveys, blogs,
							photos, and videos), and we will display your name, username, and a link to your user
							profile along with the content you submit. We do not control how other users or third
							parties use any Personal Information that you make available to such users or the public.
						</p>
						<p className={styles['page-content']}>
							<span>Professional advisors</span>
							<br />
							<br />
							We may disclose your Personal Information to professional advisors, such as lawyers,
							bankers, auditors and insurers, where necessary in the course of the professional services
							that they render to us.
						</p>
						<p className={styles['page-content']}>
							<span>For compliance, fraud prevention and safety</span>
							<br />
							<br />
							We may share your Personal Information for compliance, fraud prevention and safety purposes.
						</p>

						<p className={styles['page-content']}>
							<span>Law enforcement, regulators, and other parties for legal reasons</span>
							<br />
							<br />
							We may share your Personal Information with third parties as required by law or subpoena or
							if we reasonably believe that such action is necessary: (a) to comply with the law and the
							reasonable requests of law enforcement; (b) to enforce our Terms of Use or to protect the
							security or integrity of our Service; and/or (c) to exercise or protect the rights,
							property, or personal safety of Circles, our users, visitors, or others.
						</p>

						<p className={styles['page-content']}>
							<span>Business transfers</span>
							<br />
							<br />
							We may sell, transfer or otherwise share some or all of our business or assets, including
							your Personal Information, in connection with a business transaction (or potential business
							transaction) such as a corporate divestiture, merger, consolidation, acquisition,
							reorganization or sale of assets, or in the event of bankruptcy or dissolution.
						</p>

						<p className={styles['page-content']}>
							<span>With your consent or according to your instructions</span>
							<br />
							<br />
							We may share information in other ways if you give us consent or direct us to do so, such as
							sharing your Personal Information for market research or personalized advertisements.
						</p>

						<h4 className={styles['page-sub-title']}>
							Our third party data collection and online advertising practices
						</h4>
						<p className={styles['page-content']}>
							Circles may participate in interest-based advertising and use third party advertising
							partners to collect information from visitors to the Service for the purpose of displaying
							interest-based advertisements on other websites, apps or services, social networks, or on
							other devices you may use. Typically, though not always, these third-party ad networks use
							cookies and tracking technologies to recognize your browser or device and to collect
							information about your visit to our Service to provide you with customized content,
							advertising, and commercial messages which may be more relevant to your interests, as well
							as to provide advertising-related services such as reporting, attribution, analytics, and
							market research.
						</p>
						<p className={styles['page-content']}>
							Please see the section below to learn how you can opt out of interest-based advertising.
						</p>

						<p className={styles['page-content']}>
							<span>Are There Other Ways My Personal Data Could Be Shared?</span>
							<br />
							<br />
							You may elect to share certain personal information with individuals, entities, or with the
							public via your use of the Service. In this case, you will control such sharing via settings
							that we provide. For example, the Service may make it possible for you to publicly share
							information via Platforms such as Facebook, or Instagram. Be aware that when you choose to
							share information with friends, or with the public at large, you may be disclosing sensitive
							information, or information from which sensitive information can be inferred. Always use
							caution when sharing information through the Service. You understand and agree that we are
							not responsible for any consequences of your sharing of information through and beyond the
							Service.
						</p>

						<h4 className={styles['page-sub-title']}>Your choices</h4>

						<p className={styles['page-content']}>
							In this section, we describe the rights and choices available to all users:
						</p>

						<p className={styles['page-content']}>
							<span>Access or Update Your Information</span>
							<br />
							<br />
							If you have registered for an account with us, you may review certain Personal Information
							in your account profile by logging into your account and navigating to “Profile” in the App.
							To delete your account, you can contact us at
							<a href="mailto:arjav@royalecheese.com">arjav@royalecheese.com</a>
						</p>

						<p className={styles['page-content']}>
							<span>
								Opt-out of marketing communications. You may opt-out of marketing-related emails by
							</span>
							<br />
							<br />
							contacting us at arjav@royalecheese.com. Please note that if you choose to opt-out of
							marketing-related emails, you may continue to receive service-related and other
							non-marketing emails.
						</p>

						<p className={styles['page-content']}>
							<span>Opt in for Data Rewards</span>
							<br />
							<br />
							By default when you sign up on Circles, you will be opted in to sharing certain Personal
							Information for market research or personalized advertisements, you will earn rewards in
							connection with this opt-in (“Data Rewards”).
						</p>
						<p className={styles['page-content']}>
							You may opt out of this by contacting us at arjav@royalecheese.com
						</p>

						<p className={styles['page-content']}>
							Please note that if you are not opted-in to Data Rewards, certain incentives and Services
							affiliated with the Circles Rewards Program may not be available to you.
						</p>
						<p className={styles['page-content']}>
							<span>Deleting Your Information</span>
							<br />
							<br />
							you can contact us directly at
							<a href="mailto:arjav@royalecheese.com">arjav@royalecheese.com</a>
						</p>

						<p className={styles['page-content']}>
							<span>Cookies &amp; Other Tracking Technologies</span>
							<br />
							<br />
							To manage cookies, an individual may have the ability to change their browser settings to
							(for example): (i) notify them when they receive a cookie, so the individual can choose
							whether or not to accept it; (ii) disable existing cookies; or (iii) automatically reject
							cookies (or certain types of cookies, such as cookies set by third parties). Note that
							similar controls may exist for other tracking technologies, such as pixels, SDKs, and
							localStorage. Please check your device or browser settings and related documentation for
							more information.
						</p>
						<p className={styles['page-content']}>
							Further, note that restricting the use of these technologies might negatively impact an
							individual’s experience using our Service, as some features or offerings may be less
							personalized or may not work as otherwise intended. Depending on an individual’s device and
							operating system, the individual may not be able to delete or block all cookies or other
							tracking technologies described in this Privacy Policy. In addition, if an individual wants
							to reject cookies across all browsers and devices, the individual will need to do so on each
							browser and device they use. An individual may also have options within their email client
							to prevent the automatic downloading of images that may contain technologies that would
							allow us to know whether they have accessed our email and performed certain functions with
							it.
						</p>

						<p className={styles['page-content']}>
							<span>Advertising choices.</span> You may be able to limit use of your information for
							interest-based advertising through the following settings/options/tools:
						</p>

						<p className={styles['page-content']}>
							<span>Browser settings.</span> Changing your internet web browser settings to block
							third-party cookies.
						</p>
						<p className={styles['page-content']}>
							<span>Privacy browsers/plug-ins.</span> Using privacy browsers and/or ad-blocking browser
							plug-ins that let you block tracking technologies.
						</p>
						<p className={styles['page-content']}>
							<span>Platform settings.</span> Google and Facebook offer opt-out features that let you
							opt-out of use of your information for interest-based advertising.
						</p>
						<p className={styles['page-content']}>
							We cannot offer any assurances as to whether the companies we work with participate in the
							opt-out programs described above.
						</p>
						<p className={styles['page-content']}>
							<span>Right to Know/Right to Delete.</span> ‍You may submit a request to know, access,
							correct or delete the information we have collected about you by sending a request to
							arjav@royalecheese.com. You can also update your account information or delete your account
							/ data by contacting us through the above email.
						</p>

						<h4 className={styles['page-sub-title']}>
							Our policies on other sites, mobile apps and services
						</h4>
						<p className={styles['page-content']}>
							The Service may contain links to other websites, mobile applications, and other online
							services operated by third parties. These links are not an endorsement of, or representation
							that we are affiliated with, any third party. In addition, our content may be included on
							web pages or in mobile applications or online services that are not associated with us. We
							do not control third party websites, mobile applications or online services, and we are not
							responsible for their actions. Other websites, mobile applications and services follow
							different rules regarding the collection, use and sharing of your Personal Information. We
							encourage you to read the privacy policies of the other websites, mobile applications and
							online services you use.
						</p>

						<h4 className={styles['page-sub-title']}>Children</h4>
						<p className={styles['page-content']}>
							The Service is not directed to, and we do not knowingly collect Personal Information from,
							anyone under the age of 18. If a parent or guardian becomes aware that his or her child has
							provided us with information without their consent, he or she should contact us and we will
							promptly delete such information. We encourage parents with concerns to contact us at
							<a href="mailto:arjav@royalecheese.com">arjav@royalecheese.com</a>
						</p>

						<h4 className={styles['page-sub-title']}>Retention</h4>
						<p className={styles['page-content']}>
							We will usually store the Personal Information we collect about you for no longer than
							necessary to fulfill the purposes for which it was collected, and in accordance with our
							legitimate business interests and applicable law. However, if necessary, we may retain
							Personal Information for longer periods of time, until set retention periods and deadlines
							expire, for instance where we are required to do so in accordance with legal, tax and
							accounting requirements set by a legislature, regulator or other government authority.
						</p>

						<p className={styles['page-content']}>
							To determine the appropriate duration of the retention of Personal Information, we consider
							the amount, nature and sensitivity of the Personal Information, the potential risk of harm
							from unauthorized use or disclosure of Personal Information and if we can attain our
							objectives by other means, as well as our legal, regulatory, tax, accounting and other
							applicable obligations.
						</p>

						<p className={styles['page-content']}>
							Once retention of the Personal Information is no longer necessary for the purposes outlined
							above, we will either delete or deidentify the Personal Information or, if this is not
							possible (for example, because Personal Information has been stored in backup archives),
							then we will securely store the Personal Information and isolate it from further processing
							until deletion or deidentification is possible.
						</p>

						<h4 className={styles['page-sub-title']}>Changes to this Privacy Policy</h4>

						<p className={styles['page-content']}>
							We reserve the right to modify this Privacy Policy at any time. If we make material changes
							to this Privacy Policy, we will notify you including by updating the “Effective date” at the
							top of this Privacy Policy. Any modifications to this Privacy Policy will be effective upon
							our posting the new terms and/or upon implementation of the new changes on the Service (or
							as otherwise indicated at the time of posting).
						</p>

						<h4 className={styles['page-sub-title']}>How to contact us</h4>

						<p className={styles['page-content']}>
							Please direct any questions or comments about this Privacy Policy or privacy practices to
							arjav@royalecheese.com.
						</p>
					</div>
				</section>
			</main>
		</>
	);
}
