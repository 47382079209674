import { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import Animated from '../animated/animated';
import Page from '../page/page';
import styles from './hub.module.css';

interface HubItem {
	title: string;
}
interface Props extends PropsWithChildren {
	title: string;
	index?: number;
	hubItems: HubItem[];
}
export default function Hub({ title, hubItems, children, index }: Props) {
	const hubContainer = useRef<HTMLDivElement | null>(null);
	const [currentIndex, setCurrentIndex] = useState(index ?? 0);

	const scrollToIndex = useCallback(
		(i: number, behavior: ScrollBehavior) => {
			hubContainer.current?.scrollTo({
				left: (hubContainer.current.scrollWidth / hubItems.length) * i,
				behavior,
			});
		},
		[hubItems.length],
	);

	useEffect(() => {
		scrollToIndex(currentIndex, 'auto');
	}, [currentIndex, scrollToIndex]);

	const onScroll = () => {
		// TODO: For webview the index will always remain in float.
		// Calculate the index correctly for web/desktop version
		if (hubContainer.current) {
			const index = (hubContainer.current.scrollLeft / (hubItems.length * screen.width)) * hubItems.length;
			if (Number.isInteger(index) && index !== currentIndex) {
				setCurrentIndex(index);
			}
		}
	};

	const onTitleClick = (i: number) => {
		scrollToIndex(i, 'smooth');
	};

	return (
		<div ref={hubContainer} className={styles.parallax} onScroll={onScroll}>
			<div className={styles.title}>
				<Animated>{title}</Animated>
			</div>

			<div className={styles.container}>
				{hubItems.map((hubItem, i) => {
					return (
						<Page
							key={hubItem.title}
							title={hubItem.title}
							onTitleClick={() => {
								onTitleClick(i);
							}}>
							{children ? children[i] : undefined}
						</Page>
					);
				})}
			</div>
			<div className={styles['dots-container']}>
				{hubItems.map((hubItem, i) => {
					return (
						<div
							key={i}
							onClick={() => {
								onTitleClick(i);
							}}>
							<div className={`${styles.dots} ${i === currentIndex ? styles.active : ''}`}></div>
						</div>
					);
				})}
			</div>
		</div>
	);
}
