import Lottie, { AnimationItem } from 'lottie-web';

interface GestureListener {
	listener: EventListenerOrEventListenerObject;
	animation: { elementSelector: string; lottie: object };
}
const _listeners: EventListenerOrEventListenerObject[] = [];

let currentAnimation: AnimationItem | undefined;

const reset = () => {
	Object.keys(localStorage).forEach(key => {
		if (key.startsWith('hide-coach-mark-')) localStorage.removeItem(key);
	});
};

const hideCoachMark = (key: string) => {
	localStorage.setItem(`hide-coach-mark-${key}`, 'true');
};

const shouldShowCoachMark = (key: string) => {
	const value = localStorage.getItem(`hide-coach-mark-${key}`);
	return value !== 'true';
};

const addGestureListener = ({ listener, animation }: GestureListener) => {
	const element = document.querySelector(animation.elementSelector);
	if (element) {
		_listeners.push(listener);
		window.addEventListener('wheel', listener);
		window.addEventListener('touchmove', listener);
		currentAnimation = Lottie.loadAnimation({
			container: element,
			animationData: animation.lottie,
		});
	}
};

const removeGestureListener = (listener: EventListenerOrEventListenerObject) => {
	_listeners.push(listener);
	if (currentAnimation) {
		currentAnimation.destroy();
	}
	window.addEventListener('wheel', listener);
	window.addEventListener('touchmove', listener);
};

export default {
	reset,
	hideCoachMark,
	addGestureListener,
	shouldShowCoachMark,
	removeGestureListener,
};
