import { useQueryClient } from '@tanstack/react-query';
import { accountKeys } from './keys';
import { sendOtp, UpdateProfileRequest, verifyOtp, VerifyOtpRequest } from './api';
import { Account } from '../../models/account';
import { OnMutateResponse } from '../models/on-mutate-response';

export const useAccountMutations = () => {
	const queryClient = useQueryClient();

	const sendOtpMutation = {
		mutationFn: sendOtp,
	};

	const verifyOtpMutation = {
		mutationFn: verifyOtp,
		onMutate: (variables: VerifyOtpRequest): OnMutateResponse<Account | undefined> => {
			const previousData = queryClient.getQueryData<Account>(accountKeys.all());
			if (previousData) {
				queryClient.setQueryData<Account>(accountKeys.all(), {
					...previousData,
					phoneNumber: variables.phoneNumber,
					countryCode: variables.countryCode,
				});
			}
			return { previousData };
		},
	};

	const updateProfileMutation = {
		mutationKey: accountKeys.update(),
		onMutate: (variables: UpdateProfileRequest): OnMutateResponse<Account | undefined> => {
			const previousData = queryClient.getQueryData<Account>(accountKeys.all());
			if (previousData) {
				queryClient.setQueryData<Account>(accountKeys.all(), {
					...previousData,
					...variables,
				});
			}
			return { previousData };
		},
	};

	const deleteAccountMutation = {
		mutationKey: accountKeys.delete(),
		onMutate: (): OnMutateResponse<Account | undefined> => {
			const previousData = queryClient.getQueryData<Account>(accountKeys.all());
			queryClient.setQueryData(accountKeys.all(), undefined);
			return { previousData };
		},
		onError: (_err, _account, context: OnMutateResponse<Account | undefined>) => {
			queryClient.setQueryData<Account>(accountKeys.all(), context.previousData);
		},
	};

	const logoutMutation = {
		mutationKey: accountKeys.logout(),
		onMutate: (): OnMutateResponse<Account | undefined> => {
			const previousData = queryClient.getQueryData<Account>(accountKeys.all());
			queryClient.setQueryData(accountKeys.all(), undefined);
			return { previousData };
		},
	};

	return {
		deleteAccountMutation,
		updateProfileMutation,
		logoutMutation,
		sendOtpMutation,
		verifyOtpMutation,
	};
};
