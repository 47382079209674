import styles from './page.module.css';

export default function StepList() {
	const url = window.location.href;
	const parts = url.split('/');
	const lastPart = parts[parts.length - 1];
	return (
		<ol>
			<li className={`${styles.list} ${lastPart.startsWith('expense-detail') ? styles['bold-style'] : ''}`}>
				Add Expense Details
			</li>
			<li className={`${styles.list} ${lastPart === 'payee' ? styles['bold-style'] : ''}`}>
				Enter Payee Details
			</li>
			<li className={`${styles.list} ${lastPart === 'participants' ? styles['bold-style'] : ''}`}>
				Select the Participants
			</li>
		</ol>
	);
}
