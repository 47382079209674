import AnimatedParent from '../../../../components/essence/animated/animated-parent';
import Page from '../../../../components/essence/page/page';
import EssenceAnimatedButton from '../../../../components/essence/button/animated-button';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useExpenseQueries } from '../../../../shared/tanstack/expenses/queries';
import { useExpenseMutations } from '../../../../shared/tanstack/expenses/mutations';
import useLoaderDisplay from '../../../../shared/hooks/loader-display';
import { useQuery, useMutation } from '@tanstack/react-query';
import { jwtDecode } from 'jwt-decode';
import accountService from '../../../../shared/services/account-service';
import { Account } from '../../../../shared/models/account';
import { InvalidExpenseIdError } from '../../../../shared/models/errors/invalid-expense-id-error';
import styles from './expenses.module.css';
interface LocationState {
	circleId: string;
}
export default function ExpenseDetail() {
	const { expenseId } = useParams();
	const location = useLocation();
	const { circleId } = location.state as LocationState;
	const { expenseQuery } = useExpenseQueries();
	if (!expenseId) {
		throw new InvalidExpenseIdError();
	}
	const { data: expense, isLoading } = useQuery(expenseQuery(expenseId));
	const navigate = useNavigate();
	const [isCreditor, setIsCreditor] = useState(false);
	const accessToken = accountService.getAccessToken();
	let loggedInUserId: string | undefined;
	if (accessToken) {
		loggedInUserId = jwtDecode<Account>(accessToken).userId;
	}
	const { deleteExpenseMutation } = useExpenseMutations(circleId);
	const { mutate: deleteExpense, isPaused, status, isSuccess } = useMutation(deleteExpenseMutation);

	useLoaderDisplay({
		key: 'getExpense',
		isLoading: isLoading,
	});
	useLoaderDisplay({
		key: 'deleteExpense',
		isLoading: status === 'pending' && !isPaused,
	});

	const onEditExpense = () => {
		if (expense) {
			if (expense.isSettlement && expense.debtors && expense.creditors) {
				navigate(`/secure/circles/${circleId}/add-settlement/payee?token=${new Date().getTime().toString()}`, {
					state: {
						settlementDetail: {
							...expense,
							debtorId: expense.debtors[0].userId,
							creditorId: expense.creditors[0].userId,
						},
					},
				});
			} else {
				navigate(
					`/secure/circles/${circleId}/add-expense/expense-detail?token=${new Date().getTime().toString()}`,
					{
						state: { expenseDetail: expense },
					},
				);
			}
		}
	};

	useEffect(() => {
		if (isSuccess) {
			navigate(-1);
		}
	}, [isSuccess, navigate]);

	const onDeleteExpense = () => {
		deleteExpense({ expenseId, circleId });
	};
	useEffect(() => {
		if (expense) {
			setIsCreditor(expense.createdBy === loggedInUserId);
		}
	}, [expense, loggedInUserId]);

	return (
		<AnimatedParent>
			{expense && (
				<Page title={expense.isSettlement ? 'expense detail' : 'settlement'}>
					{expense.isSettlement && <p className="headline-5">Description</p>}
					{expense.isSettlement && <p className="headline-4">{expense.description}</p>}

					<p className="headline-5  mt-6">Amount</p>
					<p className="headline-4">
						{expense.currency}
						{expense.amount}
					</p>

					<p className="headline-5 mt-6">Date</p>
					<p className="headline-4">
						{expense.dateTime.toString().replace(/T.*/, '').split('-').reverse().join('-')}
					</p>
					<div className="mt-10"></div>

					{expense.isSettlement && (
						<div className="mt-6">
							{expense.creditors?.map(user => (
								<p key={user.userId} className="headline-4">
									{loggedInUserId !== user.userId ? user.name : 'You'} paid {expense.currency}
									{user.amount}{' '}
								</p>
							))}

							{expense.debtors?.map(user =>
								loggedInUserId !== user.userId ? (
									<p key={user.userId} className="headline-4 ms-6">
										- {user.name} owes {expense.currency}
										{user.amount}{' '}
									</p>
								) : (
									<p key={user.userId} className="headline-4 ms-6">
										- You owe {expense.currency}
										{user.amount}{' '}
									</p>
								),
							)}
						</div>
					)}

					{expense.isSettlement && (
						<div className="mt-6">
							{expense.creditors?.map(user => (
								<p key={user.userId} className="headline-4">
									{loggedInUserId !== user.userId ? user.name : 'You'} paid{' '}
									{expense.debtors?.map(user =>
										loggedInUserId !== user.userId ? `${user.name ? user.name : '-'} ` : 'you ',
									)}
									{expense.currency}
									{user.amount}
								</p>
							))}
						</div>
					)}

					<div className="mt-6" />
					{isCreditor && (
						<div className={styles['edit-expense']}>
							<EssenceAnimatedButton name="edit" onClick={onEditExpense}></EssenceAnimatedButton>
							<EssenceAnimatedButton name="delete" onClick={onDeleteExpense}></EssenceAnimatedButton>
						</div>
					)}
				</Page>
			)}
		</AnimatedParent>
	);
}
