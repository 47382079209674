import Clickable from '../core/clickable';
import styles from './switch.module.css';

interface Props extends React.ComponentProps<'input'> {
	disabled?: boolean;
	text?: string;
	dataTestId?: string;
}
export default function EssenceSwitch({ disabled, text, dataTestId, ...props }: Props) {
	return (
		<Clickable disabled={disabled}>
			<label className={`${styles.switch} ${disabled ? styles['switch-disabled'] : ''}`}>
				<input data-testid={dataTestId} disabled={disabled} type="checkbox" {...props} />
				<div className={styles.slider}>
					<span className={styles.inner}></span>
				</div>
				<div className="headline-4">{text}</div>
			</label>
		</Clickable>
	);
}
