import Animated from '../animated/animated';
import EssenceCheckbox, { Props } from './checkbox';

export default function EssenceAnimatedCheckbox(props: Props) {
	return (
		<Animated>
			<EssenceCheckbox {...props} />
		</Animated>
	);
}
