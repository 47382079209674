import Animated from '../../../../components/essence/animated/animated';
import { useNavigate } from 'react-router-dom';

interface Props {
	circleId: string;
}
export default function AddNewExpense({ circleId }: Props) {
	const navigate = useNavigate();

	const goToAddExpenses = () => {
		navigate(`/secure/circles/${circleId}/add-expense/expense-detail?token=${new Date().getTime().toString()}`, {
			state: {},
		});
	};
	return (
		<Animated>
			<div className="headline-4 lowercase" onClick={goToAddExpenses}>
				+ add expense
			</div>
		</Animated>
	);
}
