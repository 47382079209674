import Animated from '../animated/animated';
import EssenceRadio from './radio';

export default function EssenceAnimatedRadio(props) {
	return (
		<Animated>
			<EssenceRadio {...props} />
		</Animated>
	);
}
