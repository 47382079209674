import { Link } from 'react-router-dom';
import EssenceAnimatedRow from '../../../../components/essence/row/animated-row';
import { Circle } from '../../../../shared/models/circle';

interface Props {
	circles: Circle[];
}

export default function CircleList({ circles }: Props) {
	return (
		<>
			{circles.map(circle => {
				return (
					<Link key={circle.circleId} to={`/secure/circles/${circle.circleId}`} data-testid="circle-list">
						<EssenceAnimatedRow title={circle.name} subTitle={`(${circle.interests.join(', ')})`} />
					</Link>
				);
			})}
		</>
	);
}
