import Hub from '../../components/essence/hub/hub';
import AnimatedParent from '../../components/essence/animated/animated-parent';
import swipeLeftAnimation from './../../../scroll-left.json';
import { useState, useEffect } from 'react';
import coachMarkManager from '../../secure/services/coach-mark';
import Settings from './settings/page';
import MyCircles from './my-circles/page';
import { useSearchParams } from 'react-router-dom';

export default function Circles() {
	//TODO: Remove the unused/duplicate code.
	// const navigate = useNavigate();

	const [displayAnimation, setDisplayAnimation] = useState(coachMarkManager.shouldShowCoachMark('landing'));
	const [searchParams] = useSearchParams();
	const pageIndex = searchParams.get('index') ? Number(searchParams.get('index')) : 0;

	const hubItems = [
		{
			title: 'My Circles',
		},
		{
			title: 'Settings',
		},
	];

	// const { logoutMutation } = useAccountMutations();
	// const { mutate: logout } = useMutation(logoutMutation);

	// const handleAddCircle = () => {
	// 	// navigate("/secure/onboarding/circle-type", { replace: true });
	// 	navigate('/secure/onboarding/circle-info');
	// };

	const onSwipe = () => {
		coachMarkManager.hideCoachMark('landing');
		setDisplayAnimation(false);
	};

	useEffect(
		() => () => {
			coachMarkManager.removeGestureListener(onSwipe);
		},
		[],
	);

	useEffect(() => {
		if (displayAnimation) {
			coachMarkManager.addGestureListener({
				listener: onSwipe,
				animation: {
					elementSelector: '#left-swipe',
					lottie: swipeLeftAnimation,
				},
			});
		}
	}, [displayAnimation]);

	return (
		<AnimatedParent>
			<Hub title={'Circles'} hubItems={hubItems} index={pageIndex}>
				<MyCircles />
				<Settings />
			</Hub>
		</AnimatedParent>
	);
}
