import EssenceAnimatedCheckbox from '../../../../../components/essence/checkbox/animated-checkbox';
import styles from '../expense-amount.module.css';
import React, { useState } from 'react';
import { Expense } from '../../../../../shared/models/expense';
import accountService from '../../../../../shared/services/account-service';
import { Account } from '../../../../../shared/models/account';
import { jwtDecode } from 'jwt-decode';
import { ExpenseUser } from '../../../../../shared/models/expense-user';

interface Props {
	circleParticipants: ExpenseUser[];
	expenseDetail: Expense;
}

export default function ParticipantsList({ circleParticipants, expenseDetail }: Props) {
	const [participants, setParticipants] = useState(circleParticipants);
	const [isDirty, setIsDirty] = useState(false);
	const accessToken = accountService.getAccessToken();
	let loggedInUserId: string;
	if (accessToken) {
		loggedInUserId = jwtDecode<Account>(accessToken).userId;
	}

	const onToggle = (userId: string) => {
		let updatedParticipants = participants.map(participant => {
			if (participant.userId === userId) {
				return {
					...participant,
					isChecked: !participant.isChecked,
					amount: 0,
				};
			}
			return participant;
		});

		if (!isDirty) {
			const checkedUsers = updatedParticipants.filter(participant => participant.isChecked);
			const amountToDistribute = parseFloat((expenseDetail.amount / checkedUsers.length).toFixed(2));
			updatedParticipants = updatedParticipants.map(participant => ({
				...participant,
				amount: participant.isChecked ? amountToDistribute : 0,
			}));

			if (checkedUsers.length === participants.length) {
				const debitAmount = updatedParticipants.reduce(
					(sum, participant) => sum + (participant.amount || 0),
					0,
				);
				const remainingAmount = expenseDetail.amount - debitAmount;
				if (remainingAmount !== 0) {
					const randomIndex = Math.floor(Math.random() * circleParticipants.length);
					updatedParticipants = updatedParticipants.map((participant, index) => {
						if (index === randomIndex) {
							return {
								...participant,
								amount: parseFloat((amountToDistribute + remainingAmount).toFixed(2)),
							};
						} else {
							return participant;
						}
					});
				}
			}
		}
		setParticipants(updatedParticipants);
	};

	const onAmountChange = ($event: React.ChangeEvent<HTMLInputElement>, userId: string) => {
		setIsDirty(true);
		const regex = new RegExp(/^[0-9]*\.?[0-9]{0,2}?$/);
		const amount = $event.target.value;

		if (regex.test(amount)) {
			const updatedParticipants = participants.map(participant => {
				if (participant.userId === userId) {
					return { ...participant, amount: parseFloat(amount) };
				}
				return participant;
			});
			setParticipants(updatedParticipants);
		}
	};
	const onBlur = () => {
		const updatedParticipants = participants.map(participant => {
			if (participant.amount) {
				return {
					...participant,
					amount: participant.amount,
				};
			}
			return participant;
		});
		setParticipants(updatedParticipants);
	};

	return (
		<>
			{participants.map(participant => (
				<li
					key={participant.userId}
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}>
					<EssenceAnimatedCheckbox
						title={
							participant.userId === loggedInUserId ? 'You' : participant.name ? participant.name : '-'
						}
						checked={participant.isChecked}
						onChange={() => {
							onToggle(participant.userId);
						}}
						testId={participant.userId}
					/>

					{participant.isChecked && <span className="headline-4 me-3">{expenseDetail.currency}</span>}
					{participant.isChecked && (
						<input
							onBlur={onBlur}
							className={styles.amount}
							essence-input="true"
							value={participant.amount}
							onChange={e => {
								onAmountChange(e, participant.userId);
							}}
							type="tel"></input>
					)}
				</li>
			))}
		</>
	);
}
