import EssenceAnimatedRow from '../../components/essence/row/animated-row';
import { Expense } from '../../shared/models/expense';

interface Props {
	expense: Expense;
	loggedInUserId: string;
}
export default function ExpenseRow({ expense, loggedInUserId }: Props) {
	const _getSubtitle = (expense: Expense) => {
		if (expense.creditors) {
			const paidBy = expense.creditors.map(x => (x.userId === loggedInUserId ? 'You' : x.name)).join(', ');
			return `<span>Date: ${expense.dateTime
				.toString()
				.replace(/T.*/, '')
				.split('-')
				.reverse()
				.join('-')}  <br>Paid By: ${paidBy}</span>`;
		}
	};

	const _getSettlementSubtitle = (expense: Expense) => {
		return `Date: ${expense.dateTime.toString().replace(/T.*/, '').split('-').reverse().join('-')}`;
	};

	const _getSettlementDescription = (expense: Expense) => {
		if (expense.creditors && expense.debtors) {
			const paidBy = expense.creditors.map(x => (x.userId === loggedInUserId ? 'You' : x.name)).join(', ');
			const paidTo = expense.debtors.map(x => (x.userId === loggedInUserId ? 'you' : x.name)).join(', ');
			return `Settlement: ${paidBy} paid ${paidTo}`;
		}
	};
	return (
		<>
			{!expense.isSettlement && (
				<EssenceAnimatedRow
					title={expense.description}
					subTitle={_getSubtitle(expense)}
					actionText={`${expense.currency.toString()}${expense.amount.toString()}`}
				/>
			)}
			{expense.isSettlement && (
				<EssenceAnimatedRow
					title={_getSettlementDescription(expense)}
					subTitle={_getSettlementSubtitle(expense)}
					actionText={`${expense.currency}${expense.amount.toString()}`}
				/>
				// <div className="headline-4 my-8">{_getSettlementDescription(expense)}</div>
			)}
		</>
	);
}
