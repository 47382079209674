import { useEffect, useState } from 'react';
import Header from '../../shared/components/header/page';
import Content from '../../shared/components/content/page';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAccountQueries } from '../../shared/tanstack/account/queries';
import useLoaderDisplay from '../../shared/hooks/loader-display';
import EssenceAlert, { AlertInfo } from '../../components/essence/alert/alert';
import { useAccountMutations } from '../../shared/tanstack/account/mutations';
import { useQuery } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { onlineManager } from '@tanstack/react-query';
import { useCircleQueries } from '../../shared/tanstack/circle/queries';
import accountService from '../../shared/services/account-service';
import { useQueryClient } from '@tanstack/react-query';
import coachMarkManager from '../../secure/services/coach-mark';
import { UpdateProfileRequest } from '../../shared/tanstack/account/api';
import { formattedPhoneNumber } from '../../shared/services/format-service';
import SaveProfileForm from './components/save-profile-form/page';
import DeleteAccount from './components/delete-account/page';

interface StateType {
	context: string;
	countryCode: number;
	phoneNumber: string;
}

export default function Profile() {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const location = useLocation();
	const { accountQuery } = useAccountQueries();
	const { updateProfileMutation, deleteAccountMutation } = useAccountMutations();
	const [skipCirclesQuery, setSkipCirclesQuery] = useState(true);
	const { mutate: updateProfile, isPaused, status, isSuccess, isError } = useMutation(updateProfileMutation);

	const {
		mutate: deleteAccount,
		isSuccess: deleteAccountSuccess,
		isError: deleteAccountError,
		isPaused: isDeleteAccountPaused,
		status: deleteAccountStatus,
		reset: deleteAccountReset,
	} = useMutation(deleteAccountMutation);

	useLoaderDisplay({
		key: 'updateProfile',
		isLoading: status === 'pending' && !isPaused,
	});

	useLoaderDisplay({
		key: 'deleteAccount',
		isLoading: deleteAccountStatus === 'pending' && !isDeleteAccountPaused,
	});

	const { data: account } = useQuery(accountQuery);

	const [alert, setAlert] = useState<AlertInfo>({
		show: false,
		message: 'Please try again later.',
	});

	const { circlesQuery } = useCircleQueries();
	const { data: circles, isLoading: isCirclesLoading } = useQuery(circlesQuery(skipCirclesQuery));
	useLoaderDisplay({ key: 'getCircles', isLoading: isCirclesLoading });

	const state = location.state as StateType;

	useEffect(() => {
		if (!onlineManager.isOnline() && account === undefined) {
			setAlert({
				show: true,
				message: 'Please connect to the internet to get details of your account.',
			});
		}
	}, [account]);

	useEffect(() => {
		if (deleteAccountSuccess) {
			accountService.removeToken();
			queryClient.removeQueries();
			coachMarkManager.reset();
			navigate('/auth/login', { replace: true });
		}
	}, [deleteAccountSuccess, navigate, queryClient]);

	useEffect(() => {
		if (deleteAccountError) {
			setAlert({
				show: true,
				message: 'There was some error deleting your account. Please try again later.',
			});
			deleteAccountReset();
		}
	}, [deleteAccountError, deleteAccountReset]);

	useEffect(() => {
		if (isSuccess) {
			if (state.context === 'UPDATE_PROFILE') {
				navigate(-1);
			} else {
				accountService.setProfileCreated();
				setSkipCirclesQuery(false);
			}
		}
	}, [isSuccess, state.context, navigate]);

	useEffect(() => {
		if (isError) {
			setAlert({
				show: true,
				message: 'There was some error updating the profile. Please try again later.',
			});
		}
	}, [isError]);

	useEffect(() => {
		if (!skipCirclesQuery && state.context !== 'UPDATE_PROFILE') {
			if (circles) {
				navigate(circles.length > 0 ? '/secure/circles' : '/secure/onboarding/circle-info', {
					replace: true,
				});
			}
		}
	}, [circles, state.context, skipCirclesQuery, navigate]);

	const hideAlert = () => {
		setAlert({
			show: false,
			message: alert.message,
		});
		if (!onlineManager.isOnline()) {
			navigate(-1);
		}
	};
	const saveProfile = (name: string) => {
		const updatedAccount: UpdateProfileRequest = {
			name: name.trim(),
			profileImage: 'sample',
		};
		updateProfile(updatedAccount);
	};

	return (
		<>
			{alert.show && (
				<EssenceAlert
					onOk={hideAlert}
					onDismiss={hideAlert}
					okButton="okay"
					message={alert.message}></EssenceAlert>
			)}

			<Header title="Profile" subtitle="visible to others in your circles" />
			<Content>
				<>
					<p className="headline-5">Phone Number</p>
					<p className="headline-4">
						{account?.phoneNumber
							? formattedPhoneNumber(account.phoneNumber, account.countryCode)
							: formattedPhoneNumber(state.phoneNumber, state.countryCode)}
					</p>
					<SaveProfileForm
						name={account?.name}
						onSave={saveProfile}
						buttonText={state.context === 'UPDATE_PROFILE' ? 'save' : 'next'}
					/>
					{state.context === 'UPDATE_PROFILE' && (
						<DeleteAccount
							onDelete={() => {
								deleteAccount(undefined);
							}}
						/>
					)}
				</>
			</Content>
		</>
	);
}
