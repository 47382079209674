import styles from './page.module.css';

export default function Terms() {
	return (
		<>
			<main className="bg-3 headline-5 m-16">
				<section>
					<h1 className="text-center page-title">Terms of Service</h1>
					<div className="container">
						<h4 className={styles['page-sub-title']}>Effective Date: May 7th, 2024</h4>
						<h4 className={styles['page-sub-title']}>Last Updated: May 7th, 2024</h4>
						<p className={styles['page-content']}>
							Thank you for your interest in Play Cent Technologies Private Limited (“Circles,” “we,”
							“us,” or “our”), our website located at https://circles.royalecheese.com (the “Site”), our
							mobile applications (the “Mobile Apps”), and collectively with the Mobile Apps, the “App” or
							“Application”) and any associated websites and subdomains, applications, and services
							(collectively, the “Service”). This Terms of Service Agreement (“Terms”) is a legally
							binding contract between you and Circles.
						</p>
						<p className={styles['page-content']}>
							By registering for an account, downloading or installing the App, or otherwise accessing or
							using the Service, or any portion thereof, you acknowledge and agree that you have read,
							understand, and agree to be bound by these Terms.
						</p>
						<p className={styles['page-content']}>
							You represent and warrant that you have the right, authority, and capacity to enter into
							these Terms. If the individual entering into this agreement or otherwise accessing or using
							the Service is doing so on behalf of, or within his or her capacity as a representative,
							agent, or employee of an entity, such individual and such entity agree that: (i) the terms
							“you” and “your” as used herein apply to such entity and such individual; and (ii) represent
							and warrant that the individual entering into these Terms has the power, right, authority,
							and capacity to enter into these Terms on behalf of such entity. If you do not agree with
							all of the provisions of these Terms, you may not access or use the Service.
						</p>
					</div>
				</section>
				<section>
					<div className="container">
						<p className={styles['page-content']}>
							These Terms are subject to occasional revision. Please regularly check the Site to view the
							most recent terms.
						</p>
						<p className={styles['page-content']}>
							<span>Eligibility</span>
							This is a contract between you and Circles. You must read and agree to these Terms before
							using the Service. If you do not agree, you may not use the Service. You may use the Service
							only if you can form a binding contract with Circles, and only in compliance with these
							Terms and all applicable local, state, national, and international laws, rules and
							regulations.
						</p>
						<p className={styles['page-content']}>
							The Service is not available to any users previously removed from the Service by Pogo. The
							Service is only available to residents of India and is only intended for use in India. Any
							access to, or use of, the Service by non-Indian residents is strictly prohibited and in
							violation of these Terms. Circles makes no representations that the Service is appropriate
							or available for use in other locations. By using the Service, you represent that you are an
							Indian resident.
						</p>
						<p className={styles['page-content']}>
							Any use or access to the Service by anyone under 18 is strictly prohibited and in violation
							of these Terms.
						</p>
						<h4 className={styles['page-sub-title']}>1. Accounts</h4>
						<p className={styles['page-content']}>
							<span>1.1. Account Creation.</span> In order to use most features of the Service, you must
							register for an account (“Account”). You promise that: (a) all required registration
							information you submit in connection with your registration is truthful and accurate; and
							(b) you will maintain the accuracy of such information.
						</p>
						<p className={styles['page-content']}>
							<span>1.2. Account Responsibilities.</span> You are responsible for all activities that
							occur under your Account. You agree to immediately notify Circles of any unauthorized use or
							suspected unauthorized use of your Account or any other breach of security. You may not
							share your Account with anyone. You agree that you shall not have more than one Account at
							any given time. You agree not to create an Account or use the Service if you have been
							previously removed by Circles. If you provide any information that is untrue, inaccurate,
							not current or incomplete, or Circles has reasonable grounds to suspect that any information
							you provide is untrue, inaccurate, not current or incomplete, Circles has the right to
							suspend or terminate your Account and refuse any and all current or future use of the
							Service (or any portion thereof). You agree not to create an Account using a false identity
							or information, or on behalf of someone other than yourself.
						</p>
						<p className={styles['page-content']}>
							<span>1.3. Your Account.</span> You agree that you shall have no ownership or other property
							interest in your Account, and you further acknowledge and agree that all rights in and to
							your Account are and shall forever be owned by Circles.
						</p>
						<p className={styles['page-content']}>
							<span>1.4 Data connections; Sharing.‍</span>
							<br />
							<span>(a) Fetching data.</span> In order to store your User Data in Circles, you must
							connect a third-party platform (e.g., your Amazon account, your Google account, your
							Flipkart account, etc.) (each a “Connection”) to Circles. Circles will provide the
							opportunity for you to login to the Connection via the Mobile App creating an authorization
							token that is stored with Circles on your behalf, which provides your account with ongoing
							access to the Connection and the embedded data therein (the process, “Making/Made a
							Connection”). After Making a Connection, you will have access to Insights and Rewards. You
							may disconnect a Connection at any time and no new data will be fetched until you reconnect.
						</p>
						<p className={styles['page-content']}>
							<span>(b) Sharing with Circles &amp; Participating Businesses.</span>
							Once you have made a Connection to Circles, with your consent, Your user Data is shared with
							Circles &amp; its participating businesses, including our affiliates and subsidiaries, our
							service providers or advisors, marketing/advertising and strategic partners, analytics
							providers; social networks; our clients, partnered brands, retailers and agencies
							(collectively, &quot;Participating Businesses&quot;). By Sharing your Connections and User
							Data with Circles, you will gain access to the other Circles Services, Insights and Rewards
							(see below for more information).
						</p>
						<p className={styles['page-content']}>
							<span>1.5. Referrals.</span> We may offer bonus Points (as defined below) when someone you
							refer becomes a user of the Service (a “Referral Program”). Any such Referral Program may be
							subject to additional terms and conditions set forth in the offer or App, at Circles’s sole
							discretion.
						</p>
						<p className={styles['page-content']}>
							You agree that you have obtained all necessary permissions and consents from an individual
							before referring that individual to Circles and will inform the individual: (a) that we are
							not the sender or initiator of any messages or communications that you send to that
							individual relating to the Referral Program; (b) that you are not acting as our agent in
							sending any such messages or communications; and (c) that you may be receiving a referral
							bonus if the individual becomes a new user of Circles. We do not directly send any referral
							codes to individuals.
						</p>
						<p className={styles['page-content']}>
							Current or inactive former users of the Service cannot qualify as new users for purposes of
							a Referral Program, and any attempt to create referrals to non-existent individuals or to
							multiple accounts for the same individual will be void and are in violation of these Terms.
							We reserve the right to void or take any other action we deem appropriate with respect to
							referrals, including if a referral code is shared in a social media post or from a social
							media account that contains offensive, harassing, violent, pornographic, or otherwise
							inappropriate content. Circles may discontinue and/or revoke any Referral Program or
							incentive program in its sole discretion at any time without notice. Circles reserves the
							right to decline to award referral bonuses, rescind bonus Rewards credited, or take any
							other action related to a Referral Program.
						</p>
						<h4 className={styles['page-sub-title']}>2. Rewards Program</h4>
						<p className={styles['page-content']}>
							<span>2.1 Circles Rewards Program. </span>
							<br />
							The Circles Rewards Program allows you to earn points (”Rewards”) for sharing your data for
							market research and personalized advertisements (collectively, “Data Rewards”). Circles
							reserves the right to limit the Service and incentives associated with the Circles Rewards
							Program.
						</p>
						<p className={styles['page-content']}>
							For more information on Data Rewards, and for information around how we process your
							information and data in connection with any Rewards, please refer to our Privacy Policy.
						</p>
						<p className={styles['page-content']}>
							In connection with a Circles Account, Circles offers Users the ability to earn Points (as
							defined below) for certain actions, activities, or transactions related to Making
							Connections and Sharing access to the data with Circles or Partners or Participating
							businesses (collectively, the “Rewards Program”). The Rewards Program is offered at no
							additional charge to Users. The Rewards Program begins upon the opening of the User’s
							Circles Account. POINTS ARE NOT LEGAL TENDER AND HAVE NO CASH VALUE OUTSIDE OF THE REWARDS
							PROGRAM. YOU HAVE NO LEGAL INTEREST OR PROPERTY OR OTHER RIGHTS TO POINTS, AND THEY CANNOTBE
							BOUGHT, SOLD OR TRANSFERRED IN ANY WAY, TO ANY OTHER USER (INCLUDING UPONDEATH OR AS PART OF
							A DOMESTIC RELATIONS MATTER). IF (I) YOU TERMINATE OR DELETE YOUR Circles ACCOUNT, (II) YOUR
							Circles ACCOUNT FALLS OUT OF GOOD STANDING,OR (III) YOUR Circles ACCOUNT IS INACTIVE FOR 12
							CONSECUTIVE MONTHS, YOU WILLFORFEIT ANY POINTS IN YOUR POINTS BALANCE. All Users with a
							Circles Account in “Good Standing” (meaning it is not frozen or suspended) are eligible for
							the Rewards Program. By agreeing to the Terms, you hereby authorize Circles to record and
							analyze your actions related to Making Connections and Sharing in order to calculate an
							accrual of Points. Circles reserves the right to disqualify any User from participation in
							the Rewards Program, suspend your participation in the Rewards Program and/or freeze any
							accrual or redemption of Points if we suspect fraudulent activity in your Connections,
							Circles Account or any User behavior that violates the Terms.
						</p>
						<p className={styles['page-content']}>
							Circles will award “Points” to Users through the Rewards Program based on certain actions,
							activities, or transactions related to your Circles Account. Points are accrued, and your
							Point balance (“Point Balance”) is displayed in your Circles Account on the Mobile App. At
							this time, as long as your Circles Account remains open and in Good Standing, Points will
							accrue in the manner described. Points may be rescinded if Circles determines, in its sole
							discretion, that the Points were awarded to you in error, corresponding actions have been
							rescinded, or if there is fraudulent activity in your Data Vault, Connections, or Circles
							Account. If you believe that you are eligible for Points that were rescinded or that you
							have not received, or that you received Points that were calculated erroneously, please
							contact us.
						</p>
						<p className={styles['page-content']}>
							<span>2.2 Earning Points.</span> By completing certain actions or performing activities,
							Users may receive Points. The Rewards Program core offering provides Points to Users based
							on Sharing Connections with Circles or Partners. The specific number of Points or rate of
							accrual for Points will be displayed in the Mobile App, and may change based on your Data
							fetched from connection, or in our discretion. Prior to Sharing with Circles or Partners,
							you will be informed how such actions will impact your accrual of Points, and will be given
							the option to accept or decline. Points, if available, may be accrued in the following ways:
						</p>
						<p className={styles['page-content']}>
							<span>a) Data Rewards :</span> By default when you sign up on Circles, you will be opted in
							to sharing certain Personal Information for market research or personalized advertisements,
							you will earn rewards in connection with this opt-in (“Data Rewards”). Terms and conditions
							set forth on the Site and the App and may be updated, modified, suspended, or canceled by
							Circles at any time in its sole discretion.
						</p>
						<p className={styles['page-content']}>
							<span>b) Data Connections &amp; Initial Sharing Bonus:</span> Each time you Share a new
							Connection with Circles, you may be offered a one-time reward of Points (“Bonus Points”).
							Bonus Points is a reward for Sharing your historical data. You may also earn Reward points
							for digital data fetched from external accounts, such as Flipkart, Amazon or Google.
						</p>
						<p className={styles['page-content']}>
							<span>c) Linked Retailer Reward points:</span> You can earn additional Reward points for
							purchases with retailer or digital consumer account(s) you choose to link to your Circles
							account, such as your Amazon, Google or Flipkart account (collectively, “Linked Retailers”),
							where you receive your receipts (”Linked Retailer Reward points”).
						</p>
						<p className={styles['page-content']}>
							Accordingly, you authorize Circles as your agent to access your Linked Retailers to pull
							receipts of your purchases. You represent and agree that for all Linked Retailers, you are
							an authorized user of that account and that you have the authority to provide Circles access
							to that account as your agent. Circles stores those log-in credentials on your device and
							not on our systems. Circles is not responsible for the security of your mobile device. Terms
							and conditions set forth on the Site and the App and may be updated, modified, suspended, or
							canceled by Circles at any time in its sole discretion.
						</p>
						<p className={styles['page-content']}>
							<span>d) Linked Email Reward points:</span> You can earn additional Reward points for email
							accounts you choose to link to your Circles account (”Linked Emails”) where you receive your
							receipts (”Linked Email Reward points”).
						</p>
						<p className={styles['page-content']}>
							Accordingly, you authorize Circles as your agent to access your Linked Emails to pull
							receipts of your purchases. You represent and agree that for all Linked Emails, you are an
							authorized user of that account and that you have the authority to provide Circles access to
							that account as your agent. You understand that while linked to Circles, Circles is
							automatically able to access your Linked Emails to retrieve all email receipts. Circles
							stores those log-in credentials on your device and not on our systems. Circles is not
							responsible for the security of your mobile device.
						</p>
						<p className={styles['page-content']}>
							<span>2.3 Redeeming Points.</span> Subject to these Terms and our approval, When your
							account is atleast 30 days old and, when your Points balance reaches the Minimum Redemption
							Amount, (&quot;Minimum Redemption Amount&quot; shall mean the minimum value of Points
							required to redeem any Reward offered in the Services (&quot;Reward&quot;), which is subject
							to change at any time at the sole discretion of Circles), you may redeem your Points. Points
							can only be used in exchange for Rewards and have no other use or value. There is no
							automatic redemption of Points. No interest is accrued on Points. Points may be redeemed for
							Rewards at the rate specified in the Services at the time of redemption. Circles does not
							guarantee that any particular Reward or Offer will be available on any given product for any
							specific user at any moment in time. Furthermore, not all Rewards or Offers may be available
							to all users at all times, and different Rewards or Offers may be available only to certain
							users. Items for which Rewards are or will be available are subject to change without
							notice. Circles reserves the right to change, remove, discontinue or otherwise alter the
							available Rewards or Offers at any time.
						</p>
						<p className={styles['page-content']}>
							When you make a request to redeem Points for a Reward, you must elect within the App the
							Reward, which could be a gift card or other product offered as a Reward, you want to receive
							in exchange for your Points. Once a redemption request is approved and the Redemption Item
							is authorized by us, your Points balance will be reduced by the number of Points designated
							to the Redemption Item selected by you. All Redemption Items are non-refundable, and may not
							be returned or cancelled unless specifically offered to cancel in-app. We are not
							responsible or liable for any loss or delay of any Redemption Item once you have redeemed
							your Points. If you have any questions or concerns with the redemption process, you may
							contact us at arjav@royalecheese.com
						</p>
						<p className={styles['page-content']}>
							<span>2.4 Expiration of Points.</span> Your unredeemed Points will expire and will no longer
							be redeemable after one hundred eighty (180) days of inactivity, which is defined as
							consecutive days where you do not do either of the following: a) perform any Point earning
							action; or b) redeem any Points.
						</p>
						<p className={styles['page-content']}>
							Prior to your Points expiration date, you may prevent your then unredeemed Points from
							expiring by doing either of the following: i) performing a Point earning action; or ii)
							redeeming your Points. Expired Points cannot be recovered.
						</p>
						<h4 className={styles['page-content']}>3. Access to the Service</h4>
						<p className={styles['page-content']}>
							<span>3.1 Access and Use.</span> Subject to these Terms, Circles grants you a non-exclusive,
							revocable, limited, non-transferable, non-assignable, and “as is” right to use and access
							the features and functionality of the Service solely for your own personal, non-commercial
							use, subject to any use limitations.
						</p>
						<p className={styles['page-content']}>
							<span>3.2 Application License.</span> Subject to your compliance with these Terms, including
							without limitation Section 5.2 and Section 5.3 below, Circles grants you a limited
							non-exclusive, non-transferable, non-sublicensable, revocable license to download, install
							and use a copy of the App on each mobile device or computer that you own or control and to
							run such copy of the App solely for your own personal, non-commercial purposes.
						</p>
						<p className={styles['page-content']}>
							<span>3.3 Modification.</span> We may modify, update, suspend, or discontinue the Service
							(in whole or in part) at any time with or without notice to you. You agree that Circles is
							not liable to you or to any third party for any modification, update, suspension, or
							discontinuation of the Service. You may need to update third-party software from time to
							time in order to use the Service.
						</p>
						<p className={styles['page-content']}>
							<span>3.4 Location Data.</span> Some features of the Service enable us to tailor your
							experience in the Service based on your location. If you decline to provide location
							information, or if, in our judgment, we cannot verify your location, you will be unable to
							utilize some features of the Service.
						</p>
						<p className={styles['page-content']}>
							We may use the Google Maps API to gather information about your location. The use of the
							Google Maps features and content is subject to the Google Maps/Google Earth Additional Terms
							of Service available at https://maps.google.com/help/terms_maps.html and the Google Privacy
							Policy available at https://www.google.com/policies/privacy/.
						</p>
						<h4 className={styles['page-sub-title']}>4. IP Rights</h4>
						<p className={styles['page-content']}>
							<span>4.1 Ownership.</span> You acknowledge that we or our suppliers own all right, title,
							and interest, including all intellectual property rights, in and to the Service. Except for
							the limited access rights expressly set forth in these Terms, you are not granted any rights
							with respect to the Service and there are no implied licenses granted by Circles under these
							Terms.
						</p>
						<p className={styles['page-content']}>
							<span>4.2 Trademarks.</span> Circles and all related graphics, logos, service marks and
							trade names used on or in connection with the Service or in connection with the Service are
							the trademarks of Circles and may not be used without permission in connection with your or
							any third-party products or services. Other trademarks, service marks and trade names that
							may appear on or in the Service are the property of their respective owners.
						</p>
						<p className={styles['page-content']}>
							<span>4.3 Feedback.</span> We welcome feedback to help us build a better service. If you
							provide Circles with any feedback or suggestions regarding the Service (“Feedback”), you
							acknowledge that we can freely use and exploit such Feedback in any manner. Feedback you
							provide is not confidential or proprietary to you. So, please do not provide Circles any
							information or ideas that you consider to be confidential or proprietary.
						</p>
						<h4 className={styles['page-sub-title']}>5. Third-Party Services</h4>
						<p className={styles['page-content']}>
							<span>5.1 Third-Party Materials.</span> As a part of the Service, you may have access to
							materials, links, applications, advertisements, and services that are hosted or controlled
							by another party (collectively, “Third-Party Materials”). You agree that it is impossible
							for Circles to monitor such Third-Party Materials, and that you access these Third-Party
							Materials and services at your own risk. Circles provides access to these Third-Party
							Materials only as a convenience to you, and does not review, approve, monitor, endorse,
							warrant, or make any representations with respect to Third-Party Materials. You use all
							Third-Party Materials at your own risk and should apply a suitable level of caution and
							discretion in doing so. When you click on any of the Third-Party Materials, the applicable
							third party’s terms and policies apply, including the third party’s privacy practices. You
							should make whatever investigation you feel necessary or appropriate before proceeding with
							any transaction in connection with such Third-Party Materials.
						</p>
						<p className={styles['page-content']}>
							You acknowledge and agree that Circles may get commissions when you access Third-Party
							Materials through the App.
						</p>
						<p className={styles['page-content']}>
							<span>5.2 Accessing and Downloading the Application from Apple App Store.</span>
							The following applies to any App accessed through or downloaded from the Apple App Store
							(”Apple-Sourced App”):
						</p>
						<p className={styles['page-content']}>
							<span>(a)</span> You acknowledge and agree that (i) these Terms are between you and Circles
							only, and not Apple, Inc. (”Apple”) and (ii) Circles, not Apple, is solely responsible for
							the Apple-Sourced App and content thereof. Your use of the Apple-Sourced App must comply
							with the App Store Terms of Service.
						</p>
						<p className={styles['page-content']}>
							<span>(b) </span> You acknowledge that Apple has no obligation whatsoever to furnish any
							maintenance and support services with respect to the Apple-Sourced App.
						</p>
						<p className={styles['page-content']}>
							<span>(c)</span> In the event of any failure of the Apple-Sourced App to conform to any
							applicable warranty, you may notify Apple, and Apple will refund the purchase price for the
							Apple-Sourced App to you and to the maximum extent permitted by applicable law, Apple will
							have no other warranty obligation whatsoever with respect to the Apple-Sourced App. Any
							other claims, losses, liabilities, damages, costs or expenses attributable to any failure to
							conform to any warranty will be the sole responsibility of Circles.
						</p>
						<p className={styles['page-content']}>
							<span>(d)</span> You and Circles acknowledge that Circles, not Apple, is responsible for
							addressing any claims you have or any claims of any third party relating to the
							Apple-Sourced App or your possession and use of the Apple-Sourced App, including, but not
							limited to: (i) product liability claims; (ii) any claim that the Apple-Sourced App fails to
							conform to any applicable legal or regulatory requirement; and (iii) claims arising under
							consumer protection or similar legislation.
						</p>
						<p className={styles['page-content']}>
							<span>(e)</span> You and Circles acknowledge that, in the event of any third-party claim
							that the Apple-Sourced App or your possession and use of that Apple-Sourced App infringes
							that third party’s intellectual property rights, Circles, not Apple, will be solely
							responsible for the investigation, defense, settlement and discharge of any such
							intellectual property infringement claim to the extent required by these Terms.
						</p>
						<p className={styles['page-content']}>
							<span>(f) </span> You and Circles acknowledge and agree that Apple, and Apple’s
							subsidiaries, are third-party beneficiaries of these Terms as related to your license of the
							Apple-Sourced App, and that, upon your acceptance of the terms and conditions of these
							Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce
							these Terms as related to your license of the Apple-Sourced App against you as a third-party
							beneficiary thereof.
						</p>
						<p className={styles['page-content']}>
							<span>(g)</span> Without limiting any other terms of these Terms, you must comply with all
							applicable third-party terms of agreement when using the Apple-Sourced App.
						</p>
						<p className={styles['page-content']}>
							<span>
								5.3 Accessing and Downloading the Application from the Google Play Store or Google
								Chrome Web Store.
							</span>
							The following applies to any App you acquire from the Google Play Store or Google Chrome Web
							Store (“Google-Sourced App”): (i) you acknowledge that these Terms are between you and
							Circles only, and not with Google or its affiliates (collectively, “Google”); (ii) your use
							of the Google-Sourced App must comply with Google’s then-current Google Play Store Terms of
							Service or Google Chrome Web Store Terms of Service, as applicable; (iii) Google is only a
							provider of the Google Play Store and Google Chrome Web Store; (iv) Circles, and not Google,
							is solely responsible for the Google-Sourced App; (v) Google has no obligation or liability
							to you with respect to the Google-Sourced App or these Terms; and (vi) you acknowledge and
							agree that Google is a third-party beneficiary to these Terms as it relates to the
							Google-Sourced App.
						</p>
						<h4 className={styles['page-sub-title']}>6. Content</h4>
						<p className={styles['page-content']}>
							You acknowledge that all information, data, text, photographs, messages, tags, and other
							content or materials accessible through the Service (“Content”), are the sole responsibility
							of the party from whom such Content originated. This means that you, and not Circles, are
							entirely responsible for all Content that you upload or otherwise make available through the
							Service (“Your Content”). By submitting Your Content, you grant Circles a fully-paid,
							royalty-free, worldwide, non-exclusive right (including any moral rights) and license to
							use, sublicense, distribute, reproduce, modify, adapt, publicly perform, and display, Your
							Content (in whole or in part) and/or to incorporate it in other works in any form, media or
							technology now known or later developed, for the full term of any worldwide intellectual
							property right, including moral rights, that may exist in Your Content. Except with respect
							to Your Content, you agree that you have no right, title, or interest in or to any Content
							that appears on or in the Service.
						</p>
						<p className={styles['page-content']}>
							<span>6.1 Investigations.</span> Circles may, but is not obligated to, monitor or review the
							use of the Service and Content at any time. Without limiting the foregoing, Circles shall
							have the right, in its sole discretion, to remove any of Your Content for any reason (or no
							reason). If Circles becomes aware of any possible violations by you of any provision of
							these Terms, Circles may investigate such violations, and, at its sole discretion,
							immediately terminate your license to use the Service, or change, alter or remove Your
							Content, in whole or in part, without prior notice to you.
						</p>
						<p className={styles['page-content']}>
							<span>6.2 No Obligation to Pre-Screen Content.</span> Circles may, but is not obligated to
							pre-screen, refuse or remove any Content for any reason, including if Content violates these
							Terms or is otherwise objectionable. Circles has no responsibility or liability for the
							deletion or accuracy of any Content. Certain Services may enable you to specify the level at
							which such Service restricts access to Your Content. You are solely responsible for applying
							the appropriate level of access to Your Content. Unless expressly agreed to by Circles in
							writing elsewhere, Circles has no obligation to store any of Your Content.
						</p>
						<p className={styles['page-content']}>
							<span>6.3 Representations and Warranties.</span> You represent and warrant that you have all
							necessary right, title, interest, authorizations, and permissions to: (i) provide, provide
							access to, request Circles access, disclose, or submit any data, content, information, or
							feedback, including Your Content, that you provide, provide access to, disclose, or submit
							to Circles or the Service, or that you authorize or request Circles to access on your
							behalf, as applicable; and (ii) grant the rights and permissions granted hereunder with
							respect to any data, content, information, or feedback, including Your Content.
						</p>
						<h4 className={styles['page-sub-title']}>7. User Conduct</h4>
						<p className={styles['page-content']}>
							As a condition of your use of the Service, you agree not to use the Service for any purpose
							that is prohibited by these Terms or by applicable law. You shall not (and shall not permit
							any third party) to:
						</p>
						<p className={styles['page-content']}>
							<span>(a)</span> submit to our Service information that is false, inaccurate, tampered with,
							adjusted, or otherwise manipulated for the purpose of redeeming a Reward;‍
						</p>
						<p className={styles['page-content']}>
							<span>(b)</span> link your Circles Account to any bank account, credit/debit card, email
							account, retailer/digital consumer account or health tracker/app that is not owned by you;
						</p>
						<p className={styles['page-content']}>
							<span>(c)</span> redeem, or attempt to redeem, Rewards for products that have not actually
							been purchased and retained, or for products that have been purchased but returned to a
							store or online vendor, following purchase;
						</p>
						<p className={styles['page-content']}>
							<span>(d)</span> infringe any patent, trademark, trade secret, copyright, right of publicity
							or other right of any person or entity;
						</p>
						<p className={styles['page-content']}>
							<span>(e)</span> take any action that is unlawful, threatening, abusive, harassing,
							defamatory, libelous, deceptive, fraudulent, invasive of another’s privacy, tortious,
							obscene, offensive, or profane;
						</p>
						<p className={styles['page-content']}>
							<span>(f)</span> take any action that constitutes unauthorized or unsolicited advertising,
							junk or bulk e-mail;
						</p>
						<p className={styles['page-content']}>
							<span>(g)</span> take any action that involves commercial activities and/or sales without
							Circles’s prior written consent, such as contests, sweepstakes, barter, advertising, or
							pyramid schemes;
						</p>
						<p className={styles['page-content']}>
							<span>(h)</span> impersonate any person or entity, including any employee or representative
							of Circles;
						</p>
						<p className={styles['page-content']}>
							<span>(i)</span> interfere with or attempt to interfere with the proper functioning of the
							Service or uses of the Service in any way not expressly permitted by these Terms; or
						</p>
						<p className={styles['page-content']}>
							<span>(j)</span> engage in, or attempt to engage in any potentially harmful acts that are
							directed against the Service, including but not limited to violating or attempting to
							violate any security features of the Service, using manual or automated software or other
							means to access, “scrape,” “crawl” or “spider” any pages contained in the Service,
							introducing viruses, worms, or similar harmful code into the Service, or interfering or
							attempting to interfere with use of the Service by any other user, host or network,
							including by means of overloading, “flooding,” “spamming,” “mail bombing,” or “crashing” the
							Service.
						</p>
						<h4 className={styles['page-sub-title']}>8. Indemnification</h4>
						<p className={styles['page-content']}>
							You agree to indemnify and hold Circles, its officers, employees, agents, partners,
							suppliers (including but not limited to the Payment Card Networks) and licensors (each, a
							“Circles Party” and collectively, the “Circles Parties”) harmless, including costs and
							attorneys’ fees, from any loss, claim, or demand arising out of: (i) your use of the
							Service; (ii) your violation of these Terms; (iii) your violation of applicable laws or
							regulations; (iv) any disputes or issues between you and any third party; or (v) your
							violation of any rights of any third party. We may assume the exclusive defense and control
							of any matter for which you are required to indemnify us, and you agree to cooperate with
							our defense of these claims. You agree not to settle any matter for which you may have an
							indemnification obligation hereunder without the prior written consent of Circles. Circles
							will use reasonable efforts to notify you of any claim, action, or proceeding for which you
							may have an indemnification obligation hereunder upon becoming aware of it. This provision
							does not require you to indemnify any of the Circles Parties for any unconscionable
							commercial practice by such party or for such party’s fraud, deception, false promise,
							misrepresentation or concealment, suppression or omission of any material fact in connection
							with the Site or Service provided hereunder. You agree that the provisions in this section
							will survive any termination of your Account, these Terms and/or your access to the Service.
						</p>
						<h4 className={styles['page-sub-title']}>9. DISCLAIMERS</h4>
						<p className={styles['page-content']}>
							NO WARRANTIES. THE SERVICE IS PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot;
							BASIS, WITH ALL FAULTS, AND Circles AND ITS AFFILIATES, CONTRACTORS, EMPLOYEES, AGENTS, OR
							THIRD PARTY PARTNERS OR SUPPLIERS, INCLUDING PAYMENT CARD NETWORKS OR PAYMENT PROCESSORS
							(COLLECTIVELY, THE “Circles PARTIES”), EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND
							CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING ALL WARRANTIES OR
							CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT,
							ACCURACY, OR NON-INFRINGEMENT.
						</p>
						<p className={styles['page-content']}>
							Circles PARTIES MAKE NO WARRANTY THAT THE SERVICE WILL MEET YOUR REQUIREMENTS, WILL BE
							AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS, OR WILL BE ACCURATE,
							RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE, OR THAT THE
							INFORMATION OR RESULTS PROVIDED IN, OR THAT MAY BE OBTAINED FROM USE OF, THE SERVICE WILL
							MEET YOUR REQUIREMENTS OR BE ACCURATE, RELIABLE, COMPLETE, OR UP-TO-DATE. Circles PARTIES
							MAKE NO WARRANTIES WHATSOEVER REGARDING ANY PRODUCTS, SERVICES, INFORMATION OR OTHER
							MATERIAL ADVERTISED, MADE AVAILABLE, OR REFERRED TO YOU THROUGH THE SERVICE. YOU ASSUME ALL
							RISK FOR ALL DAMAGES, INCLUDING DAMAGE TO YOUR COMPUTER SYSTEM, MOBILE DEVICE OR LOSS OF
							DATA THAT MAY RESULT FROM YOUR USE OF OR ACCESS TO THE SERVICE. ANY CONTENT, MATERIALS,
							INFORMATION OR SOFTWARE DOWNLOADED, USED OR OTHERWISE OBTAINED THROUGH THE USE OF THE
							SERVICE IS DONE AT YOUR OWN DISCRETION AND RISK.
						</p>
						<h4 className={styles['page-sub-title']}>10. LIMITATION ON LIABILITY</h4>
						<p className={styles['page-content']}>
							TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL Circles PARTIES BE
							LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFITS, LOST DATA, COSTS OF PROCUREMENT OF
							SUBSTITUTE PRODUCTS, OR ANY INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, WHETHER
							BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE OR ANY OTHER LEGAL THEORY,
							WHETHER OR NOT Circles (OR Circles’S SUPPLIERS, INCLUDING WITHOUT LIMITATION, THE PAYMENT
							CARD NETWORKS) HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE WHERE SUCH DAMAGES
							RESULT FROM OR RELATING TO (I) THESE TERMS, (II) YOUR ACCESS TO OR USE OF, OR INABILITY TO
							ACCESS OR USE, THE SERVICE, OR (III) ANY PURCHASE OF A THIRD PARTY PRODUCT OR SERVICE BASED
							ON INFORMATION CONTAINED IN THE SERVICE. ACCESS TO, AND USE OF, THE SERVICE IS AT YOUR OWN
							DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICES OR
							COMPUTER SYSTEMS, OR LOSS OF DATA RESULTING THEREFROM. UNDER NO CIRCUMSTANCES WILL Circles
							PARTIES BE LIABLE TO YOU FOR MORE THAN THE GREATER OF (A) THE TOTAL AMOUNT PAID TO Circles
							BY YOU DURING THE ONE-MONTH PERIOD PRIOR TO THE ACT, OMISSION OR OCCURRENCE GIVING RISE TO
							SUCH LIABILITY AND (B) ONE HUNDRED RUPEES (100 INR). THE FOREGOING CAP ON LIABILITY SHALL
							NOT APPLY TO LIABILITY OF A Circles PARTY FOR (A) DEATH OR PERSONAL INJURY CAUSED BY A
							Circles PARTY’S NEGLIGENCE; OR FOR (B) ANY INJURY CAUSED BY A Circles PARTY’S FRAUD OR
							FRAUDULENT MISREPRESENTATION. THE LIMITATIONS OF DAMAGES SET FORTH IN THIS SECTION ARE
							FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN Circles AND YOU.
						</p>
						<h4 className={styles['page-sub-title']}>11. Term and Termination</h4>
						<p className={styles['page-content']}>
							11.1 Term. You and we agree that these Terms commenced on the earlier to occur of (a) the
							date you first used the Service or (b) the date you accepted these Terms and will remain in
							full force and effect while you use the Service, unless earlier terminated in accordance
							with these Terms.
						</p>
						<p className={styles['page-content']}>
							11.2 Termination of Services by Circles. We may terminate these Terms at any time for any
							reason, including if you have materially breached any provision of these Terms, or if
							Circles is required to do so by law (e.g., where the provision of the Site, the App, or the
							Service is, or becomes, unlawful). Circles may immediately and without notice, suspend or
							terminate any Services provided to you.
						</p>
						<p className={styles['page-content']}>
							11.3 Termination of Services by You. If you want to terminate these Terms, you may do so by
							closing your Account. Contact us at arjav@royalecheese.com
						</p>
						<p className={styles['page-content']}>
							11.4 Effect of Termination. Termination of these Terms includes removal of access and all
							related information, files, Rewards, associated with or inside your Account (or any part
							thereof), and Your Content. Any rights you have to the Points &amp; Rewards in your Account
							will terminate and you will no longer be eligible to receive a distribution of such Points.
							Circles will not have any liability whatsoever to you for any suspension or termination,
							including for deletion of Your Content and your Points &amp; Rewards. All provisions of
							these Terms which by their nature should survive, shall survive termination of Services,
							including without limitation, ownership provisions, warranty disclaimers, and limitation of
							liability.
						</p>
						<h4 className={styles['page-sub-title']}>12. GOVERNING LAWS</h4>
						<p className={styles['page-content']}>
							The Terms of Use shall be governed and construed in accordance with the laws of India
							without reference to conflict of laws principles.
						</p>
						<h4 className={styles['page-sub-title']}>13. DISPUTES</h4>
						<p className={styles['page-content']}>
							With respect to any dispute regarding the Platform, all rights and obligations and all
							actions contemplated by these Terms and Conditions shall be governed by the laws of India
							and subject to this Clause 17, the courts at Hyderabad, India shall have exclusive
							jurisdiction to deal with such disputes. To the fullest extent permitted by applicable law,
							any dispute, differences or claim arising out of your visit to this Platform, shall be
							referred to and finally resolved by mediation and in accordance with the dispute resolution
							provisions under Indian Consumer Protection Act, 2019. Please review our other policies,
							including privacy policy (accessed at https://circles.royalecheese.com/privacy), posted on
							the Platform. These policies also govern your visit to the Platform. We reserve the right to
							make changes to our site, policies, and these Terms and Conditions at any time without any
							notice to You.
						</p>
						<h4 className={styles['page-sub-title']}>13. General</h4>
						<p className={styles['page-content']}>
							<span>13.1 Changes.</span> These Terms are subject to occasional revision, and we reserve
							the right to charge fees for accessing and using the Service. If we make any substantial
							changes, we may require you to accept the changes, notify you by sending you an email, as
							explained in Section 13.9, and/or by prominently posting notice of the changes on the
							Service. Any changes to these Terms will be effective upon the earliest of when you provide
							your acceptance of the changes, thirty (30) calendar days following our dispatch of an email
							notice to you (if applicable), and thirty (30) calendar days following our posting of notice
							of the changes on the Service. The changes will be effective immediately for new users of
							the Service. Continued use of our Service following notice of such changes shall indicate
							your acknowledgement and acceptance of such changes and agreement to be bound by the terms
							and conditions of such changes.
						</p>
						‍
						<p className={styles['page-content']}>
							<span>13.2Electronic Communications.</span> The communications between you and Circles use
							electronic means, whether you use the Service or send us emails, or whether Circles posts
							notices on the Service or communicates with you via email. For contractual purposes, you:
							(a) consent to receive communications from Circles in an electronic form; and (b) agree that
							all terms and conditions, agreements, notices, disclosures, and other communications that
							Circles provides to you electronically satisfy any legal requirement that such
							communications would satisfy if it were provided in a hardcopy writing. The foregoing does
							not affect your non-waivable rights.
						</p>
						‍
						<p className={styles['page-content']}>
							<span>13.3 Force Majeure. </span> Circles shall not be liable for any delay or failure to
							perform resulting from causes outside its reasonable control, including, but not limited to,
							acts of God, war, terrorism, riots, embargos, acts of civil or military authorities, fire,
							floods, accidents, strikes or shortages of transportation facilities, fuel, energy, labor or
							materials.
						</p>
						‍
						<p className={styles['page-content']}>
							<span>13.4 Questions, Complaints, Claims.</span> If you have any questions, complaints or
							claims with respect to the Service, please contact us at arjav@royalecheese.com . We will do
							our best to address your concerns. If you feel that your concerns have been addressed
							incompletely, we invite you to let us know for further investigation.
						</p>
						‍
						<p className={styles['page-content']}>
							<span>13.5Taxes.</span> You agree that you are solely responsible for ascertaining and
							complying with any tax obligations you may have in connection with your use of the Service.
						</p>
						‍
						<p className={styles['page-content']}>
							<span>13.6 Notice.</span> Where Circles requires that you provide an email address, you are
							responsible for providing Circles with your most current email address. In the event that
							the last email address you provided to Circles is not valid, Circless dispatch of the email
							containing such notice will constitute effective notice. You may give notice to Circles at
							the following address: Royale Cheese Innovations LLP, D-1105 The First, Vastrapur,
							Ahmedabad, Gujarat, India 380015. Such notice shall be deemed given when received by Circles
							by letter delivered by nationally recognized overnight delivery service or first className
							postage prepaid mail at the above address.
						</p>
						‍
						<p className={styles['page-content']}>
							<span>13.7 ‍International Users.</span> The Service can be accessed from countries around
							the world and may contain references to services and content that are not available in your
							country. These references do not imply that Circles intends to announce such services or
							content in your country. The Service is controlled and offered by Circles from its
							facilities in India. Circles makes no representations that the Service is appropriate or
							available for use in other locations. Those who access or use the Service from other
							countries do so at their own volition and are responsible for compliance with local law.
						</p>
						<p className={styles['page-content']}>
							<span>13.8 Entire Agreement. </span> These Terms constitute the entire agreement between you
							and us regarding the use of the Service. Our failure to exercise or enforce any right or
							provision of these Terms shall not operate as a waiver of such right or provision. If any
							provision of these Terms is, for any reason, held to be invalid or unenforceable, the other
							provisions of these Terms will be unimpaired and the invalid or unenforceable provision will
							be deemed modified so that it is valid and enforceable to the maximum extent permitted by
							law. These Terms, and your rights and obligations herein, may not be assigned,
							subcontracted, delegated, or otherwise transferred by you without Circles&apos;s prior
							written consent, and any attempted assignment, subcontract, delegation, or transfer in
							violation of the foregoing will be null and void. Circles may freely assign these Terms. The
							terms and conditions set forth in these Terms shall be binding upon assignees.
						</p>
						<p className={styles['page-content']}>
							<span>13.9 Contact Information:</span>
							<br />
							Royale Cheese Innovations LLP., <br />
							D-1105 The First, Vastrapur, Ahmedabad, Gujarat, India 380015 <br />
							arjav@royalecheese.com
						</p>
					</div>
				</section>
			</main>
		</>
	);
}
