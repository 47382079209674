import EssenceAnimatedButton from '../../../../../components/essence/button/animated-button';
import Header from '../../../../../shared/components/header/page';
import Content from '../../../../../shared/components/content/page';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import getMemberType from '../../../../../secure/circle-details/member-type';
import { useCircleQueries } from '../../../../../shared/tanstack/circle/queries';
import { useExpenseMutations } from '../../../../../shared/tanstack/expenses/mutations';
import { useQuery, useMutation } from '@tanstack/react-query';
import ParticipantsList from '../participant-list/page';
import { InvalidCircleIdError } from '../../../../../shared/models/errors/invalid-circle-id-error';
import { Expense } from '../../../../../shared/models/expense';
import WarningMessage from '../warning-message/page';
import { ExpenseUser } from '../../../../../shared/models/expense-user';

interface LocationState {
	expenseDetail: Expense;
}
const Participants = () => {
	const { circleId } = useParams();
	const { circleQuery, participantsQuery } = useCircleQueries();
	if (!circleId) {
		throw new InvalidCircleIdError();
	}
	const { data: circle } = useQuery(circleQuery(circleId));
	const location = useLocation();
	const { data: circleParticipants } = useQuery(participantsQuery(circleId));
	const [participants, setParticipants] = useState<ExpenseUser[]>();
	const expenseDetail = (location.state as LocationState).expenseDetail;
	const { addExpenseMutation, updateExpenseMutation } = useExpenseMutations(circleId);
	const { mutate: addExpense, isError: isAddExpenseError } = useMutation(addExpenseMutation);
	const { mutate: updateExpense, isError: isUpdateExpenseError } = useMutation(updateExpenseMutation);

	const navigate = useNavigate();
	let member: string | undefined;
	if (circle) {
		const memberType = getMemberType(circle.type);
		if (memberType) {
			member = memberType.pluralTitle;
		}
	}

	const creditAmount = participants?.reduce((sum, participant) => sum + (participant.amount || 0), 0) ?? 0;

	useEffect(() => {
		if (isAddExpenseError || isUpdateExpenseError) {
			alert('Some unknown error occured');
		}
	}, [isAddExpenseError, isUpdateExpenseError]);

	useEffect(() => {
		if (circleParticipants && circleParticipants.length > 0) {
			let updatedParticipants = circleParticipants.map(participant => {
				const amountToPay = parseFloat((expenseDetail.amount / circleParticipants.length).toFixed(2));
				const isChecked = true;
				return {
					...participant,
					isChecked,
					amount: amountToPay,
				};
			});
			const randomIndex = Math.floor(Math.random() * circleParticipants.length);

			const creditAmount = updatedParticipants.reduce((sum, participant) => sum + (participant.amount || 0), 0);
			const remainingAmount = parseFloat((expenseDetail.amount - creditAmount).toFixed(2));
			if (remainingAmount != 0) {
				updatedParticipants = updatedParticipants.map((participant, index) => {
					if (index == randomIndex) {
						return {
							...participant,
							isChecked: true,
							amount: parseFloat((participant.amount + remainingAmount).toFixed(2)),
						};
					} else {
						return participant;
					}
				});
			}
			setParticipants(updatedParticipants);
		}
	}, [circleParticipants, expenseDetail.amount]);

	const onSaveExpense = (event: React.FormEvent<HTMLFormElement>) => {
		const debtors = participants?.filter(participant => participant.amount > 0);
		event.preventDefault();
		if (!expenseDetail.expenseId) {
			addExpense({
				...expenseDetail,
				circleId: circleId,
				debtors: debtors,
			});
			navigate(-3);
		} else {
			updateExpense({
				...expenseDetail,
				circleId: circleId,
				debtors: debtors,
				expenseId: expenseDetail.expenseId,
			});
			navigate(-4);
		}
	};

	const form = {
		get isDisabled() {
			return parseFloat((expenseDetail.amount - creditAmount).toFixed(2)) != 0 ? true : undefined;
		},
	};
	return (
		<>
			{member && (
				<Header title={member} subtitle={`Select the list of ${member} who are a part of the expense.`} />
			)}
			<Content>
				<ul>
					{participants && (
						<ParticipantsList circleParticipants={participants} expenseDetail={expenseDetail} />
					)}
					{form.isDisabled && <WarningMessage amount1={expenseDetail.amount} amount2={creditAmount} />}
					<EssenceAnimatedButton onClick={onSaveExpense} name="save" disabled={form.isDisabled} />
				</ul>
			</Content>
		</>
	);
};

export default Participants;
