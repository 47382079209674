import { useQueryClient } from '@tanstack/react-query';
import { contactsKeys } from './keys';
import { OnMutateResponse } from '../models/on-mutate-response';
import { Contact } from '../../services/contacts-manager';
import { findDifference, getFormattedContacts, getPhoneContacts } from '../../services/contacts-manager';

export const useContactMutations = () => {
	const queryClient = useQueryClient();

	const loadContactsMutation = {
		mutationKey: contactsKeys.load(),

		onMutate: async (): Promise<OnMutateResponse<Contact[]>> => {
			await queryClient.cancelQueries({
				queryKey: contactsKeys.all(),
			});
			const previousData = queryClient.getQueryData<Contact[]>(contactsKeys.all()) ?? [];

			const phoneContacts = await getPhoneContacts();
			const formattedContacts = getFormattedContacts(phoneContacts);
			const difference = findDifference(previousData, formattedContacts);
			// const serverContacts = await syncContactsWithServer(difference);

			if (difference.created.length > 0 || difference.deleted.length > 0 || difference.updated.length > 0) {
				// Optimistically update to the new value
				queryClient.setQueryData(contactsKeys.all(), difference.final);
			}

			// Return a context object with the snapshotted value
			return { previousData };
		},
	};

	return {
		loadContactsMutation,
	};
};
