import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import accountService from '../../../shared/services/account-service';
import Header from '../../../shared/components/header/page';
import Content from '../../../shared/components/content/page';
import useLoaderDisplay from '../../../shared/hooks/loader-display';
import { useCircleQueries } from '../../../shared/tanstack/circle/queries';
import { useAccountMutations } from '../../../shared/tanstack/account/mutations';
import { useMutation } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { jwtDecode } from 'jwt-decode';
import { Account } from '../../../shared/models/account';
import InputVerificationCode from '../../../shared/components/input/verification-code/page';
import { formattedPhoneNumber } from '../../../shared/services/format-service';

interface State {
	phoneNumber?: string;
	countryCode?: number;
}

export default function VerifyOtp() {
	const navigate = useNavigate();
	const { phoneNumber, countryCode } = useLocation().state as State; // Read values passed on state
	if (!phoneNumber || !countryCode) {
		throw new Error('Need Phone & CountryCode');
	}

	const [skipCirclesQuery, setSkipCirclesQuery] = useState(true);

	const { verifyOtpMutation } = useAccountMutations();
	const { mutate: verifyOtp, data: token, isPaused: isSendingOtpPaused, status } = useMutation(verifyOtpMutation);

	const { circlesQuery } = useCircleQueries();
	const { data: circles, isLoading: isCirclesLoading } = useQuery(circlesQuery(skipCirclesQuery));

	useLoaderDisplay({
		key: 'verifyOtp',
		isLoading: status === 'pending' && !isSendingOtpPaused,
	});
	useLoaderDisplay({ key: 'getCircles', isLoading: isCirclesLoading });

	const verifyWithCode = (verificationCode: string) => {
		const fcmToken = accountService.getFCMToken() ?? undefined;
		const referrerId = localStorage.getItem('referrerId') ?? undefined;
		verifyOtp({
			phoneNumber,
			countryCode,
			otp: verificationCode,
			fcmToken,
			referrerId,
		});
	};

	useEffect(() => {
		if (token && phoneNumber && countryCode) {
			accountService.setToken(token);
			const accessToken = accountService.getAccessToken();
			if (accessToken) {
				const loggedInUserId = jwtDecode<Account>(accessToken).userId;
				FirebaseAnalytics.setUserId({
					userId: loggedInUserId,
				}).catch(() => {
					// Do nothing
				});

				// If the name for the account is already set, request list of circles
				if (token.name) {
					setSkipCirclesQuery(false);
				}
				// Else open the update profile page
				else {
					accountService.resetProfileCreated();
					navigate('/secure/settings/profile', {
						state: {
							phoneNumber,
							countryCode,
						},
						replace: true,
					});
				}
			}
		}
	}, [token, navigate, phoneNumber, countryCode]);

	useEffect(() => {
		if (token && circles && !skipCirclesQuery) {
			// if circles are created go to home screen else, go to circle creation screen
			navigate(circles.length > 0 ? '/secure/circles' : '/secure/onboarding/circle-info', {
				replace: true,
			});
		}
	}, [circles, token, skipCirclesQuery, navigate]);

	return (
		<>
			{countryCode && phoneNumber && (
				<Header
					title="Verify Code"
					subtitle={`enter the code sent to ${formattedPhoneNumber(phoneNumber, countryCode)}`}
				/>
			)}
			<Content>
				<p className="text-2xl">Code</p>
				<div className="flex space-x-3">
					<InputVerificationCode
						onCodeEntered={(verificationCode: string) => {
							verifyWithCode(verificationCode);
						}}
					/>
				</div>
				<div>
					By verifying the OTP you agree to the{' '}
					<Link key="terms" to={`/terms`} style={{ textDecoration: 'underline' }}>
						terms & conditions
					</Link>{' '}
					&{' '}
					<Link key="privacy" style={{ textDecoration: 'underline' }} to={`/privacy`}>
						privacy policy
					</Link>{' '}
				</div>
			</Content>
		</>
	);
}
