import api from '../../services/api';
import { toDoKeys } from './keys';
import { getItems } from '../query-response-manager';
import { useQueryClient } from '@tanstack/react-query';
import { Todo } from '../../models/todo';

export const useTodoQueries = () => {
	const queryClient = useQueryClient();

	const toDoQuery = (circleId: string) => ({
		queryKey: toDoKeys.list(circleId),
		queryFn: () =>
			api.get<Todo[]>(`/api/todo?circleId=${circleId}`).then(res => {
				const serverItems = res.data;
				const clientItems = queryClient.getQueryData<Todo[]>(toDoKeys.list(circleId)) ?? [];
				const items = getItems<Todo>(serverItems, clientItems, 'toDoId');
				return items;
			}),
		refetchOnReconnect: false,
		select: (todos: Todo[]) => todos.filter(todo => todo.syncStatus !== 'PENDING_DELETE'),
	});

	return {
		toDoQuery,
	};
};
