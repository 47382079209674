import { useQueryClient } from '@tanstack/react-query';
import { v4 as uuid } from 'uuid';
import { toDoKeys } from './keys';
import { Todo } from '../../models/todo';
import { OnMutateResponse } from '../models/on-mutate-response';
import { AddTodoRequest, EditTodoRequest } from './api';

export const useTodoMutations = (circleId: string) => {
	const queryClient = useQueryClient();

	const addToDoMutation = {
		mutationKey: toDoKeys.add(),
		onMutate: async (newTodo: AddTodoRequest): Promise<OnMutateResponse<Todo[]>> => {
			await queryClient.cancelQueries({
				queryKey: toDoKeys.list(circleId),
			});
			const previousData = queryClient.getQueryData<Todo[]>(toDoKeys.list(circleId)) ?? [];

			const clientId = uuid();

			// Optimistically update to the new value
			queryClient.setQueryData(toDoKeys.list(circleId), (old: Todo[]) => {
				const pendingTodos = old.filter(todo => todo.status === 'PENDING');
				const nonPendingTodos = old.filter(todo => todo.status !== 'PENDING');
				return [
					...pendingTodos,
					{
						...newTodo,
						toDoId: clientId,
						clientId,
						status: 'PENDING',
						dueDataTime: null,
						description: '',
						syncStatus: 'PENDING_CREATE',
					},
					...nonPendingTodos,
				];
			});

			// Return a context object with the snapshotted value
			return { previousData, clientId };
		},
		// If the mutation fails,
		// use the context returned from onMutate to roll back
		onError: (_err: unknown, _newTodo: unknown, context: OnMutateResponse<Todo[]>) => {
			queryClient.setQueryData(toDoKeys.list(circleId), context.previousData);
		},
		onSuccess: (data: Todo, variables: Todo, context: OnMutateResponse<Todo[]>) => {
			// Optimistically update to the new value
			queryClient.setQueryData<Todo[]>(toDoKeys.list(variables.circleId), todos => {
				const updatedTodos = todos?.map(todo => {
					return todo.clientId === context.clientId
						? {
								...todo,
								toDoId: data.toDoId,
							}
						: todo;
				});

				return updatedTodos;
			});
		},
	};

	const toggleToDoMutation = {
		mutationKey: toDoKeys.toggle(),
		onMutate: async ({ toDoId }): Promise<OnMutateResponse<Todo[]>> => {
			await queryClient.cancelQueries({
				queryKey: toDoKeys.list(circleId),
			});
			const previousData = queryClient.getQueryData<Todo[]>(toDoKeys.list(circleId)) ?? [];
			// Optimistically update to the new value
			queryClient.setQueryData<Todo[]>(toDoKeys.list(circleId), todos => {
				const updatedTodos = todos?.map(todo => {
					if (todo.toDoId === toDoId) {
						const status = todo.status === 'COMPLETED' ? 'PENDING' : 'COMPLETED';
						return {
							...todo,
							status,
						};
					}
					return todo;
				});

				// Find the index of the updated todo
				const updatedTodoIndex = updatedTodos?.findIndex(todo => todo.toDoId === toDoId) ?? 0;

				// Remove the updated todo from the array
				const updatedTodo = updatedTodos?.splice(updatedTodoIndex, 1)[0];

				if (updatedTodo) {
					// Push the updated todo to the end
					updatedTodos.push(updatedTodo);
				}

				return updatedTodos;
			});

			return { previousData };
		},
		// If the mutation fails,
		// use the context returned from onMutate to roll back
		onError: (err, newToDo, context: OnMutateResponse<Todo[]>) => {
			queryClient.setQueryData(toDoKeys.list(circleId), context.previousData);
		},
	};

	const deleteToDoMutation = {
		mutationKey: toDoKeys.delete(),
		onMutate: async (newTodo: Todo) => {
			await queryClient.cancelQueries({
				queryKey: toDoKeys.list(circleId),
			});
			const previousData = queryClient.getQueryData(toDoKeys.list(circleId));

			// Optimistically update to the new value
			queryClient.setQueryData<Todo[]>(toDoKeys.list(circleId), todos => {
				const updatedTodos = todos?.map(todo => {
					if (todo.toDoId === newTodo.toDoId) {
						return {
							...todo,
							syncStatus: 'PENDING_DELETE',
						};
					}
					return todo;
				});

				return updatedTodos;
			});

			// Return a context object with the snapshotted value
			return { previousData };
		},
		// If the mutation fails,
		// use the context returned from onMutate to roll back
		onError: (err, newToDo, context: OnMutateResponse<Todo[]>) => {
			queryClient.setQueryData(toDoKeys.list(circleId), context.previousData);
		},
	};

	const reorderToDoMutation = {
		mutationKey: toDoKeys.reorder(),
		onMutate: async (newTodos: Todo[]) => {
			await queryClient.cancelQueries({
				queryKey: toDoKeys.list(circleId),
			});
			const previousData = queryClient.getQueryData<Todo[]>(toDoKeys.list(circleId));

			const previousTodosData = newTodos.map(todo => {
				const index = previousData?.findIndex(prevTodo => prevTodo.toDoId === todo.toDoId);
				if (index) {
					return previousData?.[index];
				}
				return todo;
			});

			// Optimistically update to the new value
			queryClient.setQueryData(toDoKeys.list(circleId), previousTodosData);

			// Return a context object with the snapshotted value
			return { previousData };
		},
		// If the mutation fails,
		// use the context returned from onMutate to roll back
		onError: (err, newTodo, context: OnMutateResponse<Todo[]>) => {
			queryClient.setQueryData(toDoKeys.list(circleId), context.previousData);
		},
	};

	const editToDoMutation = {
		mutationKey: toDoKeys.edit(),
		onMutate: async ({ toDoId, name }: EditTodoRequest): Promise<OnMutateResponse<Todo[]>> => {
			await queryClient.cancelQueries({
				queryKey: toDoKeys.list(circleId),
			});
			const previousData = queryClient.getQueryData<Todo[]>(toDoKeys.list(circleId)) ?? [];
			// Optimistically update to the new value
			queryClient.setQueryData<Todo[]>(toDoKeys.list(circleId), todos => {
				const updatedTodos = todos?.map(todo => {
					if (todo.toDoId === toDoId) {
						return {
							...todo,
							name,
						};
					}
					return todo;
				});

				return updatedTodos;
			});

			return { previousData };
		},
		// If the mutation fails,
		// use the context returned from onMutate to roll back
		onError: (err, newToDo, context: OnMutateResponse<Todo[]>) => {
			queryClient.setQueryData(toDoKeys.list(circleId), context.previousData);
		},
	};

	return {
		addToDoMutation,
		toggleToDoMutation,
		deleteToDoMutation,
		reorderToDoMutation,
		editToDoMutation,
	};
};
