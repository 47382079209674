import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../../shared/components/header/page';
import Content from '../../../shared/components/content/page';
import useLoaderDisplay from '../../../shared/hooks/loader-display';
import { useMutation } from '@tanstack/react-query';
import { useCircleMutations } from '../../../shared/tanstack/circle/mutations';
import { onlineManager } from '@tanstack/react-query';
import EssenceAlert, { AlertInfo } from '../../../components/essence/alert/alert';
import AddCircleForm from '../components/add-circle-form/add-circle-form';

export default function CircleInfo() {
	const navigate = useNavigate();

	const isOnline = onlineManager.isOnline();

	const [alert, setAlert] = useState<AlertInfo>({
		show: false,
		message: 'There was some error creating the circle. Please try again later.',
	});

	// const [searchParams, setSearchParams] = useSearchParams();

	// const submitForm = (e) => {
	//   // We don't want the page to refresh
	//   e.preventDefault();

	//   searchParams.set("name", e.target["circle-name"].value);
	//   setSearchParams(searchParams);

	//   navigate(
	//     "/secure/onboarding/circle-interests" + "?" + searchParams.toString()
	//   );
	// };
	const { addCircleMutation } = useCircleMutations();
	const { mutate: addCircle, isSuccess, isError, reset, status, isPaused, data } = useMutation(addCircleMutation);

	useLoaderDisplay({
		key: 'addCircle',
		isLoading: status === 'pending' && !isPaused,
	});

	useEffect(() => {
		if (isSuccess) {
			navigate(`/secure/circles/${data.circleId}/participants/add`, {
				replace: true,
			});
		}
	}, [isSuccess, data, navigate]);

	useEffect(() => {
		if (isError) {
			setAlert({
				show: true,
				message: 'There was some error creating the circle. Please try again later.',
			});
		}
	}, [isError]);

	const createCircle = (circleName: string) => {
		addCircle({
			interests: ['todo', 'expenses', 'itinerary'],
			type: 'Travel',
			name: circleName,
		});
	};

	const hideAlert = () => {
		reset();
		setAlert({
			show: false,
			message: alert.message,
		});
	};

	return (
		<>
			{!isOnline && (
				<EssenceAlert
					onOk={hideAlert}
					okButton="okay"
					message={'Please connect to the internet to create a circle.'}></EssenceAlert>
			)}

			{alert.show && <EssenceAlert onOk={hideAlert} okButton="okay" message={alert.message}></EssenceAlert>}
			<Header title="Details" subtitle="What would you like to call your circle?" />
			<Content>
				<AddCircleForm onCreate={createCircle} />
			</Content>
		</>
	);
}
