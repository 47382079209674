import { Expense } from '../../models/expense';
import { ExpenseSummary } from '../../models/expense-summary';
import api from '../../services/api';
import { getItems } from '../query-response-manager';
import { expensesKeys } from './keys';
import { useQueryClient } from '@tanstack/react-query';

export const useExpenseQueries = () => {
	const queryClient = useQueryClient();

	const expensesQuery = (circleId: string) => ({
		refetchOnReconnect: false,
		queryKey: expensesKeys.list(circleId),
		queryFn: () =>
			api.get<Expense[]>(`/api/expense?circleId=${circleId}`).then(res => {
				const serverItems = res.data;
				const clientItems: Expense[] = queryClient.getQueryData(expensesKeys.list(circleId)) ?? [];
				const items = getItems<Expense>(serverItems, clientItems, 'expenseId');
				// items.sort((a, b) => (new Date(b.dateTime)) - (new Date(a.dateTime)));
				items.sort((a, b) => {
					const dateA = a.dateTime.getTime();
					const dateB = b.dateTime.getTime();

					// Compare dateB and dateA to sort in descending order (latest first)
					return dateB - dateA;
				});

				return items;
			}),
		select: (expenses: Expense[]) => expenses.filter(expense => expense.syncStatus !== 'PENDING_DELETE'),
	});

	const expensesSummaryQuery = (circleId: string) => ({
		refetchOnReconnect: false,
		queryKey: expensesKeys.summary(circleId),
		queryFn: () => api.get<ExpenseSummary[]>(`/api/expense/summary?circleId=${circleId}`).then(res => res.data),
	});

	const expenseQuery = (expenseId: string) => ({
		refetchOnReconnect: false,
		queryKey: expensesKeys.detail(expenseId),
		queryFn: () => api.get<Expense>(`/api/expense/${expenseId}`).then(res => res.data),
	});

	return {
		expensesQuery,
		expensesSummaryQuery,
		expenseQuery,
	};
};
