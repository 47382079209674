import { Expense } from '../models/expense';

const _getStorageKey = (prefix: string, value: string) => {
	return `${prefix}-${value}`;
};

const _getValue = <T>(key: string): T | undefined => {
	const valueStr = localStorage.getItem(key);
	if (valueStr) {
		try {
			return JSON.parse(valueStr) as T;
		} catch {
			return;
		}
	}
	return undefined;
};

const _setValue = <T>(key: string, value: T) => {
	if (value) {
		localStorage.setItem(key, JSON.stringify(value));
	} else {
		localStorage.removeItem(key);
	}
};

const _removeValues = (prefix: string, storageKey: string) => {
	const keys = Object.keys(localStorage);
	for (const key of keys) {
		if (key.startsWith(prefix) && key !== storageKey) {
			localStorage.removeItem(key);
		}
	}
};

const getSettlementForKey = (key: string) => {
	const fullKey = _getStorageKey('settlement-storage-key', key);
	return _getValue<Expense>(fullKey);
};

const setSettlmentForKey = (key: string, value: Expense) => {
	const fullKey = _getStorageKey('settlement-storage-key', key);
	_setValue<Expense>(fullKey, value);
};

const removeSettlementsExceptKey = (key: string) => {
	const fullKey = _getStorageKey('settlement-storage-key', key);
	_removeValues('settlement-storage-key', fullKey);
};

const getExpenseForKey = (key: string) => {
	const fullKey = _getStorageKey('expense-storage-key', key);
	return _getValue<Expense>(fullKey);
};

const setExpenseForKey = (key: string, value: Expense) => {
	const fullKey = _getStorageKey('expense-storage-key', key);
	_setValue<Expense>(fullKey, value);
};

const removeExpensesExceptKey = (key: string) => {
	const fullKey = _getStorageKey('expense-storage-key', key);
	_removeValues('expense-storage-key', fullKey);
};

export default {
	getSettlementForKey,
	setSettlmentForKey,
	removeSettlementsExceptKey,
	getExpenseForKey,
	setExpenseForKey,
	removeExpensesExceptKey,
};
