import EssenceButton from '../button/button';
import styles from './alert.module.css';

export interface AlertInfo {
	show: boolean;
	message: string;
}
interface Props extends React.ComponentProps<'button'> {
	message: string;
	okButton: string;
	dismissButton?: string;
	onOk?: React.MouseEventHandler<HTMLButtonElement>;
	onDismiss?: React.MouseEventHandler<HTMLButtonElement>;
}
export default function EssenceAlert({ onOk, onDismiss, message, okButton, dismissButton, ...props }: Props) {
	return (
		<>
			<div className={styles.overlay} />
			<div className={styles.alert}>
				<div className={styles['txt-message']}>{message}</div>
				<div className={styles['buttons-container']}>
					{dismissButton && (
						<EssenceButton data-testid="btn-dismiss" {...props} name={dismissButton} onClick={onDismiss} />
					)}
					{okButton && <EssenceButton data-testid="btn-ok" {...props} name={okButton} onClick={onOk} />}
				</div>
			</div>
		</>
	);
}
