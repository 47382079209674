import Clickable from '../core/clickable';
import styles from './radio.module.css';

interface Props extends React.ComponentProps<'input'> {
	disabled?: boolean;
	text: string;
}

export default function EssenceRadio({ onChange, disabled, text, ...props }: Props) {
	return (
		<Clickable disabled={disabled}>
			<label className={`${styles['radio-container']} ${disabled ? styles['radio-container-disabled'] : ''}`}>
				<input onChange={onChange} type="radio" {...props} />
				<div className={`${styles.radiomark} subtitle-1`} />
				{text}
			</label>
		</Clickable>
	);
}
