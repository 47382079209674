import { Link } from 'react-router-dom';
import { Expense } from '../../shared/models/expense';
import ExpenseRow from './expense-row';

export interface ExpensesProps {
	expenses: Expense[];
	circleId: string;
	loggedInUserId: string;
}

export default function ListExpenses({ expenses, circleId, loggedInUserId }: ExpensesProps) {
	return (
		<div>
			{expenses.map(expense => (
				<Link key={expense.expenseId} to={`/secure/expense/${expense.expenseId}`} state={{ circleId }}>
					<ExpenseRow key={expense.expenseId} expense={expense} loggedInUserId={loggedInUserId} />
				</Link>
			))}
		</div>
	);
}
