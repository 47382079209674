import { Outlet } from 'react-router-dom';
import AddExpenseSteps from './add-expense-steps/page';

export default function AddExpense() {
	return (
		<div>
			<div className="grid grid-cols-12">
				<AddExpenseSteps />
				<div className="col-span-12 md:col-span-6 flex flex-col h-auto md:h-screen justify-center px-5 mt-4 md:px-16 md:bg-zinc-900">
					<Outlet />
				</div>
			</div>
		</div>
	);
}
