import Animated from '../../../../components/essence/animated/animated';
import EssenceCheckbox from '../../../../components/essence/checkbox/checkbox';
import { ReorderIcon } from '../../../../components/essence/checkbox/render-icon';
import { Todo } from '../../../../shared/models/todo';
import { Reorder, useDragControls } from 'framer-motion';
import styles from './page.module.css';

interface Props {
	todo: Todo;
	showIndex: boolean;
	index: number;
	onToggle(option: string): void;
	onRemove(option: string): void;
	onDragEnd(): void;
	onEdit?(todo: Todo, text: string): void;
}
export default function TodoRow({ todo, index, onToggle, onRemove, onDragEnd, onEdit, showIndex }: Props) {
	const dragControls = useDragControls();
	const noAnimation = {
		initial: {},
		animate: {},
		exit: {},
	};
	const onToDoEdit = (text: string) => {
		if (onEdit && text !== todo.name) {
			onEdit(todo, text);
		}
	};
	return (
		<Reorder.Item
			key={todo.toDoId}
			value={todo}
			dragListener={false}
			dragControls={dragControls}
			onDragEnd={onDragEnd}
			variants={noAnimation}>
			<Animated>
				<div className="flex items-center">
					<span className="md:mr-10 mr-4 cursor-move">
						<ReorderIcon testId={todo.toDoId} dragControls={dragControls} />
					</span>
					<div className="w-full">
						<EssenceCheckbox
							showIndex={showIndex}
							index={index}
							onEdit={onToDoEdit}
							editable={true}
							className={todo.status === 'COMPLETED' ? styles['is-checked'] : ''}
							title={todo.name}
							checked={todo.status === 'COMPLETED'}
							checkBoxColor={todo.status === 'COMPLETED' ? 'gray' : ''}
							testId={todo.toDoId}
							onChange={() => {
								onToggle(todo.toDoId);
							}}
							actionIcon="fa fa-trash"
							onActionClick={() => {
								onRemove(todo.toDoId);
							}}
						/>
					</div>
				</div>
			</Animated>
		</Reorder.Item>
	);
}
