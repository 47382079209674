import { useState, useEffect } from 'react';
import Page from '../../../../components/essence/page/page';
import Animated from '../../../../components/essence/animated/animated';
import AnimatedParent from '../../../../components/essence/animated/animated-parent';
import getMemberType from '../../member-type';
import { useNavigate, useParams } from 'react-router-dom';
import EssenceCheckbox from '../../../../components/essence/checkbox/checkbox';
import { useRef } from 'react';
import EssenceButton from '../../../../components/essence/button/button';
import { useCircleQueries } from '../../../../shared/tanstack/circle/queries';
import { useCircleMutations } from '../../../../shared/tanstack/circle/mutations';
import { useQuery, useMutation } from '@tanstack/react-query';
import { Contact } from '../../../../shared/services/contacts-manager';
import styles from './page.module.css';
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';
import { InvalidCircleIdError } from '../../../../shared/models/errors/invalid-circle-id-error';
import mobileStyles from './mobile-style.module.css';
import { useContactsQueries } from '../../../../shared/tanstack/contacts/queries';
import { useContactMutations } from '../../../../shared/tanstack/contacts/mutations';

const AddParticipants = () => {
	const navigate = useNavigate();
	const { circleId } = useParams();
	if (!circleId) {
		throw new InvalidCircleIdError();
	}

	const pageSize = 50; // Adjust this to control the number of contacts fetched per page
	const [searchTerm, setSearchTerm] = useState<string>('');

	const { contactsQuery } = useContactsQueries();
	const [page, setPage] = useState(0);

	const { data: contacts } = useQuery(contactsQuery());

	const [selectedContacts, setSelectedContacts] = useState<Map<string, Contact>>(new Map());
	const [filteredContacts, setFilteredContacts] = useState<Contact[]>([]);
	const observerRef = useRef<HTMLDivElement>(null);
	const { circleQuery } = useCircleQueries();
	const { data: circle } = useQuery(circleQuery(circleId));
	let member: string | undefined;
	const circleType = circle?.type;
	if (circleType) {
		member = getMemberType(circleType)?.pluralTitle;
	}

	const { addParticipantsMutation } = useCircleMutations();
	const { mutate: addParticipant } = useMutation(addParticipantsMutation);

	const { loadContactsMutation } = useContactMutations();
	const { mutate: loadContacts } = useMutation(loadContactsMutation);

	useEffect(() => {
		loadContacts();
	}, [loadContacts]);

	useEffect(() => {
		if (contacts) {
			const search = searchTerm.toLowerCase();
			const selectedContactsArr: Contact[] = Array.from(selectedContacts.values());
			const newContacts: Contact[] = contacts
				.filter((contact: Contact) => {
					return search && search.length > 0
						? contact.name.toLowerCase().includes(search) ||
								contact.phoneNumber.toLowerCase().includes(search)
						: true;
				})
				.filter((contact: Contact) => {
					return (
						selectedContacts.get(`${contact.countryCode.toString()}${contact.phoneNumber}}`) === undefined
					);
				})
				.slice(page * pageSize, (page + 1) * pageSize) as Contact[];

			if (page == 0) {
				setFilteredContacts(() => [...selectedContactsArr, ...newContacts]);
			} else {
				setFilteredContacts(() => [...selectedContactsArr, ...filteredContacts, ...newContacts]);
			}
		}
	}, [contacts, page, searchTerm, selectedContacts, filteredContacts]);

	useEffect(() => {
		const handleObserver = (entries: IntersectionObserverEntry[]) => {
			const target = entries[0];
			if (target.isIntersecting) {
				if ((contacts?.length ?? 0) > 0) {
					setPage(page => page + 1);
				}
			}
		};

		if (observerRef.current) {
			const observer = new IntersectionObserver(handleObserver, {
				threshold: 0.1,
			});
			observer.observe(observerRef.current);
			return () => {
				observer.disconnect();
			};
		}
	}, [observerRef, contacts?.length]);

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPage(0);
		setSearchTerm(event.target.value);
	};

	const handleToggleCompletion = (contactToUpdate: Contact) => {
		setPage(0);
		setSearchTerm('');
		if (contactToUpdate.isChecked) {
			contactToUpdate.isChecked = false;
			setSelectedContacts(selectedContacts => {
				selectedContacts.delete(`${contactToUpdate.countryCode.toString()}${contactToUpdate.phoneNumber}}`);
				return new Map(selectedContacts);
			});
		} else {
			contactToUpdate.isChecked = true;
			setSelectedContacts(selectedContacts => {
				selectedContacts.set(
					`${contactToUpdate.countryCode.toString()}${contactToUpdate.phoneNumber}}`,
					contactToUpdate,
				);
				return new Map(selectedContacts);
			});
		}
		document.body.scrollTop = document.documentElement.scrollTop = 0;
	};

	const saveParticipants = () => {
		const participantsToAdd = Array.from(selectedContacts.values());

		if (participantsToAdd.length > 0) {
			addParticipant({
				circleId,
				contacts: participantsToAdd.map(participant => ({
					userId: participant.userId ?? '',
					countryCode: participant.countryCode,
					phoneNumber: participant.phoneNumber,
				})),
			});
		}

		navigate(-1);
	};

	const getName = (contact: Contact) => {
		return contact.name.length === 0 ? '-' : contact.name;
	};

	const getPhoneNumber = (contact: Contact) => {
		let phoneNumber: string;
		if (contact.phoneNumber) {
			phoneNumber = contact.phoneNumber;
		} else if (contact.lastFourNumber) {
			phoneNumber = `xxx.xxx.${contact.lastFourNumber}`;
		} else {
			return;
		}

		if (contact.countryCode) {
			return `+${contact.countryCode.toString()} ${phoneNumber}`;
		}
	};

	const openSystemAppSettings = () => {
		NativeSettings.open({
			optionAndroid: AndroidSettings.ApplicationDetails,
			optionIOS: IOSSettings.App,
		}).catch(() => {
			// Do nothing
		});
	};

	return (
		<AnimatedParent>
			<div style={{ position: 'relative' }}>
				{member && (
					<Page title={`add ${member}`}>
						<div style={{ position: 'relative' }}>
							<Animated>
								<input
									className={mobileStyles['mobile-search']}
									essence-input="true"
									type="text"
									placeholder={'search ' + member}
									value={searchTerm}
									onChange={handleSearchChange}
								/>
							</Animated>

							<div className="pb-16">
								{contacts?.length === 0 && (
									<div className="mt-10 body-1">
										<div className="opacity-50">
											We would like to access your contacts so that you can add them to the
											circle.
											<br></br>
											<br></br>
											<div>
												Please allow permission to access your contacts from the system app
												settings.
											</div>
											<br></br>
										</div>
										<EssenceButton name="open settings" onClick={openSystemAppSettings} />
									</div>
								)}

								{filteredContacts.map((contact, i) => (
									<EssenceCheckbox
										key={`${i.toString()} ${contact.phoneNumber}`}
										title={getName(contact)}
										subTitle={getPhoneNumber(contact)}
										checked={contact.isChecked}
										onChange={() => {
											handleToggleCompletion(contact);
										}}
									/>
								))}
								{filteredContacts.length !== contacts?.length && (
									<div ref={observerRef} className="text-center p-10">
										{/* Loading... */}
									</div>
								)}
							</div>
						</div>
					</Page>
				)}
				{contacts && contacts.length > 0 && (
					<div className={styles['add-participants']}>
						<EssenceButton name="add participants" onClick={saveParticipants} />
					</div>
				)}
			</div>
		</AnimatedParent>
	);
};

export default AddParticipants;
