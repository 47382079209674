import { Outlet } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import styles from './add-settlement.module.css';
import { useCircleQueries } from '../../../../shared/tanstack/circle/queries';
import { useQuery } from '@tanstack/react-query';
import getMemberType from '../../member-type';
import { InvalidCircleIdError } from '../../../../shared/models/errors/invalid-circle-id-error';

export default function AddSettlement() {
	const { circleId } = useParams();
	const { circleQuery } = useCircleQueries();
	if (!circleId) {
		throw new InvalidCircleIdError();
	}
	const { data: circle } = useQuery(circleQuery(circleId));
	const url = window.location.href;
	const parts = url.split('/');
	const lastPart = parts[parts.length - 1];
	let member: string | undefined;
	if (circle) {
		const memberType = getMemberType(circle.type);
		if (memberType) {
			member = memberType.pluralTitle;
		}
	}
	return (
		<div>
			<div className="grid grid-cols-12">
				<div className="hidden md:block col-span-6">
					<div className="hidden md:flex items-center justify-center flex-col h-screen">
						<h1 className="mt-10 text-5xl">{circle?.name}</h1>
						<h4 className="text-2xl mt-16 mb-10">Settle in 2 easy steps</h4>
						<ol>
							<li
								className={`${styles.list} ${
									lastPart.startsWith('payee') ? styles['bold-style'] : ''
								}`}>
								Select {member} to settle with
							</li>
							<li
								className={`${styles.list} ${
									lastPart.startsWith('settlement-detail') ? styles['bold-style'] : ''
								}`}>
								Add Settlement Details
							</li>
						</ol>
					</div>
				</div>
				<div className="col-span-12 md:col-span-6 flex flex-col h-auto md:h-screen justify-center px-5 mt-4 md:px-16 md:bg-zinc-900">
					<Outlet />
				</div>
			</div>
		</div>
	);
}
