import { NavigateFunction, useNavigate } from 'react-router-dom';
import EssenceAnimatedButton from '../../../../components/essence/button/animated-button';

interface Props {
	circleId: string;
}
export default function Itinerary({ circleId }: Props) {
	const navigate: NavigateFunction = useNavigate();
	// const { itineraryQuery } = useItineraryQueries();
	// const { data: itinerary } = useQuery(itineraryQuery(''));

	const goToAddItinerary = () => {
		localStorage.removeItem(`itinerary-detail`);
		navigate(`/secure/circles/${circleId}/add-itinerary/itinerary-detail`);
	};

	return (
		<>
			<EssenceAnimatedButton name="Add Itinerary" onClick={goToAddItinerary} />
			{/* <ListItinerary itinerary={itinerary} circleId={circleId} /> */}
		</>
	);
}
