import { QueryClient } from '@tanstack/react-query';
import { updateProfile, logout, deleteAccount, UpdateProfileRequest } from './api';
import { accountKeys } from './keys';

export const setDefaultAccountMutation = (queryClient: QueryClient) => {
	// we need a default mutation function so that paused mutations can resume after a page reload
	queryClient.setMutationDefaults(accountKeys.update(), {
		mutationFn: async (account: UpdateProfileRequest) => {
			// to avoid clashes with our optimistic update when an offline mutation continues
			await queryClient.cancelQueries({
				queryKey: accountKeys.update(),
			});
			await updateProfile(account);
		},
	});

	queryClient.setMutationDefaults(accountKeys.logout(), {
		mutationFn: async () => {
			// to avoid clashes with our optimistic update when an offline mutation continues
			await queryClient.cancelQueries({
				queryKey: accountKeys.logout(),
			});
			await logout();
		},
	});

	queryClient.setMutationDefaults(accountKeys.delete(), {
		mutationFn: async () => {
			// to avoid clashes with our optimistic update when an offline mutation continues
			await queryClient.cancelQueries({
				queryKey: accountKeys.delete(),
			});
			await deleteAccount();
		},
	});
};
