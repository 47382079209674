import { Base } from '../models/base';

export const getItems = <T extends Base>(serverItems: T[], clientItems: T[], idKeyName: string) => {
	// The difference algorithm
	// Lets assume A = server items and B = client items
	// Find items = aItems that are in A but not in B
	// Find items = dItems that are in A and in B but marked as deleted in B
	// Find items = cItems that in B but not in A and marked as created.
	// Find items = uItems that are in A and in B but not deleted. Take the item with the latest updated at value.
	// Do a union of aItems, dItems, cItems, uItems, sort it by desc dateTime and set in Query Data.

	let items: T[] = [];

	for (const serverItem of serverItems) {
		const clientItem = clientItems.find(clientItem => clientItem[idKeyName] === serverItem[idKeyName]);
		if (clientItem) {
			// The items has been marked as deleted locally.
			if (clientItem.syncStatus === 'PENDING_DELETE') {
				// Do nothing
			} else {
				if (serverItem.updatedAt === undefined || clientItem.updatedAt === undefined) {
					items.push(serverItem);
				} else if (serverItem.updatedAt.toString() > clientItem.updatedAt.toString()) {
					// The server has the most updated item
					items.push(serverItem);
				} else {
					// The client has the most updated item
					items.push(clientItem);
				}
			}
		} else {
			// The item was not found on client side.
			items.push(serverItem);
		}
	}

	for (const clientItem of clientItems) {
		const serverItem = serverItems.find(serverItem => clientItem[idKeyName] === serverItem[idKeyName]);

		if (!serverItem && clientItem.syncStatus === 'PENDING_CREATE') {
			items.push(clientItem);
		}
	}

	items = items.sort((x, y) => ((x.updatedAt ?? new Date()) > (y.updatedAt ?? new Date()) ? 1 : 0));
	return items;
};
