import EssenceAnimatedButton from '../../components/essence/button/animated-button';
import { Participant } from '../../shared/models/participant';
import storageKeyService from '../../shared/services/storage-key-service';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ExpenseSummaryList from './expense-summary-list';
import { ExpenseSummary as ExpenseSummaryItem } from '../../shared/models/expense-summary';
import { jwtDecode } from 'jwt-decode';
import { useState, useEffect } from 'react';
import { Account } from '../../shared/models/account';
import accountService from '../../shared/services/account-service';

interface Props {
	expensesSummary: ExpenseSummaryItem[];
	participants: Participant[];
	circleId: string;
}

export default function ExpenseSummary({ expensesSummary, participants, circleId }: Props) {
	const [loggedInUserId, setLoggedInUserId] = useState('');

	useEffect(() => {
		const accessToken = accountService.getAccessToken();
		if (accessToken) {
			setLoggedInUserId(jwtDecode<Account>(accessToken).userId);
		}
	}, []);

	const [searchParams] = useSearchParams();
	const storageKey = searchParams.get('token');
	const navigate = useNavigate();

	const goToSettleUp = () => {
		if (storageKey) {
			storageKeyService.removeSettlementsExceptKey(storageKey);
			const settlementDetail = {
				...expensesSummary[0],
				dateTime: new Date(),
				circleId: circleId,
				description: 'Settlement',
			};
			navigate(`/secure/circles/${circleId}/add-settlement/payee?token=${new Date().getTime().toString()}`, {
				state: { settlementDetail },
			});
		}
	};

	return (
		<div className="mb-6 gap-20 items-center">
			{expensesSummary.map(summary => (
				<ExpenseSummaryList
					key={summary.creditorId}
					expenseSummary={summary}
					loggedInUserId={loggedInUserId}
					participants={participants}
				/>
			))}
			<EssenceAnimatedButton name="settle up" onClick={goToSettleUp} />
		</div>
	);
}
