import EssenceRadio from '../../../components/essence/radio/radio';
import EssenceButton from '../../../components/essence/button/button';
import Header from '../../../shared/components/header/page';
import Content from '../../../shared/components/content/page';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';

export default function CircleType() {
	const [searchParams, setSearchParams] = useSearchParams();
	const [value, setValue] = useState('');
	const navigate = useNavigate();
	const types = [
		{
			displayName: 'Family',
			value: 'family',
		},
		{
			displayName: 'Friends',
			value: 'friends',
		},
		{
			displayName: 'Work',
			value: 'work',
		},
		{
			displayName: 'Travel',
			value: 'travel',
		},
	];

	const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
		// We don't want the page to refresh
		e.preventDefault();
		searchParams.set('type', value);
		setSearchParams(searchParams);

		navigate('/secure/onboarding/circle-info' + '?' + searchParams.toString());
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
	};

	return (
		<>
			<Header title="Circle Type" subtitle="Based on your circle we've pre-selected some values for you" />

			<Content>
				<form onSubmit={submitForm}>
					{types.map(type => {
						return (
							<EssenceRadio
								defaultChecked={searchParams.get('type') === type.value ? true : false}
								key={type.value}
								text={type.displayName}
								name="circle-type-group"
								value={type.value}
								onChange={onChange}
							/>
						);
					})}
					<div className="mb-16" />
					<EssenceButton type="submit" name="Next" />
				</form>
			</Content>
		</>
	);
}
