import { useQueryClient } from '@tanstack/react-query';
import api from '../../services/api';
import { circlesKeys } from './keys';
import { getItems } from '../query-response-manager';
import { Circle } from '../../models/circle';
import { Participant } from '../../models/participant';
import { addParticipants } from '../../services/contacts-manager';

export const useCircleQueries = () => {
	const queryClient = useQueryClient();

	const circlesQuery = (skipCirclesQuery = false) => ({
		refetchOnReconnect: false,
		queryKey: circlesKeys.list(),
		queryFn: () =>
			api.get<Circle[]>(`/api/circle`).then(res => {
				const serverItems = res.data;
				const clientItems = queryClient.getQueryData<Circle[]>(circlesKeys.list()) ?? [];
				const items = getItems<Circle>(serverItems, clientItems, 'circleId');
				return items;
			}),
		enabled: !skipCirclesQuery,
		select: (circles: Circle[]) => circles.filter(circle => circle.syncStatus !== 'PENDING_DELETE'),
	});

	const circleQuery = (circleId: string) => ({
		refetchOnReconnect: false,
		queryKey: circlesKeys.detail(circleId),
		queryFn: () => api.get<Circle>(`/api/circle/${circleId}`).then(res => res.data),
	});

	const participantsQuery = (circleId: string) => ({
		refetchOnReconnect: false,
		queryKey: circlesKeys.participants(circleId),

		queryFn: async () =>
			await api.get<Participant[]>(`/api/circle/${circleId}/participant`).then(async res => {
				await addParticipants(res.data);

				const serverItems = res.data;
				const clientItems = queryClient.getQueryData<Participant[]>(circlesKeys.participants(circleId)) ?? [];
				const mappedServerItems = serverItems.map(serverItem => {
					const clientItem = clientItems.find(clientItem => serverItem.userId === clientItem.userId);
					if (clientItem) {
						const mappedItem = {
							...serverItem,
							...clientItem,
							updatedAt: new Date(),
						};
						delete mappedItem.syncStatus;
						return mappedItem;
					} else {
						return serverItem;
					}
				});

				const items = getItems<Participant>(mappedServerItems, clientItems, 'userId');
				return items;
			}),
		select: (participants: Participant[] | undefined) => {
			if (participants) {
				return participants.filter(participant => participant.syncStatus !== 'PENDING_DELETE');
			}
			return undefined;
		},
	});

	return {
		circlesQuery,
		circleQuery,
		participantsQuery,
	};
};
