interface HeaderProps {
	title: string;
	subtitle: string;
}
export default function Header({ title, subtitle }: HeaderProps) {
	return (
		<div className="mb-10">
			<div className="headline-2">{title}</div>
			<div className="caption">{subtitle}</div>
		</div>
	);
}
