import React, { useEffect } from 'react';
import EssenceAnimatedButton from '../../../../../components/essence/button/animated-button';
import Header from '../../../../../shared/components/header/page';
import Content from '../../../../../shared/components/content/page';
import { useLocation, useNavigate, useSearchParams, useParams } from 'react-router-dom';
import storageKeyService from '../../../../../shared/services/storage-key-service';
import ExpensedetailForm from './expense-detail-form';
import { Expense } from '../../../../../shared/models/expense';
import { InvalidCircleIdError } from '../../../../../shared/models/errors/invalid-circle-id-error';

interface LocationState {
	expenseDetail?: Expense;
}
const AddExpenseDetail = () => {
	const [searchParams] = useSearchParams();

	const storageKey = searchParams.get('token');

	const navigate = useNavigate();
	const location = useLocation();
	const { circleId } = useParams();
	if (!circleId) {
		throw new InvalidCircleIdError();
	}

	const expenseFromState = (location.state as LocationState).expenseDetail;
	if (expenseFromState) {
		expenseFromState.dateTime = new Date(expenseFromState.dateTime);
	}
	let existingExpense: Expense | undefined;
	if (storageKey) {
		existingExpense = storageKeyService.getExpenseForKey(storageKey);
	}

	if (existingExpense) {
		existingExpense.dateTime = new Date(existingExpense.dateTime);
	}

	const expenseDetail = existingExpense
		? existingExpense
		: expenseFromState
			? expenseFromState
			: {
					circleId,
					expenseId: '',
					isSettlement: false,
					description: '',
					currency: '',
					amount: 0,
					creditors: [],
					debtors: [],
					dateTime: new Date(),
					createdBy: '',
				};

	useEffect(() => {
		if (storageKey) {
			storageKeyService.removeExpensesExceptKey(storageKey);
		}
		if (location.state === null) {
			navigate(-1);
		}
	}, [location.state, navigate, storageKey]);

	const goToPayee = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();

		if (expenseDetail.creditors) {
			delete expenseDetail.creditors;
		}

		expenseDetail.currency = '₹';

		if (storageKey) {
			storageKeyService.setExpenseForKey(storageKey, expenseDetail);
		}
		navigate(`/secure/circles/${circleId}/add-expense/payee`, {
			state: { expenseDetail, storageKey },
		});
	};

	const form = {
		get isDisabled() {
			return !expenseDetail.amount || !expenseDetail.description ? true : undefined;
		},
	};

	return (
		<>
			<Header title="Details" subtitle={`Enter the details of the expense.`} />
			<Content>
				<div>
					<ExpensedetailForm expense={expenseDetail} />
					<EssenceAnimatedButton onClick={goToPayee} name="next" disabled={form.isDisabled} />
				</div>
			</Content>
		</>
	);
};

export default AddExpenseDetail;
