import { get, set, del } from 'idb-keyval';

/**
 * Creates an Indexed DB persister
 * @see https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API
 */
export function createIDBPersister(idbValidKey = 'circles-db') {
	return {
		persistClient: async client => {
			await set(idbValidKey, client);
		},
		restoreClient: async <T,>() => {
			return await get<T>(idbValidKey);
		},
		removeClient: async () => {
			await del(idbValidKey);
		},
	};
}
