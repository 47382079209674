import { Account } from '../models/account';

class AccountService {
	getRefreshToken() {
		const tokenStr = localStorage.getItem('TOKEN');
		if (tokenStr) {
			const tokenObj = JSON.parse(tokenStr) as Account;
			return tokenObj.refreshToken;
		}
		return undefined;
	}

	getAccessToken() {
		const tokenStr = localStorage.getItem('TOKEN');
		if (tokenStr) {
			const tokenObj = JSON.parse(tokenStr) as Account;
			return tokenObj.token;
		}
		return undefined;
	}

	setToken(token: Account) {
		localStorage.setItem('TOKEN', JSON.stringify(token));
	}

	removeToken() {
		localStorage.removeItem('TOKEN');
	}

	getFCMToken() {
		return localStorage.getItem('FCM_TOKEN');
	}

	setFCMToken(token: string) {
		localStorage.setItem('FCM_TOKEN', token);
	}

	setProfileCreated() {
		localStorage.setItem('PROFILE_CREATED', 'true');
	}

	resetProfileCreated() {
		localStorage.removeItem('PROFILE_CREATED');
	}

	isProfileCreated() {
		return localStorage.getItem('PROFILE_CREATED') === 'true';
	}
}

export default new AccountService();
