import React, { useCallback, useEffect, useState } from 'react';
import styles from './date-picker.module.css';
import EssencePickerList from '../core/picker-list/picker-list';
import EssencePickerOverlay from '../core/picker-overlay/picker-overlay';
import { PickerOption } from '../core/model/picker-option';

interface Props {
	date: Date;
	minDate?: Date;
	maxDate?: Date;
	onClose?(): void;
	onAccept?(date: Date): void;
}

interface DatePickerOption extends PickerOption {
	value: number;
}

export default function EssenceDatePicker({ date, minDate, maxDate, onClose, onAccept }: Props) {
	[minDate] = useState<Date>(minDate ? minDate : new Date(1900, 1, 1));
	[maxDate] = useState<Date>(maxDate ? maxDate : new Date(2050, 12, 1));

	const [currentDate, setCurrentDate] = useState<Date>(date);
	const [internalDate, setInternalDate] = useState<Date>(date);
	const [hidePicker, setHidePicker] = useState<boolean>(true);

	// const dayNames = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

	const [dateOption, setDateOption] = useState<DatePickerOption>({ index: 0, title: '', value: 0 });
	const [monthOption, setMonthOption] = useState<DatePickerOption>({
		index: 0,
		title: '',
		subtitle: '',
		value: 0,
	});
	const [yearOption, setYearOption] = useState<DatePickerOption>({ index: 0, title: '', value: 0 });

	const [dates, setDates] = useState<DatePickerOption[]>([]);
	const [months, setMonths] = useState<DatePickerOption[]>([]);
	const [years, setYears] = useState<DatePickerOption[]>([]);

	const accept = () => {
		setHidePicker(true);
		const date = new Date(internalDate.getTime());
		setCurrentDate(date);
		if (onAccept) {
			onAccept(date);
		}
	};

	const reject = () => {
		setHidePicker(true);
		if (onClose) {
			onClose();
		}
	};

	const openDatePicker = (event: React.FocusEvent<HTMLInputElement>) => {
		event.preventDefault();
		setHidePicker(false);
	};

	const _checkdate = useCallback(() => {
		let numberOfDays = 31;
		const thirtyOneDaysMonths = [1, 3, 5, 7, 8, 10, 12];
		const isThirtyOneDaysMonth = thirtyOneDaysMonths.includes(monthOption.value);
		if (isThirtyOneDaysMonth) {
			numberOfDays = 31;
		} else if (monthOption.value == 2 && yearOption.value) {
			if ((yearOption.value % 4 == 0 && yearOption.value % 100 != 0) || yearOption.value % 400 == 0) {
				numberOfDays = 29;
			} else {
				numberOfDays = 28;
			}
		} else {
			numberOfDays = 30;
		}
		const localDates: DatePickerOption[] = [];

		for (let index = 1; index <= numberOfDays; index++) {
			localDates.push({
				index: index - 1,
				title: index.toString(),
				value: index,
			});
		}
		setDates(localDates);
	}, [monthOption, yearOption]);

	const _updateOptions = useCallback((minDate: Date, maxDate: Date) => {
		const years: DatePickerOption[] = [];
		const months: DatePickerOption[] = [];
		for (let index: number = maxDate.getFullYear(); index >= minDate.getFullYear(); index--) {
			years.push({
				index: maxDate.getFullYear() - index,
				title: index.toString(),
				value: index,
			});
		}

		setYears(years);

		const monthNames = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		];

		for (let index = 1; index <= 12; index++) {
			months.push({
				index: index - 1,
				title: index.toString(),
				subtitle: monthNames[index - 1],
				value: index,
			});
		}

		setMonths(months);
	}, []);

	useEffect(() => {
		_updateOptions(minDate, maxDate);
	}, [_updateOptions, maxDate, minDate]);

	useEffect(() => {
		if (months.length && years.length) {
			_checkdate();
		}
	}, [months, years, _checkdate]);

	useEffect(() => {
		if (dates.length && dateOption.value !== dates[internalDate.getDate() - 1]?.value) {
			setDateOption(dates[internalDate.getDate() - 1]);
		}
		if (months.length && monthOption.value !== months[internalDate.getMonth()]?.value) {
			setMonthOption(months[internalDate.getMonth()]);
		}
		if (years.length && yearOption.value !== years[maxDate.getFullYear() - internalDate.getFullYear()]?.value) {
			setYearOption(years[maxDate.getFullYear() - internalDate.getFullYear()]);
		}
	}, [dateOption, dates, internalDate, maxDate, monthOption, months, yearOption, years]);

	const onDateChange = ($event: DatePickerOption) => {
		const date = $event;
		setDateOption(date);
		internalDate.setDate(date.value);
		setInternalDate(internalDate);
	};

	const onMonthChange = ($event: DatePickerOption) => {
		const month = $event;
		internalDate.setMonth(month.index);
		setInternalDate(internalDate);
		setMonthOption(month);
	};

	const onYearChange = ($event: DatePickerOption) => {
		const year = $event;
		internalDate.setFullYear(year.value);
		setInternalDate(internalDate);
		setYearOption(year);
	};

	return (
		<div className={styles.container}>
			<input
				essence-input="true"
				onFocus={openDatePicker}
				value={currentDate.toISOString().replace(/T.*/, '').split('-').reverse().join('-')}
				readOnly
				inputMode="none"
			/>
			{!hidePicker && (
				<EssencePickerOverlay onAccept={accept} onReject={reject}>
					<div className={styles['picker-container']}>
						<EssencePickerList
							id="date-picker-days-id"
							value={dateOption}
							onChange={onDateChange}
							options={dates}></EssencePickerList>

						<EssencePickerList
							id="date-picker-months-id"
							value={monthOption}
							onChange={onMonthChange}
							options={months}></EssencePickerList>

						<EssencePickerList
							id="date-picker-years-id"
							value={yearOption}
							onChange={onYearChange}
							options={years}></EssencePickerList>
					</div>
				</EssencePickerOverlay>
			)}
		</div>
	);
}
