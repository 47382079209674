import Logo from '../logo/page';

interface Props {
	title: string;
	subtitle: string;
}
export default function LeftPane({ title, subtitle }: Props) {
	return (
		<div className="hidden md:flex items-center justify-center flex-col h-screen">
			<Logo size="120px" />
			<h1 className="mt-10 text-5xl">{title}</h1>
			<h4 className="mt-2">{subtitle}</h4>
		</div>
	);
}
