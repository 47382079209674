import { QueryClient } from '@tanstack/react-query';
import { addItinerary, updateItinerary, deleteItinerary } from './api';
import { itineraryKeys } from './keys';
import { Itinerary } from '../../models/itinerary';

export const setDefaultItineraryMutation = (queryClient: QueryClient) => {
	// we need a default mutation function so that paused mutations can resume after a page reload
	queryClient.setMutationDefaults(itineraryKeys.add(), {
		mutationFn: async (itinerary: Itinerary) => {
			// to avoid clashes with our optimistic update when an offline mutation continues
			await queryClient.cancelQueries({
				queryKey: itineraryKeys.add(),
			});
			return await addItinerary(itinerary);
		},
	});

	// we need a default mutation function so that paused mutations can resume after a page reload
	queryClient.setMutationDefaults(itineraryKeys.delete(), {
		mutationFn: async (itinerary: Itinerary) => {
			// to avoid clashes with our optimistic update when an offline mutation continues
			await queryClient.cancelQueries({
				queryKey: itineraryKeys.delete(),
			});
			const itineraryToUpdate = _getCachedItinerary(itinerary);
			return await deleteItinerary(itineraryToUpdate);
		},
	});

	// we need a default mutation function so that paused mutations can resume after a page reload
	queryClient.setMutationDefaults(itineraryKeys.update(), {
		mutationFn: async (itinerary: Itinerary) => {
			// to avoid clashes with our optimistic update when an offline mutation continues
			await queryClient.cancelQueries({
				queryKey: itineraryKeys.update(),
			});
			const itineraryToUpdate = _getCachedItinerary(itinerary);
			return await updateItinerary(itineraryToUpdate);
		},
	});

	const _getCachedItinerary = (itinerary: Itinerary): Itinerary => {
		const itineraries = queryClient.getQueryData<Itinerary[]>(itineraryKeys.list(itinerary.circleId));
		const updatedItinerary: Itinerary[] | undefined = itineraries?.filter(
			x => x.clientId === itinerary.itineraryId,
		);
		return updatedItinerary && updatedItinerary.length > 0 ? updatedItinerary[0] : itinerary;
	};
};
