export interface ThemeColor {
	color: string;
	id: number;
	isSelected: boolean;
}

export class ThemeService {
	loadTheme() {
		const themeClass = this.isDarkMode() ? 'theme-dark' : 'theme-light';
		document.documentElement.classList.add(themeClass);
		const themeColor = this.getColors().find(color => color.isSelected);
		if (themeColor) {
			document.documentElement.setAttribute('style', `--theme-color: ${themeColor.color}`);
		}
	}
	isDarkMode() {
		const themeMode = localStorage.getItem('THEME_MODE');
		if (themeMode) {
			return themeMode === 'dark';
		}

		// By default we're setting the dark mode
		return true;
	}

	setDarkMode() {
		localStorage.setItem('THEME_MODE', 'dark');
	}

	setLightMode() {
		localStorage.setItem('THEME_MODE', 'light');
	}

	getColors(): ThemeColor[] {
		const themeColorsStr = localStorage.getItem('THEME_COLORS');
		let themeColors: ThemeColor[];
		if (!themeColorsStr) {
			themeColors = [
				{ color: 'rgb(115, 200, 255)', id: 0, isSelected: true },
				{ color: 'rgb(255, 109, 106)', id: 1, isSelected: false },
				{ color: 'rgb(239, 190, 125)', id: 2, isSelected: false },
				{ color: 'rgb(177, 162, 202)', id: 3, isSelected: false },
				{ color: 'rgb(82, 206, 156)', id: 4, isSelected: false },
				{ color: 'cornflowerblue', id: 5, isSelected: false },
			];
			localStorage.setItem('THEME_COLORS', JSON.stringify(themeColors));
		} else {
			themeColors = JSON.parse(themeColorsStr) as ThemeColor[];
		}
		return themeColors;
	}

	setColors(themeColors) {
		localStorage.setItem('THEME_COLORS', JSON.stringify(themeColors));
	}
}

export default new ThemeService();
