export const accountKeys = {
	all: () => ['account'],
	update: () => ['account', 'update'],
	sendOtp: () => ['account', 'sendOtp'],
	verifyOtp: () => ['account', 'verifyOtp'],
	logout: () => ['account', 'remove'],
	delete: () => ['account', 'delete'],
};

// export const fcmTokenKeys = {
// 	all: () => ['fcmToken'],
// 	list: () => [...fcmTokenKeys.all(), 'list'],
// };
