import { Reorder } from 'framer-motion';
import TodoRow from '../todo-row/page';
import { Todo } from '../../../../shared/models/todo';

interface Props {
	todos: Todo[];
	showIndex: boolean;
	setTodos: React.Dispatch<React.SetStateAction<Todo[]>>;
	onToggle(option: string): void;
	onRemove(option: string): void;
	onReorder(todos: Todo[]): void;
	onEdit?(todo: Todo, text: string): void;
}
export default function ListTodo({ todos, setTodos, onToggle, onRemove, onReorder, onEdit, showIndex }: Props) {
	return (
		<Reorder.Group axis="y" values={todos} onReorder={setTodos} data-testid="todo-list">
			{todos.map((todo, index) => (
				<TodoRow
					todo={todo}
					showIndex={showIndex}
					index={index}
					key={todo.toDoId}
					onToggle={onToggle}
					onRemove={onRemove}
					onEdit={onEdit}
					onDragEnd={() => {
						onReorder(todos);
					}}
				/>
			))}
		</Reorder.Group>
	);
}
