// The country code and phone number will be formatted as
// +91 1234.567.890.12
export const formattedPhoneNumber = (phoneNumber: string, countryCode?: number) => {
	if (!phoneNumber) {
		return '';
	}
	const trimmedPhoneNumber = phoneNumber.replace(/\D/g, '');
	const formattedCountryCode = countryCode ? `+${countryCode.toString()}` : '';
	const segmentLengths = [4, 3, 3, 3];
	let formattedPhoneNumber = '';
	let index = 0;
	segmentLengths.forEach(segmentLength => {
		const segment = trimmedPhoneNumber.slice(index, index + segmentLength);
		index = index + segmentLength;
		if (segment && segment.length > 0) {
			formattedPhoneNumber += segment + '.';
		}
	});

	formattedPhoneNumber = formattedPhoneNumber.slice(0, formattedPhoneNumber.length - 1);

	const space = formattedCountryCode && formattedPhoneNumber ? ' ' : '';
	return `${formattedCountryCode}${space}${formattedPhoneNumber}`;
};
