import { CSSProperties } from 'react';
import styles from './logo.module.css';

interface CssProps extends CSSProperties {
	'--circle-size': string;
}
export default function Logo({ size }: { size: string }) {
	const style: CssProps = { '--circle-size': size };

	return (
		<div style={style} className={styles.container}>
			<div className={`${styles.circle} ${styles.first}`} />
			<div className={styles.toprow}>
				<div className={styles.circle} />
				<div className={styles.circle} />
			</div>
		</div>
	);
}
