import { QueryClient } from '@tanstack/react-query';
import { addExpense, updateExpense, deleteExpense, AddExpenseRequest } from './api';
import { expensesKeys } from './keys';
import { Expense } from '../../models/expense';

export const setDefaultExpensesMutation = (queryClient: QueryClient) => {
	// we need a default mutation function so that paused mutations can resume after a page reload
	queryClient.setMutationDefaults(expensesKeys.add(), {
		mutationFn: async (expense: AddExpenseRequest) => {
			// to avoid clashes with our optimistic update when an offline mutation continues
			await queryClient.cancelQueries({
				queryKey: expensesKeys.add(),
			});
			return await addExpense(expense);
		},
	});

	// we need a default mutation function so that paused mutations can resume after a page reload
	queryClient.setMutationDefaults(expensesKeys.delete(), {
		mutationFn: async (expense: Expense) => {
			// to avoid clashes with our optimistic update when an offline mutation continues
			await queryClient.cancelQueries({
				queryKey: expensesKeys.delete(),
			});
			const expenseToUpdate = _getCachedExpense(expense);
			await deleteExpense(expenseToUpdate.expenseId);
		},
	});

	// we need a default mutation function so that paused mutations can resume after a page reload
	queryClient.setMutationDefaults(expensesKeys.update(), {
		// TODO: Maybe we want to have a separate interface with circleId over here?
		mutationFn: async (expense: Expense) => {
			// to avoid clashes with our optimistic update when an offline mutation continues
			await queryClient.cancelQueries({
				queryKey: expensesKeys.update(),
			});
			const expenseToUpdate = _getCachedExpense(expense);
			await updateExpense({
				...expenseToUpdate,
				creditors:
					expenseToUpdate.creditors?.map(creditor => ({
						userId: creditor.userId,
						amount: creditor.amount,
					})) ?? [],
				debtors:
					expenseToUpdate.debtors?.map(creditor => ({ userId: creditor.userId, amount: creditor.amount })) ??
					[],
			});
		},
	});

	const _getCachedExpense = (expense: Expense) => {
		const expenses = queryClient.getQueryData<Expense[]>(expensesKeys.list(expense.circleId));
		const updatedExpense = expenses?.filter(x => x.clientId === expense.expenseId);
		if (updatedExpense && updatedExpense.length > 0) {
			return updatedExpense[0];
		}
		return expense;
	};
};
