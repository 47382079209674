export const toDoKeys = {
	all: (circleId: string) => ['todos', circleId],
	list: (circleId: string) => [...toDoKeys.all(circleId), 'list'],
	toggle: () => ['todos', 'toggle'],
	edit: () => ['todos', 'edit'],
	add: () => ['todos', 'add'],
	delete: () => ['todos', 'delete'],
	reorder: () => ['todos', 'reorder'],
	details: (circleId: string) => [...toDoKeys.all(circleId), 'detail'],
	detail: (toDoId: string, circleId: string) => [...toDoKeys.details(circleId), toDoId],
};
