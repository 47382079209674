import { useLocation, useNavigate } from 'react-router-dom';
import styles from './navigation-overlay.module.css';
import { AnimatePresence, motion } from 'framer-motion';
import { Capacitor } from '@capacitor/core';
import { PropsWithChildren } from 'react';

export default function EssenceNavigationOverlay({ children }: PropsWithChildren) {
	const navigate = useNavigate();
	const location = useLocation();

	const landingPaths = ['/secure/circles', '/auth/login'];
	const _shouldShowActionBar = () => {
		return !landingPaths.includes(location.pathname);
	};

	const initial = {
		y: '100px',
	};

	const animate = {
		y: '0px',
		transition: { duration: 0.1 },
	};

	const exit = {
		y: '100px',
		transition: { duration: 0.1 },
	};

	return (
		<>
			{children}
			{Capacitor.getPlatform() === 'ios' && (
				<AnimatePresence mode="wait">
					{_shouldShowActionBar() && (
						<motion.div initial={initial} animate={animate} exit={exit} className={styles['action-bar']}>
							<div
								onClick={() => {
									navigate(-1);
								}}
								className={`fa-solid fa-circle-arrow-left ${styles['action-icon']}`}></div>
						</motion.div>
					)}
				</AnimatePresence>
			)}
		</>
	);
}
