import { useState } from 'react';

export interface Props {
	onChange?(phoneNumber: string): void;
}
export default function InputPhoneNumber(props: Props) {
	const [phoneNumber, setPhoneNumber] = useState('');

	const onChange = ($event: React.ChangeEvent<HTMLInputElement>) => {
		const regex = new RegExp(/^[0-9]{0,12}$/, 'g');
		if (regex.test($event.target.value)) {
			const trimmedPhoneNumber = $event.target.value;
			setPhoneNumber(trimmedPhoneNumber);
			if (props.onChange) {
				props.onChange(trimmedPhoneNumber);
			}
		}
	};

	return (
		<input
			data-testid="input-phone-number"
			type="tel"
			value={phoneNumber}
			onChange={onChange}
			essence-input="true"
			name="phone-number"
		/>
	);
}
