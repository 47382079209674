import { useQueryClient } from '@tanstack/react-query';
import { v4 as uuid } from 'uuid';
import { expensesKeys } from './keys';
import { Expense } from '../../models/expense';
import { OnMutateResponse } from '../models/on-mutate-response';

export const useExpenseMutations = (circleId: string) => {
	const queryClient = useQueryClient();

	const addExpenseMutation = {
		mutationKey: expensesKeys.add(),
		onMutate: async (newExpense: Expense): Promise<OnMutateResponse<Expense[]>> => {
			await queryClient.cancelQueries({
				queryKey: expensesKeys.list(circleId),
			});
			const previousData = queryClient.getQueryData<Expense[]>(expensesKeys.list(circleId)) ?? [];
			const clientId = uuid();

			const updatedExpenses: Expense[] = [
				{
					...newExpense,
					expenseId: clientId,
					clientId,
					syncStatus: 'PENDING_CREATE',
					createdAt: new Date(),
					updatedAt: new Date(),
					// dateTime: newExpense.dateTime.toISOString()
				},
				...previousData,
			];
			//updatedExpenses.sort((a, b) => (new Date(b.dateTime)) - (new Date(a.dateTime)));
			updatedExpenses.sort((a, b) => {
				const dateA = a.dateTime.getTime();
				const dateB = b.dateTime.getTime();

				// Compare dateB and dateA to sort in descending order (latest first)
				return dateB - dateA;
			});

			// Optimistically update to the new value
			queryClient.setQueryData(expensesKeys.list(circleId), updatedExpenses);

			// Return a context object with the snapshotted value
			return { previousData, clientId };
		},
		// If the mutation fails,
		// use the context returned from onMutate to roll back
		onError: (err, newExpense, context: OnMutateResponse<Expense[]>) => {
			queryClient.setQueryData(expensesKeys.list(circleId), context.previousData);
		},
		onSuccess: (data: Expense, variables: Expense, context: OnMutateResponse<Expense[]>) => {
			// Optimistically update to the new value
			if (variables.circleId) {
				queryClient.setQueryData<Expense[]>(expensesKeys.list(variables.circleId), expenses => {
					const updatedExpenses = expenses?.map(expense => {
						return expense.clientId === context.clientId
							? {
									...expense,
									expenseId: data.expenseId,
								}
							: expense;
					});
					return updatedExpenses;
				});
			}
		},
	};

	const deleteExpenseMutation = {
		mutationKey: expensesKeys.delete(),
		onMutate: async (newExpense: Expense): Promise<OnMutateResponse<Expense[]>> => {
			await queryClient.cancelQueries({
				queryKey: expensesKeys.list(circleId),
			});

			// Snapshot the previous value
			const previousData = queryClient.getQueryData<Expense[]>(expensesKeys.list(circleId)) ?? [];

			// Optimistically update to the new value
			queryClient.setQueryData<Expense[]>(expensesKeys.list(circleId), expenses =>
				expenses?.map(expense => {
					if (expense.expenseId === newExpense.expenseId) {
						return {
							...expense,
							deletedAt: new Date(),
							syncStatus: 'PENDING_DELETE',
						};
					} else {
						return expense;
					}
				}),
			);

			// Return a context object with the snapshotted value
			return { previousData };
		},
		// If the mutation fails,
		// use the context returned from onMutate to roll back
		onError: (err, newExpense, context: OnMutateResponse<Expense[]>) => {
			queryClient.setQueryData(expensesKeys.list(circleId), context.previousData);
		},
	};

	const updateExpenseMutation = {
		mutationKey: expensesKeys.update(),
		onMutate: async (variables: Expense): Promise<OnMutateResponse<Expense[]>> => {
			await queryClient.cancelQueries({
				queryKey: expensesKeys.list(circleId),
			});
			// Snapshot the previous value
			const previousData = queryClient.getQueryData<Expense[]>(expensesKeys.list(circleId)) ?? [];

			const filteredExpenses = previousData.filter(x => x.expenseId !== variables.expenseId);

			const updatedExpenses: Expense[] = [
				...filteredExpenses,
				{
					...variables,
					// dateTime: variables.dateTime.toISOString(),
					updatedAt: new Date(),
				},
			];
			//updatedExpenses.sort((a, b) => (new Date(b.dateTime)) - (new Date(a.dateTime )));
			updatedExpenses.sort((a, b) => {
				const dateA = a.dateTime.getTime();
				const dateB = b.dateTime.getTime();

				// Compare dateB and dateA to sort in descending order (latest first)
				return dateB - dateA;
			});

			// Optimistically update to the new value
			queryClient.setQueryData(expensesKeys.list(circleId), updatedExpenses);

			// Return a context object with the snapshotted value
			return { previousData };
		},
	};

	return {
		addExpenseMutation,
		deleteExpenseMutation,
		updateExpenseMutation,
	};
};
