import { useEffect } from 'react';
import { onlineManager } from '@tanstack/react-query';

interface LoaderDisplayParams {
	key: string;
	isLoading: boolean | string;
}
const pendingStatuses = new Map();

export default function useLoaderDisplay({ key, isLoading }: LoaderDisplayParams) {
	useEffect(() => {
		const loader = document.getElementById('loader');
		if (onlineManager.isOnline()) {
			if (isLoading) {
				if (loader && Object.keys(pendingStatuses).length === 0) {
					loader.style.display = 'block';
				}

				pendingStatuses.set(key, isLoading);
			} else {
				pendingStatuses.delete(key);
				if (loader && Object.keys(pendingStatuses).length === 0) {
					loader.style.display = 'none';
				}
			}
		} else {
			if (loader) {
				loader.style.display = 'none';
			}
		}
	}, [key, isLoading]);
}
