import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import Hub from '../../components/essence/hub/hub';
import EssenceAnimatedButton from '../../components/essence/button/animated-button';
import AnimatedParent from '../../components/essence/animated/animated-parent';
import Expenses from '../../screens/expense/page';
import Settings from './components/settings/page';
import { useCircleQueries } from '../../shared/tanstack/circle/queries';
import { useQuery } from '@tanstack/react-query';
import Itinerary from './components/itinerary/page';
import { onlineManager } from '@tanstack/react-query';
import EssenceAlert from '../../components/essence/alert/alert';
import { useEffect, useState } from 'react';
import swipeLeftAnimation from '../../../scroll-left.json';
import styles from '../../screens/circles/my-circles/page.module.css';
import coachMarkManager from '../services/coach-mark';
import storageKeyService from '../../shared/services/storage-key-service';
import { Circle } from '../../shared/models/circle';
import { InvalidCircleIdError } from '../../shared/models/errors/invalid-circle-id-error';
import TodoScreen from '../../screens/todo/page';

export default function CircleDetail() {
	const navigate = useNavigate();
	const [alert, setAlert] = useState({ show: false, message: '' });
	const params = useParams();
	const circleId = params.circleId;

	if (!circleId) {
		throw new InvalidCircleIdError();
	}

	const { circleQuery } = useCircleQueries();
	const queryResult = useQuery(circleQuery(circleId));

	let circle: Circle | undefined;
	if (queryResult.data) {
		circle = queryResult.data;
	}

	const [displayAnimation, setDisplayAnimation] = useState(coachMarkManager.shouldShowCoachMark('circle-details'));
	const [searchParams] = useSearchParams();
	const pageIndex = searchParams.get('index') ? Number(searchParams.get('index')) : 0;
	const storageKey = searchParams.get('token') ?? '';

	useEffect(() => {
		storageKeyService.removeSettlementsExceptKey(storageKey);
	}, [storageKey]);
	const hubItems = circle
		? [
				...circle.interests.map(x => {
					return { title: x };
				}),
				{ title: 'settings' },
			]
		: [];

	useEffect(() => {
		if (!onlineManager.isOnline()) {
			setAlert({
				show: true,
				message: 'Please connect to the internet to get details of your circle.',
			});
		}
	}, []);

	const hideAlert = () => {
		setAlert({
			show: false,
			message: '',
		});
		navigate(-1);
	};

	const onSwipe = () => {
		coachMarkManager.hideCoachMark('circle-details');
		setDisplayAnimation(false);
	};

	useEffect(
		() => () => {
			coachMarkManager.removeGestureListener(onSwipe);
		},
		[],
	);

	useEffect(() => {
		if (displayAnimation) {
			coachMarkManager.addGestureListener({
				listener: onSwipe,
				animation: {
					elementSelector: '#left-swipe-circle-detail',
					lottie: swipeLeftAnimation,
				},
			});
		}
	}, [displayAnimation]);

	return (
		<AnimatedParent>
			{alert.show && <EssenceAlert onOk={hideAlert} okButton="okay" message={alert.message}></EssenceAlert>}
			{circle && (
				<Hub title={circle.name} hubItems={hubItems} index={pageIndex}>
					{hubItems.map(item => (
						<div key={item.title} className="flex flex-col">
							<div className={styles.container}>
								{item.title.toLowerCase() === 'todo' && (
									<TodoScreen circleId={circleId} circleName={circle.name} />
								)}
								{displayAnimation && (
									<div id="left-swipe-circle-detail" className={styles['coach-mark']}></div>
								)}
							</div>

							{item.title.toLowerCase().startsWith('expense') && <Expenses circleId={circleId} />}
							{item.title.toLowerCase().startsWith('itinerary') && <Itinerary circleId={circleId} />}
							{item.title === 'moments' && <EssenceAnimatedButton name="Add Moment" />}
							{item.title === 'settings' && <Settings />}
						</div>
					))}
				</Hub>
			)}
		</AnimatedParent>
	);
}
