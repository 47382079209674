import { Outlet } from 'react-router-dom';
import LeftPane from '../left-pane/left-pane';
import styles from './page.module.css';

interface Props {
	title?: string;
	subtitle?: string;
}
export default function Panel({ title, subtitle }: Props) {
	return (
		<div>
			<div className="grid grid-cols-12">
				<div className="hidden md:block col-span-6">
					<LeftPane
						title={title ? title : "Let's create your first circle"}
						subtitle={
							subtitle ? subtitle : '/ˈsəːkl/ A circle is a group of people sharing common interests.'
						}
					/>
				</div>
				<div
					className={`${styles['outlet-container']} col-span-12 md:col-span-6 flex flex-col h-auto md:h-screen justify-center`}>
					<Outlet />
				</div>
			</div>
		</div>
	);
}
