import { Itinerary } from '../../models/itinerary';
import api from '../../services/api';

export const addItinerary = async (body: Itinerary) => {
	const res = await api.post<Itinerary>(`/api/itinerary`, body);
	return res.data;
};

export const deleteItinerary = async (body: Itinerary) => {
	return await api.delete(`/api/itinerary/${body.itineraryId}/step/${body.stepId}`);
};

export const updateItinerary = async (body: Itinerary) => {
	return await api.put(`/api/itinerary/${body.itineraryId}/step/${body.stepId}`, body);
};
