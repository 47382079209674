// import { motion, useInView } from "framer-motion";
import { useInView } from 'framer-motion';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';

export default function Animated({ children }: PropsWithChildren) {
	const ref = useRef(null);
	const isInView = useInView(ref);
	const [, setWasDisplayed] = useState(false);
	const [, setIsUninitialized] = useState(true);

	useEffect(() => {
		if (isInView) {
			setWasDisplayed(true);
		}
	}, [isInView]);

	useEffect(() => {
		setTimeout(() => {
			setIsUninitialized(false);
			setWasDisplayed(true);
		}, 100);
	}, []);

	// const initial = {
	//   rotateY: "90deg",
	//   rotateX: "10deg",
	//   originX: "-200px",
	// };

	// const animate = {
	//   rotateY: 0,
	//   originX: 0,
	//   rotateX: 0,
	//   originZ: 0,
	//   transition: { duration: 0.1 },
	// };

	// const exit = {
	//   rotateY: "-90deg",
	//   rotateX: "-10deg",
	//   originX: "-200px",
	//   transition: { duration: 0.1 },
	// };

	// const childVariants = {
	//   initial,
	//   animate,
	//   exit,
	// };

	// const displayedChildVariants = {
	//   initial: {
	//     rotateY: 0,
	//     originX: 0,
	//     rotateX: 0,
	//     originZ: 0,
	//   },
	//   animate: {
	//     rotateY: 0,
	//     originX: 0,
	//     rotateX: 0,
	//     originZ: 0,
	//     transition: { duration: 0 },
	//   },
	//   exit: {
	//     rotateY: "-90deg",
	//     rotateX: "-10deg",
	//     originX: "-200px",
	//     transition: { duration: 0.2 },
	//   },
	// };

	return <> {children}</>;
	// return (
	//   <div ref={ref}>
	//     {(isInView || isUninitialized) && (
	//       <motion.div
	//         variants={wasDisplayed ? displayedChildVariants : childVariants}
	//       >
	//         {children}
	//       </motion.div>
	//     )}

	//     {!isInView && !isUninitialized && <div>{children}</div>}
	//   </div>
	// );
}
