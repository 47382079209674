import { Todo } from '../../models/todo';
import api from '../../services/api';

export interface AddTodoRequest {
	circleId: string;
	name: string;
	description: string;
	dueDateTime: Date;
}

export interface EditTodoRequest {
	toDoId: string;
	name: string;
}

export const addTodo = async (todo: AddTodoRequest) => {
	const res = await api.post<Todo>(`/api/todo`, todo);
	return res.data;
};

export const deleteTodo = async (toDoId: string) => {
	await api.delete(`/api/todo`, { data: [toDoId] });
};

export const reorderTodo = async (todos: Todo[]) => {
	await api.put(`/api/todo/reorder`, todos);
};

export const toggleTodo = async (toDoId: string) => {
	await api.put(`/api/todo/${toDoId}/ToggleStatus`, {});
};

export const editTodo = async (request: EditTodoRequest) => {
	await api.put(`/api/todo/${request.toDoId}`, { name: request.name });
};
