import { Contacts } from '@capacitor-community/contacts';
import { Capacitor } from '@capacitor/core';
import api from './api';
import { del, get, set } from 'idb-keyval';
import { Participant } from '../models/participant';
import { ContactPayload } from '@capacitor-community/contacts';
import { parsePhoneNumber, PhoneNumber } from 'libphonenumber-js';

export interface Contact {
	userId?: string;
	name: string;
	phoneNumber: string;
	countryCode: number;
	lastFourNumber?: string;
	isChecked?: boolean;
}

export interface Difference {
	created: Contact[];
	deleted: Contact[];
	updated: Contact[];
	final: Contact[];
}

const ARRAY_ALL_CONTACTS = 'ARRAY_ALL_CONTACTS';

let _localContacts: Contact[] = [];

get<Contact[]>(ARRAY_ALL_CONTACTS)
	.then(contacts => {
		_localContacts = contacts ?? [];
		return contacts;
	})
	.catch(() => {
		// Do nothing
	});

// Trie is useful for searching
// Unfortunately, the trie library doesn't allow to list all the nodes or to update a node.
// Hence a list of _localContacts array needs to be kept around for access to all contacts
// & also updating the trie.

const _makeUnion = async (contacts: Contact[]) => {
	if (contacts.length === 0) {
		return;
	}
	if (_localContacts.length == 0) {
		_localContacts = contacts;
	} else {
		_localContacts = _updateFromArray(_localContacts, contacts);
	}

	// Sort the storedContacts
	_localContacts = _localContacts.sort((a, b) =>
		(a.name.toLocaleLowerCase() || '').localeCompare(b.name.toLocaleLowerCase() || ''),
	);

	await set(ARRAY_ALL_CONTACTS, _localContacts);
};

const _isContactsAvailable = () => {
	if (Capacitor.isNativePlatform() && Capacitor.isPluginAvailable('Contacts')) {
		return true;
	}
	return false;
};

// Updates the oldArray with values from the newArray
// The phoneNumber is the primary key for matching
// It will only update the userId and not the name
const _updateFromArray = (oldArray: Contact[], newArray: Contact[]): Contact[] => {
	return oldArray.map(contact => {
		const matchedContact = newArray.find(
			newContact =>
				newContact.phoneNumber === contact.phoneNumber && newContact.countryCode === contact.countryCode,
		);
		if (matchedContact) {
			return {
				...matchedContact,
				name: contact.name,
			};
		}

		const userIdContact = newArray.find(newContact => newContact.userId === contact.userId);
		if (userIdContact) {
			return {
				...userIdContact,
				name: contact.name,
			};
		}

		return { ...contact };
	});
};

export const getPhoneContacts = async () => {
	if (!_isContactsAvailable()) {
		throw new Error('Capacitor Contacts are not supported!');
	}
	const projection = {
		// Specify which fields should be retrieved.
		name: true,
		phones: true,
	};

	// Convert the projection to an array of phone numbers
	const { contacts } = await Contacts.getContacts({
		projection,
	});
	return contacts;
};

/**  Converts the ContactPayload array to Contact array */
export const getFormattedContacts = (contactsPayload: ContactPayload[]): Contact[] => {
	const uniqueContacts = new Map<string, Contact>();

	contactsPayload.forEach(contact => {
		const formattedPhones = contact.phones;
		formattedPhones?.forEach(phone => {
			if (phone.number) {
				let phoneNumber = phone.number.replace(/\D/g, '').replace(/0*/, '');
				if (phoneNumber.length > 10) {
					phoneNumber = `+${phoneNumber}`;
				}
				let parsedPhoneNumber: PhoneNumber | undefined;
				try {
					parsedPhoneNumber = parsePhoneNumber(phoneNumber, 'IN');
				} catch {
					parsedPhoneNumber = parsePhoneNumber(phone.number, 'IN');
				}
				const updatedPhone = parsedPhoneNumber.nationalNumber;
				if (updatedPhone) {
					const countryCode = +parsedPhoneNumber.countryCallingCode;
					const existingContact = uniqueContacts.get(updatedPhone);

					if (!existingContact) {
						const name = contact.name?.display ?? '(No Name)';
						const newContact = {
							name,
							phoneNumber: updatedPhone, // Each object has a single phone number
							countryCode: countryCode,
						};
						uniqueContacts.set(updatedPhone, newContact);
					} else if (
						contact.name?.display &&
						existingContact.name.toLocaleLowerCase() !== contact.name.display.toLowerCase()
					) {
						// If there are multiple contacts with same phone but different names, append the names
						const updatedName = `${existingContact.name}, ${contact.name.display}`;
						uniqueContacts.set(updatedPhone, {
							...existingContact,
							name: updatedName,
						});
					}
				}
			}
		});
	});

	const contacts = Array.from(uniqueContacts.values());

	// Sort the contacts
	return contacts.sort((a, b) => (a.name.toLocaleLowerCase() || '').localeCompare(b.name.toLocaleLowerCase() || ''));
};

/** This method assumes that oldArray and newArray both are sorted with unique phoneNumbers in each array.
	The function returns the difference between two arrays:
	The primary key is the phone number for the basis of comparison
	It tells how many contacts were created, updated or deleted in the newArray compared to oldArray
 */
export const findDifference = (oldArray: Contact[], newArray: Contact[]): Difference => {
	if (oldArray.length === 0) {
		return { created: newArray, deleted: [], updated: [], final: newArray };
	}

	if (newArray.length === 0) {
		return { created: [], deleted: oldArray, updated: [], final: oldArray };
	}
	// Take two index/counters to keep track of stored vs retrieved contacts.
	let storedIndex = 0;
	let retrievedIndex = 0;
	const created: Contact[] = [];
	const updated: Contact[] = [];
	const deleted: Contact[] = [];
	const final: Contact[] = [];

	while (retrievedIndex < newArray.length || storedIndex < oldArray.length) {
		if (oldArray.length <= storedIndex) {
			const createdItems = newArray.slice(retrievedIndex, newArray.length);
			created.push(...createdItems);
			final.push(...createdItems);
			break;
		}

		if (newArray.length <= retrievedIndex) {
			const deletedItems = oldArray.slice(storedIndex, oldArray.length);
			deleted.push(...deletedItems);
			break;
		}
		// Get the contact from the CB
		const retrievedContact = newArray[retrievedIndex];

		// Get the contact from the local storage
		const storedContact = oldArray[storedIndex];
		const storedName = storedContact.name.toLowerCase();
		const retrievedName = retrievedContact.name.toLowerCase();

		if (storedName === '' && retrievedName !== '') {
			// Some contact was created int the contact book.
			// created.push({ ...retrievedContact });
			storedIndex++;
			// skip it
			// retrievedIndex++;
		} else if (retrievedName === storedName && retrievedContact.phoneNumber == storedContact.phoneNumber) {
			// If the contacts are same simply increment the index
			storedIndex++;
			retrievedIndex++;
			final.push(retrievedContact);
		} else if (retrievedName !== storedName && retrievedContact.phoneNumber == storedContact.phoneNumber) {
			final.push(retrievedContact);
			// If the contact name has updated but not the phone number,
			// update it on the server if present in the userId mapping
			updated.push({ ...retrievedContact });
			storedIndex++;
			retrievedIndex++;
		} else if (retrievedName === storedName && retrievedContact.phoneNumber !== storedContact.phoneNumber) {
			final.push(retrievedContact);
			// If the contact name has updated but not the phone number,
			// update it on the server if present in the userId mapping
			created.push({ ...retrievedContact });
			storedIndex++;
			retrievedIndex++;
		} else if (retrievedName > storedName) {
			// Some contact was deleted from the contact book.
			deleted.push({ ...retrievedContact });
			storedIndex++;
		} else if (retrievedName < storedName) {
			final.push(retrievedContact);
			// Some contact was created int the contact book.
			created.push({ ...retrievedContact });
			retrievedIndex++;
		}
	}
	return { created, updated, deleted, final };
};

export const syncContactsWithServer = async (difference: Difference): Promise<Contact[]> => {
	if (difference.created.length > 0 || difference.updated.length > 0 || difference.deleted.length > 0) {
		const response = await api.patch<Contact[]>(`/api/Account/Contact`, {
			upsert: [...difference.created, ...difference.updated],
			delete: [...difference.deleted],
		});

		const serverContacts = response.data;
		return serverContacts;
	}
	return Promise.resolve([]);
};

export const getContactsFromServer = async () => {
	const response = await api.get<Contact[]>(`api/Account/Contact`);
	await _makeUnion(response.data);
};

export const addParticipants = async (participants: Participant[]) => {
	const contacts = participants.map(participant => ({
		userId: participant.userId,
		name: participant.name ?? '(No Name)',
		phoneNumber: participant.phoneNumber ?? '',
		countryCode: participant.countryCode ?? 0,
		lastFourNumber: participant.lastFourNumber,
	}));
	await _makeUnion(contacts);
};

export const addContacts = async (contacts: Contact[]) => {
	await _makeUnion(contacts);
};

export const searchContacts = (search?: string): Contact[] => {
	if (!search) {
		return _localContacts;
	}
	return _localContacts;
};

export const clearContacts = async () => {
	await del(ARRAY_ALL_CONTACTS);
	_localContacts.length = 0;
};
