import EssenceAnimatedButton from '../../../../../components/essence/button/animated-button';
import Header from '../../../../../shared/components/header/page';
import Content from '../../../../../shared/components/content/page';
import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import accountService from '../../../../../shared/services/account-service';
import { jwtDecode } from 'jwt-decode';
import getMemberType from '../../../../../secure/circle-details/member-type';
import { useCircleQueries } from '../../../../../shared/tanstack/circle/queries';
import { useQuery } from '@tanstack/react-query';
import storageKeyService from '../../../../../shared/services/storage-key-service';
import ParticipantsList from '../participant-list/page';
import { Account } from '../../../../../shared/models/account';
import { InvalidCircleIdError } from '../../../../../shared/models/errors/invalid-circle-id-error';
import { Expense } from '../../../../../shared/models/expense';
import { InvalidExpenseError } from '../../../../../shared/models/errors/inavlid-expense-error';
import WarningMessage from '../warning-message/page';
import { ExpenseUser } from '../../../../../shared/models/expense-user';

interface LocationState {
	storageKey: string;
	expenseDetail: Expense;
}

const Payee = () => {
	const { circleId } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const { circleQuery, participantsQuery } = useCircleQueries();
	if (!circleId) {
		throw new InvalidCircleIdError();
	}
	const { data: circle } = useQuery(circleQuery(circleId));
	const { data: circleParticipants } = useQuery(participantsQuery(circleId));
	const storageKey = (location.state as LocationState).storageKey;
	const expenseDetail = storageKeyService.getExpenseForKey(storageKey)
		? storageKeyService.getExpenseForKey(storageKey)
		: (location.state as LocationState).expenseDetail;

	if (!expenseDetail) {
		throw new InvalidExpenseError();
	}

	const [participants, setParticipants] = useState<ExpenseUser[]>();
	let member: string | undefined;
	if (circle) {
		const memberType = getMemberType(circle.type);
		if (memberType) {
			member = memberType.pluralTitle;
		}
	}

	useEffect(() => {
		const accessToken = accountService.getAccessToken();
		let loggedInUserId: string;
		if (accessToken) {
			loggedInUserId = jwtDecode<Account>(accessToken).userId;
		}
		if (circleParticipants && accessToken) {
			const updatedData = circleParticipants.map(participant => {
				const isChecked = expenseDetail.creditors
					? expenseDetail.creditors.some(creditor => creditor.userId === participant.userId)
					: participant.userId === loggedInUserId;
				const creditors = expenseDetail.creditors?.find(creditor => creditor.userId === participant.userId);
				const newAmount = creditors ? creditors.amount : isChecked ? expenseDetail.amount : 0;
				return {
					...participant,
					isChecked,
					amount: newAmount,
				};
			});
			setParticipants(updatedData);
		}
	}, [circleParticipants, expenseDetail.amount, expenseDetail.creditors]);

	const debitAmount = participants?.reduce((sum, participant) => sum + (participant.amount || 0), 0) ?? 0;

	const goToParticipants = () => {
		const creditors = participants?.filter(participant => participant.amount > 0);
		expenseDetail.creditors = creditors;
		storageKeyService.setExpenseForKey(storageKey, expenseDetail);
		navigate(`/secure/circles/${circleId}/add-expense/participants`, {
			state: { expenseDetail },
		});
	};

	const form = {
		get isDisabled() {
			return expenseDetail.amount - debitAmount !== 0 ? true : undefined;
		},
	};

	return (
		<>
			{member && <Header title="Paid by" subtitle={`Select the list of ${member} who paid for the expense.`} />}
			<Content>
				<ul>
					{participants && (
						<ParticipantsList circleParticipants={participants} expenseDetail={expenseDetail} />
					)}
					{form.isDisabled && <WarningMessage amount1={expenseDetail.amount} amount2={debitAmount} />}
					<EssenceAnimatedButton onClick={goToParticipants} name="next" disabled={form.isDisabled} />
				</ul>
			</Content>
		</>
	);
};

export default Payee;
