import { useState } from 'react';
import styles from './page.module.css';

interface Props {
	onCodeEntered?(verificationCode: string): void;
}
export default function InputVerificationCode(props: Props) {
	const [verificationCode, setVerificationCode] = useState('');

	const onChange = ($event: React.ChangeEvent<HTMLInputElement>) => {
		const regex = new RegExp(/^[0-9]{0,6}$/, 'g');
		if (regex.test($event.target.value)) {
			const trimmedCode = $event.target.value;
			setVerificationCode(trimmedCode);
			if (props.onCodeEntered && trimmedCode.length === 6) {
				props.onCodeEntered(trimmedCode);
			}
		}
	};

	return (
		<input
			data-testid="input-verification-code"
			type="tel"
			className={styles['code-input']}
			value={verificationCode}
			onChange={onChange}
			essence-input="true"
			name="verification-code"
		/>
	);
}
