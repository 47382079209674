import { QueryClient } from '@tanstack/react-query';
import { setDefaultAccountMutation } from './account/default-mutations';
import { setDefaultCircleMutations } from './circle/default-mutations';
import { setDefaultExpensesMutation } from './expenses/default-mutations';
import { setDefaultItineraryMutation } from './itinerary/default-mutations';
import { setDefaultTodosMutation } from './todos/default-mutations';

export const setDefaultMutations = (queryClient: QueryClient) => {
	setDefaultAccountMutation(queryClient);
	setDefaultCircleMutations(queryClient);
	setDefaultTodosMutation(queryClient);
	setDefaultExpensesMutation(queryClient);
	setDefaultItineraryMutation(queryClient);
};
