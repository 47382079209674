import Header from '../../../../shared/components/header/page';
import Content from '../../../../shared/components/content/page';
import ThemeColorList from './components/theme-color-list/page';
import ToggleTheme from './components/toggle-theme/page';
import themeService from '../../../../shared/services/theme-service';

export default function Theme() {
	return (
		<>
			<Header title="Theme" subtitle="customise the theme to your liking" />
			<Content>
				<>
					<p className="headline-5">Dark Mode</p>
					<ToggleTheme themeService={themeService} />
					<p className="headline-5 mt-6">Color</p>
					<div className="flex gap-3 mt-3">
						<ThemeColorList themeService={themeService} />
					</div>
				</>
			</Content>
		</>
	);
}
