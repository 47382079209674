import { useQueryClient } from '@tanstack/react-query';
import { v4 as uuid } from 'uuid';
import { circlesKeys } from './keys';
import { Circle } from '../../models/circle';
import { Participant } from '../../models/participant';
import { OnMutateResponse } from '../models/on-mutate-response';
import { AddCircleRequest, ParticipantsRequest } from './api';

//Check todo mutation file and queries.tsx
export const useCircleMutations = () => {
	const queryClient = useQueryClient();

	const addCircleMutation = {
		mutationKey: circlesKeys.add(),
		onMutate: async (newCircle: AddCircleRequest): Promise<OnMutateResponse<Circle[]>> => {
			await queryClient.cancelQueries({ queryKey: circlesKeys.list() });
			// Snapshot the previous value
			const previousData = queryClient.getQueryData<Circle[]>(circlesKeys.list()) ?? [];

			const clientId = uuid();
			const oldCircles = previousData;
			// Optimistically update to the new value
			queryClient.setQueryData(circlesKeys.list(), () => [
				...oldCircles,
				{
					...newCircle,
					circleId: clientId,
					clientId,
					syncStatus: 'PENDING_CREATE',
					createdAt: new Date(),
					updatedAt: new Date(),
				},
			]);

			// Return a context object with the snapshotted value
			return { previousData, clientId };
		},
		// If the mutation fails,
		// use the context returned from onMutate to roll back
		onError: (_err, _newCircle, context: OnMutateResponse<Circle[]>) => {
			queryClient.setQueryData(circlesKeys.list(), context.previousData);
		},
		onSuccess: (data: Circle, variables, context: OnMutateResponse<Circle[]>) => {
			// Update the id to the one returned from server
			queryClient.setQueryData<Circle[]>(circlesKeys.list(), (circles: Circle[]) => {
				const updatedCircles: Circle[] = circles.map((circle: Circle) => {
					return circle.clientId === context.clientId
						? {
								...circle,
								circleId: data.circleId,
							}
						: circle;
				});
				return updatedCircles;
			});
		},
	};

	const deleteCircleMutation = {
		mutationKey: circlesKeys.delete(),
		onMutate: async (newCircle: Circle): Promise<OnMutateResponse<Circle[]>> => {
			await queryClient.cancelQueries({ queryKey: circlesKeys.list() });
			// Snapshot the previous value
			const previousData = queryClient.getQueryData<Circle[]>(circlesKeys.list()) ?? [];

			// Optimistically update to the new value
			queryClient.setQueryData(circlesKeys.list(), (circles: Circle[]) =>
				circles.map((circle: Circle) => {
					if (circle.circleId === newCircle.circleId) {
						return {
							...circle,
							deletedAt: new Date(),
							syncStatus: 'PENDING_DELETE',
						};
					} else {
						return circle;
					}
				}),
			);

			// Return a context object with the snapshotted value
			return { previousData };
		},
		// If the mutation fails,
		// use the context returned from onMutate to roll back
		onError: (_err, _newExpense, context: OnMutateResponse<Circle[]>) => {
			queryClient.setQueryData(circlesKeys.list(), context.previousData);
		},
	};

	const addParticipantsMutation = {
		mutationKey: circlesKeys.updateParticipants(),
		onMutate: async ({ contacts, circleId }: ParticipantsRequest): Promise<OnMutateResponse<Participant[]>> => {
			await queryClient.cancelQueries({
				queryKey: circlesKeys.participants(circleId),
			});
			// Snapshot the previous value
			const previousData = queryClient.getQueryData<Participant[]>(circlesKeys.participants(circleId)) ?? [];

			const updateParticipants = contacts.map(participant => ({
				...participant,
				updatedAt: new Date(),
				syncStatus: 'PENDING_CREATE',
			}));

			// Optimistically update to the new value
			queryClient.setQueryData<Participant[]>(circlesKeys.participants(circleId), (old: Participant[]) => {
				return [...old, ...updateParticipants];
			});

			// Return a context object with the snapshotted value
			return { previousData };
		},
	};

	const deleteParticipantsMutation = {
		mutationKey: circlesKeys.removeParticipants(),
		onMutate: async ({ circleId, contacts }: ParticipantsRequest): Promise<OnMutateResponse<Participant[]>> => {
			await queryClient.cancelQueries({
				queryKey: circlesKeys.participants(circleId),
			});
			// Snapshot the previous value
			const previousData = queryClient.getQueryData<Participant[]>(circlesKeys.participants(circleId)) ?? [];

			// Optimistically update to the new value
			queryClient.setQueryData<Participant[]>(circlesKeys.participants(circleId), (participants: Participant[]) =>
				participants.map(participant => {
					if (contacts.some(contact => contact.userId === participant.userId)) {
						return {
							...participant,
							deletedAt: new Date(),
							syncStatus: 'PENDING_DELETE',
						};
					} else {
						return participant;
					}
				}),
			);

			// Return a context object with the snapshotted value
			return { previousData };
		},
	};

	return {
		addCircleMutation,
		deleteCircleMutation,
		addParticipantsMutation,
		deleteParticipantsMutation,
	};
};
