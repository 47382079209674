import { CSSProperties } from 'react';
import Clickable from '../core/clickable';
import styles from './checkbox.module.css';

interface CheckBoxCSSProperties extends CSSProperties {
	'--theme-color'?: string;
}
export interface Props extends React.ComponentProps<'input'> {
	title: string;
	subTitle?: string;
	onActionClick?: React.MouseEventHandler<HTMLDivElement>;
	actionIcon?: string;
	checkBoxColor?: string;
	testId?: string;
	editable?: boolean;
	onEdit?(text: string): void;
	showIndex?: boolean;
	index?: number;
}
export default function EssenceCheckbox({
	title,
	subTitle,
	onActionClick,
	actionIcon,
	checkBoxColor,
	testId,
	editable,
	onEdit,
	showIndex,
	index,
	...props
}: Props) {
	const onAction: React.MouseEventHandler<HTMLDivElement> = $event => {
		$event.preventDefault();
		if (onActionClick) {
			onActionClick($event);
		}
	};

	return (
		<div className={styles['checkbox-container']}>
			<div>
				<Clickable disabled={props.disabled}>
					<label
						className={`${subTitle ? styles['checkbox-subtitle'] : ''} ${
							styles.checkbox
						} ${props.className ?? ''} ${props.disabled ? styles['checkbox-disabled'] : ''}`}>
						<input type="checkbox" data-testid={`essence-checkbox-input-${testId ?? ''}`} {...props} />
						<div
							className={`${subTitle ? styles['checkmark-subtitle'] : ''} ${styles.checkmark}`}
							style={{ '--theme-color': checkBoxColor } as CheckBoxCSSProperties}></div>

						{editable ? (
							<></>
						) : (
							<div className={styles.content}>
								<div className="subtitle-1">
									{showIndex ? `${((index ?? 0) + 1).toString()}. ${title}` : title}
								</div>
								{subTitle && <div className="caption">({subTitle})</div>}
							</div>
						)}
					</label>
				</Clickable>
			</div>
			{editable ? (
				<div className={`flex flex-row items-center justify-start gap-2 subtitle-1 ${styles.content}`}>
					<div>{showIndex ? `${((index ?? 0) + 1).toString()}. ` : ''}</div>
					<div
						onKeyDown={e => {
							if (e.key === 'Enter') {
								e.preventDefault();
								e.currentTarget.blur();
							}
						}}
						contentEditable={editable}
						onBlur={e => {
							if (onEdit) {
								onEdit(e.currentTarget.textContent ?? title);
							}
						}}>
						{title}
					</div>
					{subTitle && <div className="caption">({subTitle})</div>}
				</div>
			) : (
				<></>
			)}
			<div
				className={`${styles['action-icon']} ${actionIcon ?? ''} px-3 ms-5`}
				onClick={onAction}
				data-testid={`essence-checkbox-delete-${testId ?? ''}`}></div>
		</div>
	);
}
