import { circlesKeys } from './keys';
import {
	addCircle,
	AddCircleRequest,
	addParticipants,
	ParticipantsRequest,
	deleteCircle,
	removeParticipants,
	ContactRequest,
} from './api';
import { QueryClient } from '@tanstack/react-query';
import { de } from '@faker-js/faker';
import { Circle } from '../../models/circle';

export const setDefaultCircleMutations = (queryClient: QueryClient) => {
	// we need a default mutation function so that paused mutations can resume after a page reload
	queryClient.setMutationDefaults(circlesKeys.add(), {
		mutationFn: async (circle: AddCircleRequest) => {
			// to avoid clashes with our optimistic update when an offline mutation continues
			await queryClient.cancelQueries({
				queryKey: circlesKeys.add(),
			});
			return await addCircle(circle);
		},
	});

	// we need a default mutation function so that paused mutations can resume after a page reload
	queryClient.setMutationDefaults(circlesKeys.delete(), {
		mutationFn: async (circle: Circle) => {
			// to avoid clashes with our optimistic update when an offline mutation continues
			await queryClient.cancelQueries({
				queryKey: circlesKeys.delete(),
			});
			await deleteCircle(circle.circleId);
		},
	});

	// we need a default mutation function so that paused mutations can resume after a page reload
	queryClient.setMutationDefaults(circlesKeys.updateParticipants(), {
		mutationFn: async (participant: ParticipantsRequest) => {
			// to avoid clashes with our optimistic update when an offline mutation continues
			await queryClient.cancelQueries({
				queryKey: circlesKeys.updateParticipants(),
			});
			return await addParticipants(participant);
		},
	});

	// we need a default mutation function so that paused mutations can resume after a page reload
	queryClient.setMutationDefaults(circlesKeys.removeParticipants(), {
		mutationFn: async ({ circleId, contacts }: ParticipantsRequest) => {
			// to avoid clashes with our optimistic update when an offline mutation continues
			await queryClient.cancelQueries({
				queryKey: circlesKeys.updateParticipants(),
			});

			const userIds = contacts.map((participant: ContactRequest) => participant.userId);
			return await removeParticipants(circleId, userIds[0]);
		},
	});
};
