import { AxiosResponse } from 'axios';
import { Circle } from '../../models/circle';
import api from '../../services/api';

export interface AddCircleRequest {
	type: string;
	name: string;
	interests: string[];
}

export const addCircle = async (body: AddCircleRequest) => {
	return await api.post<Circle>(`/api/circle`, body).then(res => res.data);
};

export const deleteCircle = async (circleId: string) => {
	await api.delete(`/api/Circle/${circleId}`, {});
};

export interface ParticipantsRequest {
	circleId: string;
	contacts: ContactRequest[];
}
export interface ContactRequest {
	userId: string;
	countryCode: number;
	phoneNumber: string;
}
export const addParticipants = async ({ circleId, contacts }: ParticipantsRequest) => {
	if (circleId) {
		const res: AxiosResponse<null> = await api.put(`/api/circle/${circleId}/participant`, contacts);
		return res.data;
	}
};

export const removeParticipants = async (circleId: string, participantId: string) => {
	if (circleId) {
		const res: AxiosResponse<null> = await api.delete(`/api/circle/${circleId}/participant/${participantId}`);
		return res.data;
	}
};
