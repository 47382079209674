import Clickable from '../core/clickable';
import styles from './row.module.css';

interface Props {
	title: string;
	actionText?: string;
	subTitle?: string;
}

export default function EssenceRow({ title, actionText, subTitle, ...props }: Props) {
	return (
		<Clickable {...props}>
			<div className={styles['essence-row']}>
				<div>
					<div className="headline-4">{title}</div>
					{subTitle && (
						<div
							className="caption"
							dangerouslySetInnerHTML={{
								__html: subTitle,
							}}></div>
					)}
				</div>
				<div>{actionText && <div className="headline-4 text-right">{actionText}</div>}</div>
			</div>
		</Clickable>
	);
}
