import Clickable from '../../../../components/essence/core/clickable';
import Animated from '../../../../components/essence/animated/animated';
import { useNavigate } from 'react-router-dom';
import getMemberType from '../../member-type';
import { useParams } from 'react-router-dom';
import { useCircleQueries } from '../../../../shared/tanstack/circle/queries';
import { useCircleMutations } from '../../../../shared/tanstack/circle/mutations';
import { useQuery, useMutation } from '@tanstack/react-query';
import { Capacitor } from '@capacitor/core';
import { InvalidCircleIdError } from '../../../../shared/models/errors/invalid-circle-id-error';

const Settings = () => {
	const navigate = useNavigate();
	const { circleId } = useParams();
	if (!circleId) {
		throw new InvalidCircleIdError();
	}
	const { circleQuery } = useCircleQueries();
	const { data: circle } = useQuery(circleQuery(circleId));

	let pluralTitle: string | undefined;
	const circleType = circle?.type;
	if (circleType) {
		pluralTitle = getMemberType(circleType)?.pluralTitle;
	}

	const { deleteCircleMutation } = useCircleMutations();
	const { mutate: deleteCircle } = useMutation(deleteCircleMutation);

	const goToAddparticipants = () => {
		if (Capacitor.isNativePlatform()) {
			navigate(`/secure/circles/${circleId}/participants`);
		} else {
			navigate(`/secure/circles/${circleId}/participants`);
		}
	};

	// const goToModules = () => {
	//   navigate(`/secure/onboarding/circle-interests`, {
	//     state: { context: "Toggle_Module" },
	//   });
	// };

	const handleRemoveCircle = () => {
		deleteCircle({ circleId });
		navigate(`/secure/circles`);
	};

	return (
		<>
			<Animated>
				<Clickable key="participants">
					{pluralTitle && (
						<div className="headline-4 lowercase" onClick={goToAddparticipants}>
							{pluralTitle}
						</div>
					)}
				</Clickable>
			</Animated>
			{/* <Clickable key="modules">
        <Animated>
          <div className="headline-4 lowercase" onClick={goToModules}>
            modules
          </div>
        </Animated>
      </Clickable> */}
			<Animated>
				<Clickable key="exit">
					<div className="headline-4 lowercase" style={{ color: 'red' }} onClick={handleRemoveCircle}>
						exit
					</div>
				</Clickable>
			</Animated>
		</>
	);
};

export default Settings;
