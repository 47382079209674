import { useState } from 'react';
import Animated from '../../../../components/essence/animated/animated';
import Clickable from '../../../../components/essence/core/clickable';
import styles from './page.module.css';
import EssenceAlert from '../../../../components/essence/alert/alert';

interface Props {
	onDelete(): void;
}
export default function DeleteAccount({ onDelete }: Props) {
	const [showDeleteAlert, setShowDeleteAlert] = useState(false);

	return (
		<>
			{showDeleteAlert && (
				<EssenceAlert
					onOk={() => {
						setShowDeleteAlert(false);
						onDelete();
					}}
					onDismiss={() => {
						setShowDeleteAlert(false);
					}}
					okButton="delete"
					dismissButton="don't delete"
					message={
						'Deleting account will delete all your circles along with all other data. Are you sure?'
					}></EssenceAlert>
			)}
			<div className={styles['delete-btn']}>
				<Animated>
					<Clickable key="delete">
						<div
							data-testid="btn-delete-account"
							className="headline-4 lowercase"
							style={{ color: 'red' }}
							onClick={() => {
								setShowDeleteAlert(true);
							}}>
							delete account
						</div>
					</Clickable>
				</Animated>
			</div>
		</>
	);
}
