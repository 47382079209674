import { useState } from 'react';
import EssenceButton from '../../../../components/essence/button/button';

interface Props {
	name?: string;
	buttonText: string;
	onSave(name: string): void;
}
export default function SaveProfileForm({ name, onSave, buttonText }: Props) {
	const [userName, setUserName] = useState(name ?? '');

	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				onSave(userName);
			}}>
			<p className="headline-5">Name</p>
			<input
				data-testid="input-user-name"
				essence-input="true"
				value={userName}
				onChange={e => {
					setUserName(e.target.value);
				}}
			/>
			<EssenceButton
				disabled={userName.length === 0}
				data-testid="btn-save-profile"
				type="submit"
				name={buttonText}
			/>
		</form>
	);
}
