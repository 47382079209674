import { useState } from 'react';
import EssenceButton from '../../../../components/essence/button/button';

interface Props {
	onCreate(name: string): void;
}
export default function AddCircleForm({ onCreate }: Props) {
	const [circleName, setCircleName] = useState('');

	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				onCreate(circleName);
			}}>
			<p className="headline-4">Name</p>
			<input
				data-testid="input-circle-name"
				essence-input="true"
				name="circle-name"
				onChange={e => {
					setCircleName(e.target.value);
				}}
			/>
			<div className="mb-6"></div>
			<EssenceButton
				data-testid="btn-add-circle"
				disabled={circleName.length === 0}
				type="submit"
				name="add circle"
			/>
		</form>
	);
}
