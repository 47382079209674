import React, { useEffect, useState } from 'react';
import EssenceAnimatedCheckbox from '../../../components/essence/checkbox/animated-checkbox';
import EssenceButton from '../../../components/essence/button/button';
import Header from '../../../shared/components/header/page';
import Content from '../../../shared/components/content/page';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useCircleMutations } from '../../../shared/tanstack/circle/mutations';
import { useMutation } from '@tanstack/react-query';
import { AddCircleRequest } from '../../../shared/tanstack/circle/api';

interface Interest {
	displayName: string;
	value: string;
	disabled: boolean;
}

interface LocationState {
	context: string;
}

export default function CircleInterests() {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const { addCircleMutation } = useCircleMutations();
	const { mutate: addCircle, isSuccess } = useMutation(addCircleMutation);
	const location = useLocation();
	const slider = (location.state as LocationState).context === 'Toggle_Module';
	const [interests, setInterests] = useState<string[]>([]);

	const interestsList = [
		{
			displayName: 'Moments',
			value: 'moments',
			disabled: true,
		},
		{
			displayName: 'Gallery',
			value: 'gallery',
			disabled: true,
		},
		{
			displayName: 'To Do',
			value: 'todo',
			disabled: false,
		},
	];
	const familyFriend = [
		{
			displayName: 'Chat',
			value: 'chat',
			disabled: true,
		},
		...interestsList,
	];
	const travel = [
		{
			displayName: 'Chat',
			value: 'chat',
			disabled: true,
		},
		{
			displayName: 'Expenses',
			value: 'expenses',
			disabled: false,
		},
		{
			displayName: 'Itinerary',
			value: 'itinerary',
			disabled: false,
		},
		...interestsList,
	];
	const work = [
		{
			displayName: 'Announcements',
			value: 'announcements',
			disabled: true,
		},
		...interestsList,
	];

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let interests: string[] = searchParams.get('interests')?.split(',') ?? [];

		const isChecked = e.target.checked;

		if (isChecked) {
			if (interests.findIndex(x => x === e.target.value) < 0) {
				interests.push(e.target.value);
			}
		} else {
			interests = interests.filter(x => x !== e.target.value);
		}

		searchParams.set('interests', interests.join(','));
		setSearchParams(searchParams);
		setInterests(interests);
	};

	const circleType = searchParams.get('type');
	let selectedType: Interest[];

	switch (circleType) {
		case 'family':
			selectedType = familyFriend;
			break;
		case 'friends':
			selectedType = familyFriend;
			break;
		case 'travel':
			selectedType = travel;
			break;
		case 'work':
			selectedType = work;
			break;
		default:
			selectedType = interestsList;
			break;
	}

	useEffect(() => {
		if (isSuccess) {
			navigate('/secure/circles', { replace: true });
		}
		// eslint-disable-next-line
	}, [isSuccess]);

	const submitForm = (e: React.FormEvent<HTMLElement>) => {
		// We don't want the page to refresh
		e.preventDefault();
		const name = searchParams.get('name');
		const type = searchParams.get('type');

		if (name && type && interests.length) {
			const circle: AddCircleRequest = {
				name,
				type,
				interests,
			};
			addCircle(circle);
		} else {
			throw new Error('A form with blank name, type, or interest was submitted');
		}
	};

	return (
		<div>
			<Header title="Interests" subtitle="Based on your circle we've pre-selected some values for you." />

			<Content>
				<form onSubmit={submitForm}>
					{selectedType.map(interest => {
						return (
							<div
								key={interest.value}
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}>
								<EssenceAnimatedCheckbox
									title={interest.displayName}
									name="interest-checkbox-group"
									value={interest.value}
									disabled={interest.disabled}
									key={interest.value}
									onChange={onChange}
								/>
								{slider && (
									<label>
										{/* <label className={styles["toggle-slider"]}> */}
										<input type="checkbox" />
										{/* <span className={styles.slider}></span> */}
										<span></span>
									</label>
								)}
							</div>
						);
					})}
					<div className="mb-16" />
					<EssenceButton type="submit" name="create" />
				</form>
			</Content>
		</div>
	);
}
