import axios from 'axios';
import accountService from './account-service';

const instance = axios.create({
	baseURL: import.meta.env.VITE_API_URL,
	headers: {
		'Content-Type': 'application/json',
		apiKey: import.meta.env.VITE_API_KEY,
	},
});

instance.interceptors.request.use(config => {
	const token = accountService.getAccessToken();
	config.headers['ngrok-skip-browser-warning'] = 'true';
	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
});

// instance.interceptors.response.use(
// 	res => {
// 		return res;
// 	},
// 	async (err: AxiosError) => {
// 		const originalConfig = err.config;

// 		if (err.response && originalConfig) {
// 			// Access Token was expired
// 			if (err.response.status === 401 && !originalConfig.params._retry) {
// 				originalConfig.params._retry = true;

// 				try {
// 					// TODO: Refresh the token
// 					// accountService.setToken(token);

// 					return instance(originalConfig);
// 				} catch (_error) {
// 					return Promise.reject(err);
// 				}
// 			}
// 		}

// 		return Promise.reject(err);
// 	},
// );

export default instance;
