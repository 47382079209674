import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Header from '../../../shared/components/header/page';
import Content from '../../../shared/components/content/page';
import useLoaderDisplay from '../../../shared/hooks/loader-display';
import { useAccountMutations } from '../../../shared/tanstack/account/mutations';
import { useMutation } from '@tanstack/react-query';
import LoginForm from '../components/login-form/page';

export default function Login() {
	const navigate = useNavigate();
	const [phoneNumber, setPhoneNumber] = useState('');
	const [countryCode, setCountryCode] = useState<number>(0);
	const { sendOtpMutation } = useAccountMutations();
	const { mutate: sendLoginOtp, isSuccess: isSendOtpSuccess, isPaused, status } = useMutation(sendOtpMutation);
	useLoaderDisplay({
		key: 'sendOtp',
		isLoading: status === 'pending' && !isPaused,
	});

	useEffect(() => {
		if (isSendOtpSuccess) {
			navigate(`/auth/verify-otp`, { state: { phoneNumber, countryCode } });
		}
	}, [countryCode, isSendOtpSuccess, navigate, phoneNumber]);

	const sendOTP = (phoneNumber: string, countryCode: number) => {
		setPhoneNumber(phoneNumber);
		setCountryCode(countryCode);

		// Call the API
		sendLoginOtp({
			phoneNumber,
			countryCode,
		});
	};

	return (
		<>
			<Header title="Login" subtitle="set up your account with your phone number" />
			<Content>
				<LoginForm onSubmit={sendOTP} />
			</Content>
		</>
	);
}
