import { PropsWithChildren } from 'react';
import styles from './picker-overlay.module.css';

interface Props extends PropsWithChildren {
	onAccept: React.MouseEventHandler<HTMLDivElement>;
	onReject: React.MouseEventHandler<HTMLDivElement>;
}
export default function EssencePickerOverlay({ children, onAccept, onReject }: Props) {
	return (
		<div className={styles['picker-overlay']}>
			{children}
			<div className={styles['action-bar']}>
				<div onClick={onAccept} className={`fa-regular fa-circle-check ${styles['action-icon']}`}></div>
				<div onClick={onReject} className={`fa-regular fa-circle-xmark ${styles['action-icon']}`}></div>
			</div>
		</div>
	);
}
