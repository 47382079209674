export interface MemberType {
	title: string;
	pluralTitle: string;
}

const getMemberType = (circleType: string): MemberType | undefined => {
	if (circleType.toLowerCase() == 'family') {
		return { title: 'family member', pluralTitle: 'family members' };
	} else if (circleType.toLowerCase().startsWith('friend')) {
		return { title: 'friend', pluralTitle: 'friends' };
	} else if (circleType.toLowerCase() == 'travel') {
		return { title: 'explorer', pluralTitle: 'explorers' };
	} else if (circleType.toLowerCase() == 'work') {
		return { title: 'colleague', pluralTitle: 'colleagues' };
	}
	return undefined;
};

export default getMemberType;
