import { motion } from 'framer-motion';
import { PropsWithChildren } from 'react';

export default function AnimatedParent({ children }: PropsWithChildren) {
	const parentVariants = {
		initial: {},
		animate: {
			transition: { staggerChildren: 0.04 },
		},
		exit: {
			transition: { staggerChildren: 0.04 },
		},
	};

	return (
		<motion.div variants={parentVariants} animate="animate" initial="initial" exit="exit">
			{children}
		</motion.div>
	);
}
