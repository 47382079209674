import { useState } from 'react';
import EssenceButton from '../../../../components/essence/button/button';
import InputPhoneNumber from '../../../../shared/components/input/phone-number/page';
import EssenceCountryCodePicker from '../../../../components/essence/country-code-picker/country-code-picker';

interface Props {
	onSubmit(phoneNumber: string, countryCode: number): void;
}
export default function LoginForm(props: Props) {
	const [phoneNumber, setPhoneNumber] = useState('');
	const [countryCode, setCountryCode] = useState<number>(91);

	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				props.onSubmit(phoneNumber, countryCode);
			}}>
			<p className="headline-4">Phone Number</p>
			<div className="flex space-x-3">
				<EssenceCountryCodePicker
					onChange={option => {
						setCountryCode(+option.title.replace(/\D/g, ''));
					}}></EssenceCountryCodePicker>
				<InputPhoneNumber
					onChange={phoneNumber => {
						setPhoneNumber(phoneNumber);
					}}
				/>
			</div>
			<EssenceButton
				disabled={phoneNumber.length < 7}
				data-testid="btn-send-code"
				type="submit"
				name="send code"
			/>
		</form>
	);
}
