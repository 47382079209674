import EssenceAnimatedButton from '../../../../components/essence/button/animated-button';
import Header from '../../../../shared/components/header/page';
import Content from '../../../../shared/components/content/page';
import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import accountService from '../../../../shared/services/account-service';
import getMemberType from '../../member-type';
import { useCircleQueries } from '../../../../shared/tanstack/circle/queries';
import EssenceAnimatedRadio from '../../../../components/essence/radio/animated-radio';
import { useQuery } from '@tanstack/react-query';
import { jwtDecode } from 'jwt-decode';
import { useExpenseQueries } from '../../../../shared/tanstack/expenses/queries';
import storageKeyService from '../../../../shared/services/storage-key-service';
import { InvalidCircleIdError } from '../../../../shared/models/errors/invalid-circle-id-error';
import { Expense } from '../../../../shared/models/expense';
import { InvalidStorageKeyError } from '../../../../shared/models/errors/invalid-storage-key-error';
import { Account } from '../../../../shared/models/account';
import { InvalidAccessTokenError } from '../../../../shared/models/errors/invalid-access-token-error';
import { InvalidExpenseError } from '../../../../shared/models/errors/inavlid-expense-error';

export interface LocationState {
	settlementDetail?: Expense;
}
const Payee = () => {
	const { circleId } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const [searchParams] = useSearchParams();

	const storageKey = searchParams.get('token');
	if (!circleId) {
		throw new InvalidCircleIdError();
	}
	if (!storageKey) {
		throw new InvalidStorageKeyError();
	}

	const { circleQuery } = useCircleQueries();
	const { data: circle } = useQuery(circleQuery(circleId));

	const { expensesSummaryQuery } = useExpenseQueries();
	const { data: expensesSummary } = useQuery(expensesSummaryQuery(circleId));

	const { participantsQuery } = useCircleQueries();
	const { data: participants } = useQuery(participantsQuery(circleId));

	const settlmentFromState = (location.state as LocationState).settlementDetail;
	const existingSettlement = storageKeyService.getSettlementForKey(storageKey);

	const expense = existingSettlement ?? settlmentFromState;
	if (!expense) {
		throw new InvalidExpenseError();
	}

	if (settlmentFromState) {
		expense.dateTime = new Date(settlmentFromState.dateTime);
	} else if (existingSettlement) {
		expense.dateTime = new Date(existingSettlement.dateTime);
	}

	const [settlementDetail] = useState<Expense>(expense);
	let member: string | undefined;
	if (circle) {
		const memberType = getMemberType(circle.type);
		if (memberType) {
			member = memberType.title;
		}
	}

	const accessToken = accountService.getAccessToken();
	if (!accessToken) {
		throw new InvalidAccessTokenError();
	}
	const loggedInUserId: string = jwtDecode<Account>(accessToken).userId;

	useEffect(() => {
		storageKeyService.removeSettlementsExceptKey(storageKey);
		if (location.state === null) {
			navigate(-1);
		}
	}, [location.state, navigate, storageKey]);

	const goToSettlementDetail = (e: React.SyntheticEvent) => {
		e.preventDefault();
		storageKeyService.setSettlmentForKey(storageKey, settlementDetail);
		settlementDetail.isSettlement = true;

		navigate(`/secure/circles/${circleId}/add-settlement/settlement-detail`, {
			state: { settlementDetail },
		});
	};

	const _findParticipant = (userId: string) => {
		if (participants) {
			const filteredParticipant = participants.find(x => x.userId == userId);
			return filteredParticipant;
		}
		return;
	};
	if (expensesSummary) {
		return (
			<>
				{member && <Header title={member} subtitle={`Select the ${member} to settle with.`} />}
				<Content>
					{expensesSummary.length > 0 && (
						<div className="mb-6 gap-20 items-center">
							{expensesSummary.map((summary, i) => (
								<EssenceAnimatedRadio
									defaultChecked={i === 0}
									key={summary.creditorId}
									text={
										summary.creditorId !== loggedInUserId
											? `Pay ${
													_findParticipant(
														summary.creditorId !== loggedInUserId
															? summary.creditorId
															: summary.debtorId,
													)?.name ?? ''
												} ${summary.currency}${summary.amount.toString()}`
											: `Collect from ${
													_findParticipant(
														summary.creditorId !== loggedInUserId
															? summary.creditorId
															: summary.debtorId,
													)?.name ?? ''
												} ${summary.currency}${summary.amount.toString()}`
									}
									name="creditors"
									value={summary.creditorId}
								/>
							))}

							<div className="mt-6"></div>
							<EssenceAnimatedButton name="next" onClick={goToSettlementDetail} />
						</div>
					)}
				</Content>
			</>
		);
	}
};

export default Payee;
