import Animated from '../animated/animated';
import EssenceSwitch from './switch';

export default function EssenceAnimatedSwitch(props) {
	return (
		<Animated>
			<EssenceSwitch {...props} />
		</Animated>
	);
}
