import api from '../../services/api';

interface ExpenseResponse {
	expenseId: string;
}

export interface ExpenseUserRequest {
	userId: string;
	amount: number;
}
export interface AddExpenseRequest {
	circleId: string;
	isSettlement: boolean;
	description: string;
	currency: string;
	amount: number;
	creditors: ExpenseUserRequest[];
	debtors: ExpenseUserRequest[];
	dateTime: Date;
}
export const addExpense = async (body: AddExpenseRequest): Promise<ExpenseResponse> => {
	return await api.post<ExpenseResponse>(`/api/expense`, body).then(res => res.data);
};

export const deleteExpense = async (expenseId: string) => {
	await api.delete(`/api/expense/${expenseId}`);
};

export interface UpdateExpenseRequest {
	expenseId: string;
	isSettlement: boolean;
	description: string;
	currency: string;
	amount: number;
	creditors: ExpenseUserRequest[];
	debtors: ExpenseUserRequest[];
	dateTime: Date;
}

export const updateExpense = async (body: UpdateExpenseRequest) => {
	await api.put(`/api/expense/${body.expenseId}`, body);
};
