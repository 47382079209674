import React, { useState } from 'react';
import EssenceDatePicker from '../../../../../components/essence/date-picker/date-picker';
import { Expense } from '../../../../../shared/models/expense';
import styles from '../expense-amount.module.css';

interface Props {
	expense: Expense;
}
export default function ExpensedetailForm({ expense }: Props) {
	const [expenseDetail, setExpenseDetail] = useState(expense);
	const onAmountChange = ($event: React.ChangeEvent<HTMLInputElement>) => {
		const regex = new RegExp(/^[0-9]*\.?[0-9]{0,2}?$/);
		const amount = $event.target.value;

		if (regex.test(amount)) {
			setExpenseDetail({
				...expenseDetail,
				amount: parseFloat(amount) || 0,
			});
		}
	};

	const onBlur = () => {
		if (expenseDetail.amount) {
			setExpenseDetail({
				...expenseDetail,
				amount: parseFloat(expenseDetail.amount.toString()),
			});
		}
	};
	return (
		<>
			<div>
				<div className="headline-5 mt-3">Description</div>
				<input
					essence-input="true"
					type="text"
					value={expenseDetail.description}
					onChange={event => {
						setExpenseDetail({
							...expenseDetail,
							description: event.target.value,
						});
					}}
					name="expense-item"
				/>
			</div>
			<div className="headline-5 mt-3">Date</div>
			<EssenceDatePicker
				date={expenseDetail.dateTime}
				onAccept={date => {
					setExpenseDetail({
						...expenseDetail,
						dateTime: date,
					});
				}}></EssenceDatePicker>
			<div className="mb-6">
				<div className="headline-5 mt-3">Amount</div>
				<input
					className={styles.amount}
					onBlur={onBlur}
					essence-input="true"
					value={expenseDetail.amount}
					onChange={onAmountChange}
					name="expense-amount"
					type="tel"
				/>
			</div>
		</>
	);
}
