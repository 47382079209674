import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { App as CapacitorApp } from '@capacitor/app';
import Login from '../screens/auth/login/page';
import VerifyOtp from '../screens/auth/verify-otp/page';
import CircleType from '../secure/onboarding/circle-type/page';
import CircleInfo from '../screens/onboarding/circle-info/page';
import CircleInterests from '../secure/onboarding/circle-interests/page';
import Circles from '../screens/circles/page';
import CircleDetail from '../secure/circle-details/page';
import { AnimatePresence } from 'framer-motion';
// import AddParticipants from '../secure/circle-details/components/participants/add-participants';
import CircleParticipants from '../secure/circle-details/components/participants/circle-participants';
import MobileAddParticipants from '../secure/circle-details/components/participants/mobile-add-participants';
import AddExpense from '../screens/expense/components/add-expense/page';
import Participants from '../screens/expense/components/add-expense/participants/page';
import Payee from '../screens/expense/components/add-expense/payeee/page';
import Panel from '../shared/components/panel/page';
import Profile from '../screens/profile/page';
import AddExpenseDetail from '../screens/expense/components/add-expense/add-expense-detail/page';
import ExpenseDetail from '../secure/circle-details/components/expense-detail/page';
import AddItinerary from '../secure/circle-details/components/add-itinerary/page';
import AddItineraryDetail from '../secure/circle-details/components/add-itinerary/add-itinerary-detail';
// import Origin from "../secure/circle-details/components/add-itinerary/origin";
// import Destination from "../secure/circle-details/components/add-itinerary/destination";
// import Summary from "../secure/circle-details/components/add-itinerary/summary";
import { useMutation } from '@tanstack/react-query';
import { refreshTokenMutation } from '../shared/services/circles-api';
import { useCallback, useEffect } from 'react';
import { PushNotifications } from '@capacitor/push-notifications';
import { LocalNotifications, ScheduleOptions } from '@capacitor/local-notifications';
import accountService from '../shared/services/account-service';
import AddSettlementDetail from '../secure/circle-details/components/add-settlement/add-settlement-detail';
import SettlePayee from '../secure/circle-details/components/add-settlement/payee';
import AddSettlement from '../secure/circle-details/components/add-settlement/page';
import { Capacitor } from '@capacitor/core';
import EssenceNavigationOverlay from './essence/core/navigation-overlay/navigation-overlay';
import Terms from '../screens/terms/page';
import PrivacyPolicy from '../screens/privacy-policy/page';
import Theme from '../screens/circles/settings/theme/page';
import { CirclesError } from '../shared/models/errors/circles-error';

CapacitorApp.addListener('backButton', ({ canGoBack }) => {
	if (canGoBack) {
		// navigate(-1);
		window.history.back();
	} else {
		CapacitorApp.exitApp().catch((error: unknown) => {
			console.log(error);
		});
	}
}).catch((error: unknown) => {
	console.log(error);
});

interface Data {
	path: string;
}
export default function AnimatedRoutes() {
	const location = useLocation();
	const { mutate: refreshToken } = useMutation(refreshTokenMutation());
	const navigate = useNavigate();
	const accessToken = accountService.getAccessToken();

	window.onerror = function (_message, _source, _lineno, _colno, error) {
		if (error instanceof CirclesError) {
			// TODO: Create some error page
			navigate('/auth/login');
		}
		// Return true to prevent the default browser error handling
		return true;
	};

	const _handlePush = useCallback(
		(path: string) => {
			// Add a delay of 300ms so as to await for the app to load if the app is started from scratch.
			setTimeout(() => {
				navigate(path);
			}, 300);
		},
		[navigate],
	);

	const registerPushNotificationListeners = useCallback(() => {
		const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');

		if (isPushNotificationsAvailable) {
			// Request permission to use push notifications
			// iOS will prompt user and return if they granted permission or not
			// Android will just grant without prompting
			PushNotifications.requestPermissions()
				.then(async result => {
					if (result.receive === 'granted') {
						// Register with Apple / Google to receive push via APNS/FCM
						await PushNotifications.register();
						return;
					} else {
						// Show some error
						return;
					}
				})
				.catch((error: unknown) => {
					console.log(error);
				});

			// On success, we should be able to receive notifications
			PushNotifications.addListener('registration', token => {
				// console.log(token.value);
				accountService.setFCMToken(token.value);
				refreshToken(token.value);
				// alert("Success on registration: " + JSON.stringify(token.value));
			}).catch((error: unknown) => {
				console.log(error);
			});

			// Some issue with our setup and push will not work
			PushNotifications.addListener('registrationError', () => {
				// alert("Error on registration: " + JSON.stringify(error));
			}).catch((error: unknown) => {
				console.log(error);
			});

			// Show us the notification payload if the app is open on our device
			PushNotifications.addListener('pushNotificationReceived', notification => {
				console.log(JSON.stringify(notification));
				const data = notification.data as Data;
				const options: ScheduleOptions = {
					notifications: [
						{
							title: notification.title ?? '',
							body: notification.body ?? '',
							...notification,
							extra: data,
							id: 1,
							schedule: {
								allowWhileIdle: true,
							},
						},
					],
				};
				LocalNotifications.schedule(options).catch((error: unknown) => {
					console.log(error);
				});
			}).catch((error: unknown) => {
				console.log(error);
			});

			// Method called when tapping on a notification
			PushNotifications.addListener('pushNotificationActionPerformed', notification => {
				console.log(`Notification: ${JSON.stringify(notification)}`);
				const extras = notification.notification.data as { path: string };
				if (extras.path) {
					_handlePush(extras.path);
				}
			}).catch((error: unknown) => {
				console.log(error);
			});

			LocalNotifications.addListener('localNotificationActionPerformed', notification => {
				console.log(`Notification: ${JSON.stringify(notification)}`);
				const extras = notification.notification.extra as Data;
				if (extras.path) {
					_handlePush(extras.path);
				}
			}).catch((error: unknown) => {
				console.log(error);
			});
		}
	}, [_handlePush, refreshToken]);

	useEffect(() => {
		registerPushNotificationListeners();
	}, [registerPushNotificationListeners]);

	const withAuth = Component => {
		return accessToken ? <Component /> : <Navigate to="/auth/login" replace />;
	};

	return (
		<EssenceNavigationOverlay>
			<AnimatePresence mode="wait">
				<Routes location={location} key={location.pathname}>
					<Route path="/" element={<Navigate replace to="/auth/login" />} />

					<Route path="/privacy" element={<PrivacyPolicy />} />
					<Route path="/terms" element={<Terms />} />
					<Route
						element={accessToken ? <Navigate to="/secure/circles" replace /> : <Panel title="circles" />}
						path="/auth">
						<Route path="login" element={<Login />} />
						<Route path="verify-otp" element={<VerifyOtp />} />
					</Route>
					<Route path="/secure/settings" element={<Panel title="Update your profile" />}>
						<Route path="profile" element={withAuth(Profile)} />
					</Route>
					<Route path="/secure/settings" element={<Panel title="Update your theme" />}>
						<Route path="theme" element={withAuth(Theme)} />
					</Route>
					<Route path="/secure/onboarding" element={<Panel />}>
						<Route path="circle-type" element={withAuth(CircleType)} />
						<Route path="circle-info" element={withAuth(CircleInfo)} />
						<Route path="circle-interests" element={withAuth(CircleInterests)} />
					</Route>
					<Route path="/secure/circles" element={withAuth(Circles)} />
					<Route path="/secure/circles/:circleId" element={withAuth(CircleDetail)} />
					{/* <Route path="/secure/circles/:circleId/add-participant" element={withAuth(AddParticipants)} /> */}
					<Route
						path="/secure/circles/:circleId/participants/add"
						element={withAuth(MobileAddParticipants)}
					/>
					<Route path="/secure/circles/:circleId/participants" element={withAuth(CircleParticipants)} />
					<Route path="/secure/circles/:circleId/add-expense" element={withAuth(AddExpense)}>
						<Route path="expense-detail" element={withAuth(AddExpenseDetail)} />
						<Route path="participants" element={withAuth(Participants)} />
						<Route path="payee" element={withAuth(Payee)} />
					</Route>
					<Route path="/secure/expense/:expenseId" element={withAuth(ExpenseDetail)} />
					<Route path="/secure/circles/:circleId/add-settlement" element={withAuth(AddSettlement)}>
						<Route path="settlement-detail" element={withAuth(AddSettlementDetail)} />
						<Route path="payee" element={withAuth(SettlePayee)} />
					</Route>
					<Route path="/secure/circles/:circleId/add-itinerary" element={withAuth(AddItinerary)}>
						<Route path="itinerary-detail" element={withAuth(AddItineraryDetail)} />
						{/* <Route path="origin" element={withAuth(Origin)} />
          <Route path="destination" element={withAuth(Destination)} />
          <Route path="summary" element={withAuth(Summary)} /> */}
					</Route>
				</Routes>
			</AnimatePresence>
		</EssenceNavigationOverlay>
	);
}
